import React from 'react'

function VoIcon(props) {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"/>
                </clipPath>
                <path  fill={props.color} d="M84.96,86.03c-0.55,0-1.16-0.07-1.82-0.23l-2.21-0.51c-11.53-2.68-24.59-5.71-25.21-12.54
                    c-0.25-2.79-0.83-10.4-0.72-20.63c0.01-0.47,0.31-0.83,0.85-0.83c0.46,0.01,0.84,0.39,0.83,0.85c-0.1,10.15,0.46,17.69,0.71,20.46
                    c0.5,5.61,13.48,8.63,23.91,11.05l2.21,0.51c2.12,0.5,3.59-0.03,4.5-1.62c4.34-7.61-4.61-34.34-15.2-45.41
                    c-6.39-6.68-9.81-7.25-11.54-6.55c-2.83,1.14-3.45,6.51-3.45,8.49c0,0.47-0.38,0.84-0.84,0.84c-0.46,0-0.84-0.38-0.84-0.84
                    c0-0.34,0.04-8.26,4.5-10.05c3.28-1.32,7.65,0.96,13.39,6.95c11.2,11.7,20.15,39.17,15.45,47.41
                    C88.75,84.66,87.39,86.03,84.96,86.03"/>
                <path  fill={props.color} d="M68.9,64.96c-1.09,0-2.19-0.51-2.37-1.48c-0.12-0.67-0.28-2.52-0.46-4.67c-0.19-2.24-0.45-5.3-0.59-5.74
                    c-0.4-0.42-4.14-2.13-7.75-3.78c-1.65-0.75-3.44-1.57-5.26-2.43c-1.38-0.65-2.79-0.55-2.81-0.55c-0.38,0.03-0.74-0.26-0.77-0.65
                    c-0.03-0.39,0.26-0.74,0.65-0.77c0.07,0,1.79-0.14,3.54,0.68c1.81,0.85,3.6,1.67,5.25,2.42c6.63,3.03,8.21,3.81,8.5,4.59
                    c0.18,0.49,0.35,2.33,0.68,6.12c0.16,1.94,0.33,3.94,0.44,4.54c0.07,0.15,0.75,0.41,1.39,0.25c0.42-0.1,0.41-0.28,0.41-0.35
                    c-0.03-0.4-0.16-1.28-0.32-2.3c-0.71-4.48-0.9-6.47-0.3-7.2c0.18-0.22,0.43-0.34,0.7-0.35c0.99-0.07,2.07,0.69,3.22,1.46
                    c0.89,0.59,1.9,1.26,2.6,1.34c0.59,0.06,0.98-0.28,1.09-0.65c0.06-0.21,0.06-0.49-0.27-0.65c-1.04-0.51-10.22-5.35-10.96-6.09
                    c-0.21-0.2-0.3-0.48-0.26-0.79c0.19-1.55,5.01-4.71,5.06-4.74c0.41-0.24,0.63-0.61,0.6-0.99c-0.02-0.29-0.18-0.53-0.4-0.63
                    c-0.3-0.13-0.73,0.02-1.19,0.4c-0.43,0.36-2.88,2.28-4.35,3.41c-0.92,0.7-2.64,2.02-4.48,1.36c-0.76-0.28-7.5-2.72-8.22-3.74
                    c-0.54-0.76-0.81-1.44-0.87-2.21C50.86,34.87,51.3,19,51.43,14.85c-0.5,0.11-1.15,0.21-1.82,0.21c-0.4,0-0.72-0.32-0.72-0.72
                    c0-0.4,0.32-0.72,0.72-0.72c1.19,0,2.31-0.4,2.32-0.4c0.22-0.08,0.47-0.05,0.66,0.09c0.19,0.14,0.3,0.36,0.3,0.6
                    c-0.01,0.2-0.66,20.14-0.09,26.69c0.03,0.36,0.13,0.82,0.62,1.51c0.48,0.47,4.79,2.23,7.53,3.22c1.11,0.4,2.36-0.56,3.04-1.08
                    c1.51-1.16,3.98-3.09,4.39-3.44c0.89-0.74,1.86-0.96,2.67-0.62c0.72,0.31,1.21,1.02,1.26,1.85c0.06,0.93-0.42,1.8-1.3,2.32
                    c-1.15,0.69-3.59,2.64-4.23,3.49c1.6,1.03,8.65,4.81,10.3,5.62c0.89,0.44,1.3,1.38,1.02,2.34c-0.27,0.92-1.21,1.83-2.62,1.68
                    c-1.05-0.12-2.17-0.86-3.24-1.58c-0.7-0.47-1.49-0.99-2.04-1.16c-0.11,1.09,0.39,4.24,0.64,5.83c0.17,1.1,0.31,1.97,0.34,2.44
                    c0.06,1.01-0.67,1.76-1.86,1.9C69.17,64.95,69.04,64.96,68.9,64.96"/>
                <path  fill={props.color} d="M14.25,86.03c-2.44,0-3.79-1.37-4.52-2.65c-4.71-8.24,4.25-35.71,15.45-47.41c5.73-5.98,10.1-8.28,13.36-7.01
                    c4.02,1.56,4.49,8.06,4.53,10.02c0.01,0.45-0.33,0.87-0.78,0.9c-0.47,0.03-0.84-0.27-0.89-0.72c0-0.02-0.01-0.12-0.01-0.14
                    c-0.03-1.21-0.34-7.28-3.46-8.49c-1.74-0.67-5.15-0.07-11.53,6.6C15.8,48.2,6.85,74.93,11.19,82.54c0.91,1.59,2.38,2.12,4.5,1.62
                    l2.21-0.51c10.42-2.42,23.4-5.43,23.91-11.05c0.25-2.76,0.82-10.28,0.71-20.46c0-0.47,0.37-0.85,0.83-0.85h0.01
                    c0.46,0,0.84,0.37,0.84,0.83c0.11,10.25-0.47,17.84-0.72,20.63c-0.61,6.83-13.68,9.86-25.2,12.54l-2.21,0.51
                    C15.41,85.96,14.81,86.03,14.25,86.03"/>
                <path  fill={props.color} d="M30.31,64.96c-0.14,0-0.27-0.01-0.41-0.02c-1.19-0.14-1.92-0.89-1.86-1.9c0.03-0.47,0.17-1.35,0.34-2.45
                    c0.25-1.59,0.74-4.73,0.63-5.82c-0.55,0.17-1.34,0.69-2.04,1.16c-1.08,0.72-2.19,1.46-3.24,1.58c-1.43,0.15-2.36-0.76-2.63-1.68
                    c-0.28-0.96,0.13-1.9,1.02-2.34c1.65-0.81,8.67-4.58,10.3-5.62c-0.65-0.85-3.08-2.8-4.23-3.48c-0.88-0.52-1.36-1.39-1.3-2.32
                    c0.06-0.83,0.54-1.54,1.26-1.85c0.81-0.35,1.79-0.12,2.67,0.62c0.39,0.32,2.8,2.22,4.31,3.37c0.76,0.59,2.02,1.55,3.13,1.15
                    c2.75-0.99,7.06-2.74,7.56-3.25c0.46-0.65,0.56-1.11,0.59-1.47c0.57-6.55-0.08-26.49-0.09-26.69c-0.01-0.24,0.1-0.46,0.3-0.6
                    s0.44-0.18,0.66-0.09c0.01,0,1.14,0.4,2.33,0.4c0.4,0,0.72,0.32,0.72,0.72c0,0.4-0.32,0.72-0.72,0.72c-0.68,0-1.32-0.1-1.82-0.21
                    c0.13,4.15,0.57,20.01,0.05,25.89c-0.07,0.77-0.34,1.45-0.87,2.21c-0.72,1.01-7.45,3.46-8.22,3.74c-1.85,0.67-3.57-0.66-4.4-1.29
                    c-1.51-1.15-4.01-3.11-4.44-3.47c-0.45-0.38-0.89-0.53-1.19-0.4c-0.22,0.09-0.38,0.34-0.39,0.63c-0.03,0.39,0.19,0.75,0.6,1
                    c0.05,0.03,4.86,3.19,5.05,4.74c0.04,0.3-0.06,0.58-0.26,0.79c-0.74,0.74-9.92,5.58-10.96,6.09c-0.34,0.17-0.33,0.44-0.27,0.65
                    c0.11,0.38,0.49,0.72,1.09,0.66c0.71-0.08,1.71-0.75,2.6-1.34c1.15-0.76,2.19-1.53,3.22-1.46c0.27,0.01,0.52,0.13,0.7,0.35
                    c0.6,0.72,0.41,2.71-0.3,7.19c-0.16,1.02-0.3,1.91-0.32,2.31c-0.01,0.07-0.02,0.25,0.4,0.35c0.65,0.16,1.33-0.1,1.39-0.26
                    c0.11-0.59,0.27-2.59,0.44-4.52c0.32-3.8,0.49-5.64,0.68-6.13c0.29-0.78,1.87-1.56,8.5-4.59c1.65-0.75,3.43-1.57,5.24-2.42
                    c1.75-0.82,3.47-0.69,3.54-0.68c0.39,0.03,0.68,0.38,0.65,0.77c-0.03,0.39-0.38,0.68-0.77,0.65c-0.01,0-1.42-0.1-2.81,0.55
                    c-1.82,0.85-3.61,1.67-5.26,2.43c-3.62,1.65-7.35,3.36-7.81,3.89c-0.09,0.34-0.35,3.41-0.54,5.65c-0.18,2.14-0.34,3.99-0.46,4.66
                    C32.51,64.45,31.41,64.96,30.31,64.96"/>
            </g>
        </svg>
    )
}

export default VoIcon;