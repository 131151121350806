import React from "react";
import { useCookieConsents } from "@enzsft/react-cookie-consents";
import Strings from "../../assets/strings";
import { useStore } from "../../contexts/StateContext";
import { Link } from "react-router-dom";

const CookieBanner = () => {
  const [{ hash, lang }] = useStore();
  const message = Strings[lang].web.cookies.policy;
  const linkText = Strings[lang].web.cookies["know-more"];
  const cookieConsents = useCookieConsents();

  if (cookieConsents.get().length > 0) {
    return null;
  }

  return (
    <div className="banner">
      <div>
        {message}
        <Link to={`/webapp/${hash}/cookies`} className="cookie-know-more">
          {linkText}
        </Link>
      </div>
      <button onClick={() => cookieConsents.add("analytics")}>
        Accept and close
      </button>
    </div>
  );
};

export default CookieBanner;
