import React from 'react'
import {Link, Redirect, withRouter} from "react-router-dom";
import {stack as Menu} from "react-burger-menu";
import Select from 'react-select';
import './Navigation.scss';
import {FaBars} from 'react-icons/fa';
import {StateContext} from "../../contexts/StateContext";
import flag_pt from '../../assets/img/languages/flag_pt_PT.png'
import flag_br from '../../assets/img/languages/flag_pt_BR.png'
import flag_es from '../../assets/img/languages/flag_es_ES.png'
import flag_gb from '../../assets/img/languages/flag_en_GB.png'
import flag_it from '../../assets/img/languages/flag_it_IT.png'
import flag_de from '../../assets/img/languages/flag_de_DE.png'
import flag_fr from '../../assets/img/languages/flag_fr_FR.png'
import Strings from '../../assets/strings'

class Navigation extends React.Component {

    static contextType = StateContext;

    state = {
        menuOpen: false,
        options : [
            {
                value: 'pt_PT',
                flag:"Português",
                label: <div className="language-option">
                           <img src={flag_pt} alt="flag"/>
                           <small>Português</small>
                       </div>
            },
            {
                value: 'pt_BR',
                flag:"Português Brasileiro",
                label: <div className="language-option">
                           <img src={flag_br} alt="flag"/>
                           <small>Português do Brasil</small>
                       </div>
            },
            {
                value: 'en_GB',
                flag:"English",
                label: <div className="language-option">
                           <img src={flag_gb} alt="flag"/>
                           <small>English</small>
                       </div>
            },
            {
                value: 'es_ES',
                flag:"Español",
                label: <div className="language-option">
                           <img src={flag_es} alt="flag"/>
                           <small>Español</small>
                       </div>
            },
            {
                value: 'fr_FR',
                flag:"Français",
                label: <div className="language-option">
                           <img src={flag_fr} alt="flag"/>
                           <small>Français</small>
                       </div>
            },
            {
                value: 'it_IT',
                flag:"Italiano",
                label: <div className="language-option">
                    <img src={flag_it} alt="flag"/>
                    <small>Italiano</small>
                </div>
            },
            {
                value: 'de_DE',
                flag:"Germany",
                label: <div className="language-option">
                    <img src={flag_de} alt="flag"/>
                    <small>Deutsch</small>
                </div>
            }
        ]
    };

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({menuOpen: false})
    }

    customFilter(option, searchText) {
        return option.data.flag.toLowerCase().includes(searchText.toLowerCase());
    }


    render () {
        const [{lang, hash, company},dispatch] = this.context;

        const valueFromId = (opts, id) => opts.find(o => o.value === id);

        const handleChange = selectedOption => {
            const lang = selectedOption.value;

            dispatch({
                type: 'changeLanguage',
                newLanguage: lang
            });

            localStorage.setItem("lang", lang);

            this.closeMenu();

            this.props.history.push(`/webapp/${hash}/plan`);
        };

        return (
            <Menu
                right
                isOpen={this.state.menuOpen}
                customBurgerIcon={ <FaBars/> }
                disableAutoFocus
                onStateChange={(state) => this.handleStateChange(state)}>
                <Select value={valueFromId(this.state.options, lang)}
                        options={this.state.options}
                        onChange={handleChange}
                        filterOption={this.customFilter}/>
                <Link to={ `/webapp/${this.props.hash}/terms` } className="menu-item" onClick={() => this.closeMenu()}>{Strings[lang].web.menu['terms-and-conditions']}</Link>
                <Link to={ `/webapp/${this.props.hash}/cookies` } className="menu-item" onClick={() => this.closeMenu()}>{Strings[lang].web.menu['cookies']}</Link>
                <Link to={ `/webapp/${this.props.hash}/help` } className="menu-item" onClick={() => this.closeMenu()}>{Strings[lang].web.menu['help']}</Link>
                <Link to={ `/webapp/${this.props.hash}/refs` } className="menu-item" onClick={() => this.closeMenu()}>{Strings[lang].web.menu['refs']}</Link>
                <ul className="company-content">
                    {
                        company !== "hg" ?
                            (
                                <li className="powered-by">
                                    <p><small>Powered by:</small></p>
                                    <img className="header-menu-image"  src={`${process.env.PUBLIC_URL}/img/heartcolor.png`} alt="company logo"/>
                                </li>
                            )
                            : null
                    }

                    <li className="portugal-2020">
                        <img className="header-menu-image" src={`${process.env.PUBLIC_URL}/img/2020.png`}  alt="company logo"/>
                    </li>
                </ul>
            </Menu>
        )
    }
}



export default withRouter(Navigation);