import React from 'react';
import LoadingCategory from "../LoadingCategory/LoadingCategory";

function LoadingCategories(props) {
    const loadings = [];
    for (let i = 0; i < props.quantity; i++) {
        loadings.push(<LoadingCategory key={i}/>)
    }

    return loadings;
}

export default LoadingCategories;