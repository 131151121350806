import React from 'react';

export const Caffeine = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path className="st0" d="M73.1,66.8h-3.5c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h3.5c2.9,0,5.3-2.4,5.3-5.3
		c0-2.9-2.4-5.3-5.3-5.3h-2c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h2c3.9,0,7,3.1,7,7C80.1,63.7,77,66.8,73.1,66.8"/>
                <path className="st0" d="M53.9,83.3h-5.6c-6.2,0-11.5-4.5-12.3-10.4l-2.4-19.1c0-0.2,0-0.5,0.2-0.7c0.2-0.2,0.4-0.3,0.6-0.3h33.2
		c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.2,0.4,0.2,0.7l-2.4,18.9C65.4,78.8,60.1,83.3,53.9,83.3 M35.4,54.5l2.3,18
		c0.7,5.2,5.2,9.1,10.6,9.1h5.6c5.4,0,9.9-3.9,10.6-9l2.3-18.1H35.4z"/>
                <path className="st0" d="M51.1,49c-0.2,0-0.4-0.1-0.6-0.3c-0.3-0.3-0.3-0.9,0-1.2c0.1-0.1,3.5-3.6-0.1-9.7c-4.7-7.8,0-13.1,0.1-13.2
		c0.3-0.3,0.8-0.4,1.2-0.1c0.3,0.3,0.4,0.8,0.1,1.2c-0.2,0.2-3.9,4.5,0.1,11.2c4.3,7.2-0.1,11.7-0.1,11.8C51.5,49,51.3,49,51.1,49"
                />
                <path className="st0" d="M57.5,46c-0.1,0-0.2,0-0.2,0c-0.4-0.1-0.7-0.6-0.6-1c0.5-1.6,0.8-3.9-1.3-7.7c-1.4-2.6-2.1-4.3-1.9-6.5
		c0-0.5,0.5-0.8,0.9-0.8c0.5,0,0.8,0.4,0.8,0.9c-0.1,1.7,0.3,2.9,1.7,5.6c1.8,3.3,2.3,6.2,1.4,8.9C58.2,45.8,57.8,46,57.5,46"/>
                <path className="st0" d="M46.9,47.1c-0.2,0-0.3,0-0.5-0.1c-0.4-0.3-0.5-0.8-0.3-1.2c1.2-1.9,1.9-2.9-0.9-7.6c-1.6-2.7-1.8-4.6-1.8-4.7
		c0-0.5,0.3-0.9,0.8-0.9c0.4,0,0.9,0.3,0.9,0.8c0,0,0.2,1.7,1.5,4c3,5,2.5,6.8,0.9,9.4C47.4,47,47.1,47.1,46.9,47.1"/>
                <path className="st0" d="M41.7,73.4c-0.4,0-0.8-0.3-0.8-0.7l-1.8-14.3c-0.1-0.5,0.3-0.9,0.7-0.9c0.5-0.1,0.9,0.3,0.9,0.7l1.8,14.3
		c0.1,0.5-0.3,0.9-0.7,0.9C41.8,73.4,41.8,73.4,41.7,73.4"/>
            </g>
        </svg>
    );
};
