import React from 'react';

export const Vitamins = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M69.5,39.3c-2.6-10.6-10.6-17.1-20.8-17.1c-10.6,0-19,6.7-21.6,17.1c-2.8,11.3-0.1,25.5,2.7,31.4
			c1.8,4,7.6,14.6,11.8,14.6c0.2,0,0.5,0,0.7-0.1c1.1-0.4,1.8-1,2.1-1.8c0.4-1.3-0.5-2.5-0.8-2.8c-1.2-2.3-4.1-8.5-3.3-11.5
			c1.3-4.7,6.3-14,7.9-14c1,0.1,4.4,5,6.9,10.7c0.6,1.4,1,2.5,1.2,3.3c0.1,0.4,0.5,0.6,0.9,0.5c0.4-0.1,0.6-0.5,0.5-0.9
			c-0.1-0.6-0.4-1.2-0.7-2c3.8-1.2,8.7-4.5,11.1-6.2c0,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.1-0.3,1.6c-0.1,0.4,0.2,0.8,0.6,0.9
			c0.4,0.1,0.8-0.2,0.9-0.6c0.1-0.5,0.2-1.1,0.4-1.7c0.4-2.1,0.7-4.3,0.9-6.4C71,49.6,70.7,44,69.5,39.3z M49.2,23.7
			c5.6,0.1,10.5,2.4,14,6.4c-1.5,0.9-9.8,5.7-14.1,5.9L49.2,23.7z M49.1,37.5c4.9-0.3,14.2-5.8,15-6.2c1.6,2.1,2.8,4.6,3.6,7.4
			c-1.7,1.1-9.7,6.3-14.3,7.8c-0.7-1.8-2.4-3.2-4.4-3.5L49.1,37.5z M47.7,23.7L47.6,36c-4.2-0.2-12.4-4.9-14-5.9
			C37.2,26.3,42.1,24,47.7,23.7z M32.6,31.3c1,0.6,10.1,6,15,6.2l0,5.5c-2,0.3-3.7,1.6-4.4,3.4c-4.7-1.6-13-6.9-14.3-7.8
			C29.7,35.8,31,33.4,32.6,31.3z M42.3,81.5l0.1,0.1c0.2,0.2,0.7,1,0.5,1.5c-0.1,0.4-0.7,0.6-1.1,0.8c-2,0.6-7.2-6-10.7-13.7
			c-1-2.2-2-5.7-2.8-9.7c2.2,1.5,7.2,4.9,11.2,6.2c-0.3,0.9-0.6,1.6-0.7,2.1C37.7,73,42.1,81.1,42.3,81.5z M40.1,65.3
			c-4.9-1.5-11.9-6.7-11.9-6.8c0,0-0.1-0.1-0.2-0.1c-0.4-2.7-0.6-5.6-0.6-8.6c2.5,1.9,10.7,8.2,15.4,9.8
			C41.8,61.5,40.8,63.6,40.1,65.3z M43.6,58.3c-5-1.6-15.8-10.1-15.9-10.2c-0.1-0.1-0.1-0.1-0.2-0.1c0.1-2.7,0.4-5.3,1-7.8
			c2.2,1.5,9.8,6.2,14.3,7.7c0,0.2,0,0.4,0,0.7c0,2.7,1.9,4.9,4.4,5.4C46,54.7,44.8,56.3,43.6,58.3z M48.3,52.6
			c-2.2,0-4.1-1.8-4.1-4.1s1.8-4.1,4-4.1c0,0,0,0,0,0c2.2,0,4.1,1.8,4.1,4.1S50.6,52.6,48.3,52.6z M49.3,54c2.6-0.4,4.6-2.7,4.6-5.5
			c0-0.2,0-0.4,0-0.6c4.5-1.5,12-6.2,14.3-7.7c0.5,2.4,0.9,5,1,7.8c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-11.1,8.8-16.1,10.3
			C51.7,56.5,50.4,54.8,49.3,54z M69,55.4c-0.1,1-0.2,2.1-0.4,3.1c-1.3,0.9-7.7,5.5-12.1,6.9c0,0,0-0.1-0.1-0.1
			c0-0.1-1.2-2.7-2.8-5.5c4.7-1.6,13.1-8,15.5-9.9C69.2,51.6,69.1,53.5,69,55.4z"/>
                <path d="M57.1,85.2c-6.4,0-10.5-3.4-10.6-3.5c-0.4-0.3-0.5-0.8-0.4-1.3c0.1-0.5,0.6-0.9,1.1-1c1.2-0.2,2-0.6,2.2-1.1
			c1.5-3.4,3.5-4.7,4.9-5.2c0.8-0.3,1.5-0.3,2-0.3c2.9-4.5,7.1-5.2,9.4-5.2c0.5,0,0.9-0.2,1.2-0.5c2.5-2.5,4.8-3.1,6.8-3.7
			c0.9-0.3,1.7-0.5,2.6-0.9c1.5-0.7,1.3-2.4,1-3.7c-0.3-1.1,0.2-2.2,1.2-2.6c0.6-0.3,1.3-0.3,1.9,0c0.6,0.3,1,0.8,1.2,1.5
			c0.3,1.2,0.3,2.4,0,3.7l0,0c-0.4,1.8-1.1,3-1.2,3.1c0,0-1.1,1.8-1.2,4.6c-0.2,3.8-3.2,8.2-6.8,9.8c-1.6,0.7-3.1,1.8-4.1,2.5
			c-3.3,2.4-7,3.8-10.8,3.8C57.3,85.2,57.2,85.2,57.1,85.2z M47.8,80.8c1,0.8,4.5,3,9.7,2.9c3.5-0.1,6.9-1.3,9.9-3.5
			c1.1-0.8,2.7-1.9,4.4-2.7c3.1-1.4,5.8-5.2,5.9-8.5c0.1-3.2,1.4-5.2,1.5-5.3c0,0,0.7-1.1,1-2.7c0.2-1.1,0.2-2.1,0-3
			c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.1-0.6,0c-0.3,0.1-0.5,0.5-0.4,0.8c0.7,2.7,0.1,4.7-1.8,5.5c-1,0.4-1.9,0.7-2.8,1
			c-2,0.6-3.9,1.1-6.2,3.3c-0.6,0.6-1.4,0.9-2.3,0.9c-2.1,0-5.9,0.7-8.4,4.9l-0.2,0.4l-0.5-0.1c-0.4,0-1.1,0-1.8,0.2
			c-1.1,0.4-2.8,1.5-4,4.3C50.3,79.8,49.3,80.5,47.8,80.8z"/>
                <path d="M57,83.2c-0.1,0-0.1,0-0.2,0c-1.1,0-2.1-0.5-2.9-1.4c-0.8-0.8-1.1-1.9-1.1-3s0.5-2.1,1.3-2.9c0.8-0.8,1.9-1.1,3-1.1
			c1.1,0,2.1,0.5,2.9,1.3c0.8,0.8,1.1,1.9,1.1,3c0,0.2,0,0.5-0.1,0.7l0,0c-0.2,0.9-0.6,1.6-1.3,2.2C59,82.8,58,83.2,57,83.2z
			 M57,76.3c-0.7,0-1.3,0.2-1.8,0.7c-0.5,0.5-0.8,1.1-0.9,1.9c0,0.7,0.2,1.4,0.7,1.9c0.5,0.5,1.1,0.8,1.9,0.9c0.7,0,1.4-0.2,1.9-0.7
			c0.4-0.4,0.7-0.9,0.8-1.4c0-0.1,0.1-0.3,0.1-0.4c0-0.7-0.2-1.4-0.7-1.9s-1.1-0.8-1.9-0.9C57.1,76.3,57,76.3,57,76.3z"/>
                <path d="M64.4,79.4c-0.1,0-0.1,0-0.2,0c-1.1,0-2.1-0.5-2.9-1.4c-0.8-0.8-1.1-1.9-1.1-3c0-1.1,0.5-2.1,1.4-2.9s1.9-1.1,3-1.1
			c1.1,0,2.1,0.5,2.9,1.3c0.9,1,1.3,2.4,1,3.7l0,0c-0.2,0.9-0.6,1.6-1.3,2.2C66.5,79.1,65.5,79.4,64.4,79.4z M64.4,72.6
			c-0.7,0-1.3,0.2-1.8,0.7c-0.5,0.5-0.8,1.1-0.9,1.9c0,0.7,0.2,1.4,0.7,1.9c0.5,0.5,1.1,0.8,1.9,0.9c0.7,0,1.4-0.2,1.9-0.7
			c0.4-0.4,0.7-0.9,0.8-1.4l0,0c0.2-0.8-0.1-1.7-0.6-2.4c-0.5-0.5-1.1-0.8-1.9-0.9C64.5,72.6,64.5,72.6,64.4,72.6z"/>
                <path d="M71.5,74.9c-1.1,0-2.1-0.4-2.9-1.2c-0.8-0.8-1.2-1.8-1.2-3c0-1.1,0.4-2.2,1.2-3c1.6-1.6,4.3-1.6,5.9,0
			c1,0.9,1.4,2.3,1.2,3.6v0c-0.1,0.9-0.5,1.6-1.2,2.3C73.7,74.5,72.6,74.9,71.5,74.9z M71.6,68.1c-0.7,0-1.4,0.3-1.9,0.8
			c-1,1.1-1,2.8,0,3.8c1.1,1,2.8,1,3.8,0c0.4-0.4,0.6-0.9,0.7-1.5c0.1-0.9-0.1-1.7-0.8-2.3C72.9,68.3,72.2,68.1,71.6,68.1z"/>
            </g>
        </svg>
    );
};