import React from 'react'

function InjuryIcon(props) {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"/>
                </clipPath>
                <path  fill={props.color} d="M30.84,81.5c-4.57,0-5.98-0.32-7.23-0.6c-0.45-0.1-0.88-0.2-1.46-0.27c-0.37-0.05-0.66,0-1.14,0.09
                    c-0.74,0.14-1.76,0.33-3.72,0.26c-3.5-0.11-5.72-2.67-6.03-5.02c-0.27-2.02,0.82-3.71,2.78-4.3c3.33-1,5.06-1.3,7.67-1.75
                    c1.53-0.26,3.44-0.59,6.17-1.16c7.05-1.46,14.29-7.55,18.88-15.89c4.36-7.93,8.08-34.14,8.12-34.41c0.07-0.46,0.5-0.78,0.95-0.72
                    c0.46,0.06,0.78,0.49,0.72,0.95c-0.15,1.09-3.8,26.78-8.31,34.98c-4.82,8.76-12.49,15.17-20.01,16.73
                    c-2.76,0.57-4.68,0.9-6.23,1.17c-2.56,0.44-4.24,0.74-7.48,1.71c-1.67,0.5-1.67,1.89-1.59,2.47c0.22,1.66,1.84,3.47,4.41,3.55
                    c1.78,0.06,2.69-0.11,3.36-0.23c0.55-0.1,1.03-0.19,1.66-0.11c0.64,0.08,1.11,0.19,1.6,0.3c1.22,0.27,2.48,0.56,6.86,0.56
                    c3.03,0,6.13-0.64,9.13-1.27c3.27-0.68,6.65-1.39,9.94-1.24c3.69,0.15,5.68,0.66,7.44,1.1c1.75,0.44,3.27,0.81,6.12,0.78
                    c2.4-0.03,4.4-0.86,5.76-2.39c1.55-1.73,2.24-4.33,1.94-7.3c-0.29-2.89-0.92-4.87-1.52-6.79c-0.8-2.51-1.62-5.11-1.64-9.6
                    c-0.06-12.76,9.49-29.59,9.9-30.3c0.23-0.4,0.74-0.55,1.15-0.31c0.4,0.23,0.54,0.75,0.31,1.15c-0.1,0.17-9.74,17.14-9.68,29.46
                    c0.02,4.24,0.77,6.6,1.56,9.1c0.63,1.99,1.29,4.06,1.59,7.13c0.34,3.45-0.5,6.5-2.36,8.59c-1.69,1.89-4.11,2.91-7,2.95
                    c-3.08,0.03-4.76-0.38-6.55-0.83c-1.76-0.44-3.59-0.9-7.1-1.05c-3.08-0.13-6.21,0.52-9.53,1.21C37.23,80.84,34.04,81.5,30.84,81.5"
                />
                <path  fill={props.color} d="M43.27,47.73c-0.43,0-0.8-0.33-0.84-0.77l-0.64-7.24c-0.04-0.46,0.3-0.87,0.76-0.92
                    c0.45-0.02,0.87,0.3,0.92,0.77l0.65,7.24c0.04,0.46-0.3,0.87-0.77,0.92C43.32,47.73,43.29,47.73,43.27,47.73"/>
                <path  fill={props.color} d="M31.19,59.5c-0.34,0-0.65-0.2-0.78-0.53c-0.17-0.43,0.04-0.92,0.47-1.09l6.26-2.48
                    c0.43-0.17,0.92,0.04,1.09,0.47c0.17,0.43-0.04,0.92-0.47,1.09l-6.26,2.48C31.4,59.48,31.29,59.5,31.19,59.5"/>
                <path  fill={props.color} d="M39.71,51.72c-0.15,0-0.31-0.04-0.45-0.13l-8.98-5.65c-0.39-0.25-0.51-0.77-0.26-1.16
                    c0.25-0.39,0.77-0.51,1.16-0.26l8.97,5.66c0.39,0.25,0.51,0.77,0.26,1.16C40.26,51.58,39.99,51.72,39.71,51.72"/>
                <path  fill={props.color} d="M82.06,68.03c-0.21,0-0.41-0.07-0.57-0.22l-5.34-4.94c-0.34-0.31-0.36-0.85-0.04-1.19
                    c0.32-0.34,0.85-0.36,1.19-0.05l5.34,4.94c0.34,0.32,0.36,0.85,0.04,1.19C82.51,67.94,82.29,68.03,82.06,68.03"/>
                <path  fill={props.color} d="M74.81,52.29c-0.13,0-0.25-0.03-0.37-0.09c-0.42-0.21-0.59-0.71-0.38-1.13l2.98-6.03
                    c0.21-0.42,0.71-0.59,1.13-0.38c0.42,0.21,0.59,0.71,0.38,1.13l-2.98,6.03C75.42,52.12,75.12,52.29,74.81,52.29"/>
                <path  fill={props.color} d="M76.69,57.74c-0.4,0-0.76-0.29-0.83-0.7c-0.08-0.46,0.23-0.9,0.68-0.97L87,54.24
                    c0.44-0.08,0.89,0.23,0.97,0.69c0.08,0.46-0.23,0.89-0.69,0.97l-10.45,1.82C76.79,57.73,76.74,57.74,76.69,57.74"/>
                <path  fill={props.color} d="M56.6,67.99c-0.44,0-0.81-0.34-0.84-0.79c-0.03-0.46,0.32-0.87,0.78-0.9c2.01-0.13,3.48-0.81,4.35-1.99
                    c1.58-2.13,0.86-5.31,0.85-5.35c-0.11-0.45,0.17-0.91,0.63-1.01c0.45-0.11,0.91,0.17,1.01,0.62c0.04,0.16,0.91,3.96-1.13,6.72
                    c-1.18,1.61-3.07,2.51-5.6,2.68C56.64,67.99,56.62,67.99,56.6,67.99"/>
            </g>
        </svg>
    )
}

export default InjuryIcon;