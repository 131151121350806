import React from 'react';

export const Nutrition = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M59.9,31.2h-0.6v-0.6c0-1.7-1.1-3.2-2.7-3.8V24c0-1.1-0.9-2-2-2h-5.1c-1.1,0-2,0.9-2,2v2.5H37.3c-2.3,0-4.1,1.8-4.1,4.1
			v0.6h-0.6c-1.7,0-3.1,1.4-3.1,3.1c0,1.4,1,2.6,2.3,3v9.3c0,3.4,0.4,6.8,1.1,10.1c0.7,3.2,1,6.6,1,9.9v16.6c0,1.4,1.2,2.5,2.6,2.5
			h13c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6h-13c-0.7,0-1.3-0.6-1.3-1.3V66.7c0-3.4-0.4-6.8-1.1-10.1c-0.7-3.2-1-6.6-1-9.9
			v-9.2h26.8v7.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6v-7.8c1.2-0.4,2.1-1.6,2.1-2.9C63,32.6,61.6,31.2,59.9,31.2z M48.7,24
			c0-0.4,0.4-0.8,0.8-0.8h5.1c0.4,0,0.8,0.4,0.8,0.8v2.5c-0.1,0-0.2,0-0.2,0h-6.4V24z M34.4,30.6c0-1.6,1.3-2.9,2.9-2.9h17.9
			c1.6,0,2.9,1.3,2.9,2.9v0.6H34.4V30.6z M59.9,36.2H32.6c-1.1,0-1.9-0.9-1.9-1.9c0-1.1,0.9-1.9,1.9-1.9h27.3c1.1,0,1.9,0.9,1.9,1.9
			C61.8,35.4,60.9,36.2,59.9,36.2z"/>
                <g>
                    <path d="M77.9,68.3c-0.3-0.4-0.8-0.6-1.3-0.6c0.6-2.4,0.7-5.3,0.7-5.5l0-0.5l-0.5-0.1c-0.1,0-2.1-0.6-3.8-1
				c0.1-0.2,0.1-0.5,0.2-0.7c1.2-7.4-5.8-14.1-6.1-14.3l-0.3-0.2l-0.3,0.1c-0.3,0.1-4.8,1.7-8.1,4.9c-4.4-3.6-11.4-2.3-11.7-2.3
				l-0.4,0.1l-0.1,0.3c-0.1,0.3-2.5,8.3,1.6,12.4c-1.1,0.3-1.8,0.6-1.9,0.6l-0.4,0.1l0,0.4c0,0.1-0.2,2.9,0.7,5.7h-1
				c-0.5,0-1,0.2-1.3,0.6c-0.3,0.4-0.5,0.9-0.5,1.4c0.7,9,8.3,16.1,17.4,16.1c9.1,0,16.7-7.1,17.4-16.1
				C78.4,69.2,78.2,68.7,77.9,68.3z M76.1,62.7c-0.1,1-0.2,3.2-0.7,5h-5l2.5-1.8c0.3-0.2,0.3-0.6,0.2-0.8c-0.2-0.3-0.6-0.3-0.8-0.2
				l-4,2.8h-0.5c1.9-1.4,3.9-3.4,4.9-5.9C73.9,62.1,75.4,62.5,76.1,62.7z M66.6,46.8c1.2,1.3,6.4,7,5.4,13c-0.6,3.5-3.7,6.2-6.2,7.8
				c-0.1,0-0.1,0.1-0.1,0.1h-3.2l2.7-12.4c0.1-0.3-0.1-0.7-0.5-0.7c-0.3-0.1-0.7,0.1-0.7,0.5l-2.8,12.7h-1.4
				c-1.7-2.6-3.8-6.5-3.5-10.8C56.7,50.9,64.8,47.5,66.6,46.8z M47.4,49.2c1.4-0.2,6.8-0.7,10.2,2c-1.3,1.5-2.3,3.4-2.5,5.6
				c0,0.1,0,0.2,0,0.2l-2.9-3.2c-0.2-0.2-0.6-0.3-0.9,0c-0.2,0.2-0.3,0.6,0,0.8l3.7,4.1c0,0,0.1,0.1,0.1,0.1c0,0.6,0.1,1.2,0.2,1.7
				c-2.1-0.6-4.3-0.4-6.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.4-0.3C45.6,57,47,50.8,47.4,49.2z M55.8,62.1
				c0.6,2.2,1.6,4.1,2.6,5.7h-3.2c-0.1,0-0.2-0.1-0.2-0.2l-3.2-2.3c-0.3-0.2-0.7-0.1-0.8,0.1c-0.2,0.3-0.1,0.6,0.1,0.8l2,1.5h-5.5
				c-0.8-2.1-0.8-4.3-0.8-5.2C48.1,62.1,52.4,60.8,55.8,62.1z M60.9,84.6c-8.4,0-15.6-6.6-16.2-15c0-0.2,0.1-0.3,0.2-0.5
				c0.1-0.1,0.3-0.2,0.4-0.2h31.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.5C76.5,78,69.4,84.6,60.9,84.6z"/>
                </g>
            </g>
        </svg>
    );
};