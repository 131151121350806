import React from 'react';

export const Zinc = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M43.3,79.7c-3.5,0-6.3-2.8-6.3-6.3c0-1.2,0.3-2.4,1-3.4c0.2-0.3,0.7-0.5,1-0.2c0.3,0.2,0.4,0.7,0.2,1
					c-0.5,0.8-0.8,1.7-0.8,2.6c0,2.7,2.2,4.8,4.8,4.8c1.5,0,2.9-0.7,3.8-1.9c0.3-0.3,0.7-0.4,1.1-0.1c0.3,0.3,0.4,0.7,0.1,1.1
					C47.2,78.8,45.3,79.7,43.3,79.7z"/>
                <path d="M64.2,79.7c-2,0-3.8-0.9-5-2.5c-0.3-0.3-0.2-0.8,0.1-1.1c0.3-0.3,0.8-0.2,1.1,0.1c0.9,1.2,2.3,1.9,3.8,1.9
					c2.7,0,4.8-2.2,4.8-4.8c0-0.9-0.3-1.8-0.8-2.6c-0.2-0.3-0.1-0.8,0.2-1c0.3-0.2,0.8-0.1,1,0.2c0.7,1,1,2.2,1,3.4
					C70.5,76.9,67.7,79.7,64.2,79.7z"/>
                <g>
                    <path d="M81.1,45.2c-0.7-0.5-1-1.1-1.2-2.2c-0.2-1.1-0.6-2.2-1.3-3.2c-1.3-1.9-3.4-2.6-5.4-3l-0.3,0c-0.4-0.1-0.5-0.1-0.6-0.4
							c-0.8-3.1-3-5.1-6-5.7c-1.2-0.3-2.5-0.3-3.7-0.4c-0.7,0-1.4-0.1-2-0.2c-0.6-0.1-1-0.2-1.3-0.4c-3.2-2.1-7.6-2-10.8,0.1
							c-0.2,0.2-0.6,0.3-0.9,0.3c-0.7,0.1-1.4,0.1-2.1,0.1c-0.8,0-1.5,0.1-2.3,0.2c-3.7,0.4-6.2,2.2-7.3,5.4
							c-0.3,0.9-0.6,1.1-1.5,1.2c-4,0.8-6.3,3.2-6.5,6.9c0,0.2-0.3,0.7-0.6,0.9c-3.1,2.4-4.7,5.3-4.9,8.7c0,2,0.7,3.9,2.2,6
							c2.9,4,25.5,19,25.7,19.1c1.2,0.8,2.5,1.1,3.7,1.1c1.2,0,2.3-0.3,3.4-1c0.1-0.1,21.3-15,25-18.3c0.8-0.7,1.4-1.4,1.8-2.2
							c0.8-1.5,1.2-3.1,1.2-4.7C85.4,50.4,83.8,47.3,81.1,45.2z M82.8,57.5c-0.4,0.6-0.9,1.3-1.5,1.8C78,62.3,57.5,76.9,56.6,77.4
							c-1.9,1.1-3.7,1-5.6-0.1c-1.3-0.8-22.7-15.2-25.3-18.7c-1.3-1.8-1.9-3.3-1.9-5c0.2-3,1.6-5.4,4.3-7.6c0,0,0,0,0.1,0
							c0.5,1.7,1.8,5,3.6,7.2c2.5,3,8,7.9,8.2,8.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.3c0.3-0.3,0.2-0.8-0.1-1
							c-0.1,0-5.7-5.1-8-7.9c-2.3-2.8-3.6-7.7-3.6-7.7c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.4c0.2-3.8,3-5.1,5.3-5.5
							c0.6-0.1,1.1-0.3,1.5-0.6c0.3,1.7,1,4.7,2.2,7.6c1.8,4.3,5.8,12.1,5.9,12.2c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1
							c0.4-0.2,0.5-0.6,0.3-1c0-0.1-4.1-7.8-5.8-12c-1.7-4.1-2.3-8.5-2.3-8.6c0-0.1,0-0.2-0.1-0.3c0.9-2.5,2.9-3.9,6-4.2
							c0.8-0.1,1.5-0.1,2.3-0.2c0.7,0,1.4-0.1,2-0.1c-0.3,1.2-0.7,3.2-0.5,5.9c0.2,4.2,2.3,15.7,2.4,16.1c0.1,0.4,0.4,0.6,0.7,0.6
							c0,0,0.1,0,0.1,0c0.4-0.1,0.7-0.5,0.6-0.9c0-0.1-2.2-11.9-2.4-16c-0.2-3.7,0.7-6.1,0.8-6.4c1.4-0.9,3-1.4,4.6-1.4
							c1.6,0,3.2,0.4,4.5,1.3c0,0,0,0,0,0v0c0,0,1,2.5,0.8,6.5c-0.2,4.1-2.4,15.9-2.4,16c-0.1,0.4,0.2,0.8,0.6,0.9
							c0.1,0,0.1,0,0.1,0c0.4,0,0.7-0.3,0.7-0.6c0.1-0.5,2.2-12,2.4-16.1c0.1-2.7-0.2-4.8-0.5-6c0.1,0,0.1,0,0.2,0
							c0.7,0.1,1.4,0.1,2.1,0.2c1.2,0.1,2.4,0.1,3.5,0.4c2.4,0.5,4,2,4.8,4.3c0,0,0,0.1,0,0.1c0,0-0.6,4.4-2.3,8.6
							c-1.7,4.2-5.8,12-5.8,12.1c-0.2,0.4,0,0.8,0.3,1c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.5-0.2,0.7-0.4c0-0.1,4.1-7.9,5.9-12.2
							c1.2-2.9,1.9-5.9,2.2-7.6c0.2,0.1,0.4,0.1,0.6,0.2l0.2,0c2,0.4,3.5,1,4.5,2.4c0.5,0.8,0.9,1.7,1,2.5c0.1,0.5,0.2,1,0.4,1.4
							c-0.2,0.7-1.5,5.1-3.6,7.6c-2.4,2.9-8,7.9-8,7.9c-0.3,0.3-0.3,0.8-0.1,1c0.2,0.2,0.3,0.3,0.6,0.3c0.2,0,0.4-0.1,0.5-0.2
							c0.2-0.2,5.7-5.1,8.2-8.1c1.8-2.1,3-5.3,3.5-7c0.1,0.1,0.2,0.2,0.3,0.3C83.8,49.1,84.9,53.9,82.8,57.5z"/>
                </g>
            </g>
        </svg>
    );
};