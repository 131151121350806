import React from 'react'

function PowerenduranceIcon(props) {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"/>
                </clipPath>
                <path  fill={props.color} d="M48.04,29.34c-0.34,0-0.66-0.21-0.79-0.54c-0.17-0.43,0.05-0.92,0.48-1.09c1.25-0.48,2.25-1.46,2.8-2.74
                    c0.56-1.28,0.59-2.69,0.1-3.95c-1.01-2.6-3.93-3.9-6.54-2.9c-2.71,1.05-3.94,3.62-3,6.26c0.03,0.09,0.14,0.3,0.24,0.5
                    c0.14,0.29,0.28,0.57,0.32,0.69c0.16,0.44-0.07,0.92-0.51,1.07c-0.44,0.15-0.92-0.07-1.07-0.51c-0.04-0.1-0.14-0.3-0.24-0.5
                    c-0.14-0.28-0.28-0.57-0.32-0.69c-1.24-3.5,0.43-7.03,3.98-8.4c3.47-1.34,7.38,0.4,8.72,3.86c0.65,1.68,0.6,3.54-0.13,5.23
                    c-0.74,1.7-2.06,3-3.74,3.64C48.24,29.32,48.14,29.34,48.04,29.34"/>
                <path  fill={props.color} d="M63.03,44.75c-0.01,0-0.02,0-0.04,0c-15.06-0.65-15.29-1.26-15.52-1.84c-0.97-2.53-7.95-17.99-8.02-18.15
                    c-0.19-0.43,0-0.92,0.42-1.11c0.42-0.19,0.92,0,1.11,0.42c0.28,0.62,6.6,14.63,7.93,17.92c1.43,0.35,8.11,0.81,14.14,1.08
                    c0.47,0.02,0.83,0.41,0.81,0.88C63.85,44.4,63.48,44.75,63.03,44.75"/>
                <path  fill={props.color} d="M27.03,48.02c-0.17,0-0.34-0.05-0.49-0.15c-0.38-0.27-0.47-0.79-0.2-1.17c0.35-0.5,0.98-1.67,1.71-3.01
                    c3.75-6.93,7.74-13.7,11.51-13.7h0c0.47,0,0.84,0.38,0.84,0.84c0,0.47-0.38,0.84-0.84,0.84h0c-3.09,0-7.78,8.65-10.03,12.81
                    c-0.79,1.45-1.41,2.6-1.82,3.18C27.56,47.89,27.3,48.02,27.03,48.02"/>
                <path  fill={props.color} d="M47.61,75.58c-0.46,0-0.83-0.36-0.84-0.82c-0.16-5.74-0.49-12.11-0.78-13.18c-0.81-0.69-6.04-3.61-10.8-6.11
                    c-0.41-0.22-0.57-0.73-0.36-1.14c0.22-0.41,0.73-0.57,1.14-0.35c4.91,2.58,10.89,5.84,11.44,6.67c0.45,0.68,0.81,5.41,1.04,14.06
                    c0.01,0.47-0.35,0.85-0.82,0.87H47.61z M46.04,61.64h0.01H46.04z"/>
                <path  fill={props.color} d="M30.04,84.79c-0.05,0-0.1,0-0.14-0.01c-0.46-0.08-0.77-0.51-0.69-0.97l3.45-20.22
                    c-0.66-1.53-4.09-7.24-6.33-10.88c-0.24-0.4-0.12-0.92,0.27-1.16c0.4-0.24,0.91-0.12,1.16,0.27c0.61,0.99,5.97,9.67,6.56,11.4
                    l0.07,0.2l-0.04,0.21l-3.49,20.45C30.8,84.5,30.44,84.79,30.04,84.79"/>
                <path  fill={props.color} d="M37.68,87.37c-0.02,0-0.04,0-0.05,0c-0.46-0.03-0.82-0.43-0.79-0.89l0.24-3.98l19.68-5.47L57.3,68l16.65-3.97
                    l1.44-4.78l-8.51-13.2l3.21-19.23l9.48-2.25l-0.73-11.83c-0.03-0.46,0.32-0.86,0.79-0.89c0.47-0.02,0.86,0.32,0.89,0.79l0.82,13.25
                    l-9.77,2.32l-2.92,17.49l8.58,13.3l-1.94,6.46l-16.38,3.9l-0.54,8.99l-19.68,5.47l-0.17,2.78C38.49,87.03,38.12,87.37,37.68,87.37"
                />
                <path  fill={props.color} d="M22.24,43.64c-0.78,0-1.53-0.27-2.14-0.77l-1-0.83c-1.43-1.18-1.64-3.31-0.46-4.75l9.39-11.39
                    c0.57-0.69,1.38-1.12,2.28-1.21c0.9-0.08,1.77,0.18,2.47,0.75l1,0.83c1.9,1.56,0.63,3.17-0.39,4.46l-8.51,11.64
                    c-0.6,0.73-1.41,1.16-2.31,1.25C22.46,43.63,22.35,43.64,22.24,43.64 M30.64,26.37c-0.06,0-0.11,0-0.17,0.01
                    c-0.45,0.04-0.85,0.26-1.14,0.6l-9.39,11.39c-0.59,0.72-0.49,1.78,0.23,2.37l1,0.83c0.35,0.28,0.79,0.41,1.23,0.37
                    c0.45-0.04,0.86-0.26,1.14-0.6l8.5-11.63c1.28-1.62,1.15-1.73,0.66-2.14l-1-0.83C31.4,26.5,31.03,26.37,30.64,26.37"/>
            </g>
        </svg>
    )
}

export default PowerenduranceIcon;