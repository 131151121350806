import React, {useState} from 'react';
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import CategoryIcon from "../CategoryIcon/CategoryIcon";
import Badge from "../Badge/Badge";
import useModal from "../Modal/Modal";
import Text from "../../assets/strings";
import {useStore} from "../../contexts/StateContext";
import highPriority from "../../assets/img/high.png";
import {Axis} from "../Axis/Axis";
import cookiesData from "../../assets/cookies";


function Category(props) {
    let [{lang}] = useStore();

    const style = props.effect ? ['category-inner', props.effect].join(' ') : 'category-inner';
    const [Modal, open] = useModal('root');
    const [badge, setBadge] = useState(null);

    function getBadge ( category ){
        if(category.badge){
            setBadge(category);
            open();
        }
    }
    
    let modalContent;
    if(badge != null) {
        modalContent = (
            <div className="image content">
                <div className="image">
                    <Badge
                        name={badge.name}
                        badge={badge.badge}
                    />
                </div>
                <div className="description">
                    <div className="header">
                        <h3>
                            {Text[lang].badge[badge.name]}
                        </h3>
                    </div>
                    <div>
                        {Text[lang].badge.description}
                    </div>
                </div>
            </div>
        )
    }
    
    return (
        <>
            <Modal>
                <div className="modal">
                    {modalContent}
                </div>
            </Modal>
            <div className="category">
                <div className={style}>
                    <div className="category-icon">
                        {
                            props.hasOwnProperty("priority") && props?.priority ?
                                <div className="priority-wrapper">
                                    <img src={highPriority}  alt="high priority"/>
                                </div>
                            : null
                        }
                        <CategoryIcon name={props.name} color={props.color}/>
                        {props.hasOwnProperty("badge") && props.badge ?
                            <div className="category-badge" onClick={() => getBadge( props )}>
                                <Badge
                                    name={props.name}
                                    badge={props.badge}
                                />
                            </div>
                        : null}
                    </div>
                    <div className="progress">
                        <h5 dangerouslySetInnerHTML={{__html: props.label}}/>
                        <HorizontalBar
                            activeColor={props.color}
                            inactiveColor="#e5e5e5"
                            value={props.value}
                            stroke="black"
                            strokeWidth={0}
                        />
                        <Axis color={props.color}/>
                        {/*<small>{`${Math.round(props.value * 100)}%`}</small>*/}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Category;