import React from 'react';
import PowerIcon from "../../assets/icons/Power";
import VoIcon from "../../assets/icons/Vo";
import EnduranceIcon from "../../assets/icons/Endurance";
import PowerenduranceIcon from "../../assets/icons/Powerendurance";
import MuscleIcon from "../../assets/icons/Muscle";
import InjuryIcon from "../../assets/icons/Injury";
import RecoveryIcon from "../../assets/icons/Recovery";
import {Zinc} from "../../assets/icons/Zinc";
import {Magnesium} from "../../assets/icons/Magnesium";
import {Selenium} from "../../assets/icons/Selenium";
import {Iron} from "../../assets/icons/Iron";
import {Calcium} from "../../assets/icons/Calcium";
import {Antioxidants} from "../../assets/icons/Antioxidants";
import {Vitamins} from "../../assets/icons/Vitamins";
import {Caffeine} from "../../assets/icons/Caffeine";

function CategoryIcon(props) {

    switch (props.name) {
        case "power":
            return <PowerIcon color={props.color}/>;
        case "endurance":
            return <EnduranceIcon color={props.color}/>;
        case "powerendurance":
            return <PowerenduranceIcon color={props.color}/>;
        case "vo":
            return <VoIcon color={props.color}/>;
        case "muscle":
            return <MuscleIcon color={props.color}/>;
        case "injury":
            return <InjuryIcon color={props.color}/>;
        case "needs":
            return <RecoveryIcon color={props.color}/>;
        case "iron":
            return <Iron color={props.color}/>;
        case "selenium":
            return <Selenium color={props.color}/>;
        case "magnesium":
            return <Magnesium color={props.color}/>;
        case "calcium":
            return <Calcium color={props.color}/>;
        case "zinc":
            return <Zinc color={props.color}/>;
        case "vitamins":
            return <Vitamins color={props.color}/>;
        case "antioxidants":
            return <Antioxidants color={props.color}/>;
        case "caffeine":
            return <Caffeine color={props.color}/>;
        default: return;
    }
}

export default CategoryIcon;