import React from 'react';
import Text from "../../../assets/strings";
import Markdown from 'react-markdown';
import {useStore} from "../../../contexts/StateContext";
import FoodText from "../../../assets/foodList";
import {FoodList} from "../../FoodList/FoodList";

function NewData(props) {
    let [{lang}] = useStore();
    const recommendationTitle = Text[lang].plan[props.location.state.type]["recommendation-title"] != null ? Text[lang].plan[props.location.state.type]["recommendation-title"] : null;
    
    const category = props.location.state.type;
    
    const area = props.location.state.name;
    
    let recommendations = props.location.state.recommendations.map((r,i) => <Markdown source={r} key={i}/>);
    
    let descriptions = props.location.state.descriptions.map((r,i) => <Markdown source={r} key={i}/>);
    
    let predispositions = props.location.state.predispositions.map((r,i) => <Markdown source={r} key={i}/>);
    
    let predispositionExtra = props.location.state.predispositionExtra.map((r,i) => <Markdown source={r} key={i}/>);
    
    return (
        <>
            <div style={{
                background: `rgba(215, 200, 237, 0.5)`,
                borderRadius: '8px',
                padding: '20px',
                margin: '20px 0 50px'}}>


                <ul style={{
                    listStyle: 'initial'}}>
                    {descriptions}
                </ul>
    
                {predispositions.length > 0 ?
                    <>
                        <hr/>
                        <ul style={{
                            listStyle: 'initial'
                        }}>
                            {predispositions}
                        </ul>
                    </>
                 : null}
                
   
            </div>
    
            {predispositionExtra.length > 0 ?
                <div style={{
                    background: `rgba(215, 200, 237, 0.5)`,
                    borderRadius: '8px',
                    padding: '20px',
                    margin: '20px 0 50px'
                }}>
                    <ul style={{
                        listStyle: 'initial'
                    }}>
                        {predispositionExtra}
                    </ul>
                </div>
                : null
            }
    
            {
                recommendations.length > 0 ?
                <>
                    <h4>{recommendationTitle}</h4>
                    <ul >
                        {recommendations}
                    </ul>
                </>
                : null
            }
            
            {
                category === "nutrition" ?
                    <FoodList data={FoodText[lang][area]}/>
                    : null
            }

        </>
    );
}

export default NewData;