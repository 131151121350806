import React, {useEffect, useState} from 'react';
import Categories from "../../components/Categories/Categories";
import axios from "axios";
import Text from "../../assets/strings";
import {useStore} from "../../contexts/StateContext";
import NoMatch from "../../components/NoMatch/NoMatch";
import config from "../../config/config";

export const Nutrition = () => {
    const [{lang, hash}] = useStore();
    const [data, setData] = useState(null);
    const [hasError, setErrorState] = useState(false);
    const [error, setErrorData] = useState(null);
    
    useEffect(() => {
        axios.get(`/${hash}/plan`, {
            params: {
                lang: lang
            }
        }).then(response => {
            //console.log(response)
            setData(response.data);
        })
            .catch(error => {
                setErrorState(true);
                setErrorData(error.response);
                console.log(error.response);
            });
    }, [lang]);
    
    return (
        <section className="container-inner">
            {
                hasError
                    ? <NoMatch error={error}/>
                    : <Categories data={data} text={Text[lang]} area="nutrition" color={config["area-colors"].nutrition}/>
            }
        </section>
    )
};