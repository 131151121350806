import React from 'react';

export const Axis = (props) => {
    return (
        <div className="scale-container">
            <div className="horizontal-bar-percentage">
                <div className="percentage-container">
                    <div className="column-group" style={{borderTopColor: props.color}}>
                        <div className="all-25">
                            <div className="percentage text-left" style={{color:props.color}}>
                                <small>0%</small>
                            </div>
                        </div>
                        <div className="all-15">
                            <div className="percentage text-left" style={{color:props.color}}>
                                <small>25%</small>
                            </div>
                        </div>
                        <div className="all-20">
                            <div className="percentage text-center" style={{color:props.color}}>
                                <small>50%</small>
                            </div>
                        </div>
                        <div className="all-15">
                            <div className="percentage text-right" style={{color:props.color}}>
                                <small>75%</small>
                            </div>
                        </div>
                        <div className="all-25">
                            <div className="percentage text-right" style={{color:props.color}}>
                                <small>100%</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};