import React from 'react';

function VoBadge(props) {

        let styling = props.badge ? 'active-badge' : 'inactive';
        const showSvg = props.badge ? 'initial':'none';


        return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px" style={{display: showSvg}}
             viewBox="0 0 400.7 325" xmlSpace="preserve">
            <path className={ styling }
                  d="M320.6,261.6c6.1-8,10.5-16.7,13.2-26.3c2.7-9.6,3.5-19.4,2.5-29.3C323.9,222.6,318.7,241.1,320.6,261.6z"/>
            <path className={ styling } d="M126.4,297.8c-17.3-11.3-36.1-15.3-56.5-12.1C87.2,297,106.1,301,126.4,297.8z"/>
            <path className={ styling }
                  d="M26.5,157.8c1.4-9.9,1.2-19.7-1.1-29.4c-2.3-9.7-6.3-18.6-12-26.8C10.6,122,14.9,140.7,26.5,157.8z"/>
            <path className={ styling } d="M366.3,258.8c-20.5-2.3-39.2,2.6-55.9,14.6C330.9,275.7,349.6,270.9,366.3,258.8z"/>
            <path className={ styling }
                  d="M90.2,273.5c-4.2-3.6-15.8-10-26.7-12.9c-10.6-2.8-24.7-2.9-29.3-1.8C50.1,270.6,69.8,275.9,90.2,273.5z"/>
            <path className={ styling } d="M344.7,124.4c-0.3,20.6,6.2,38.7,19.8,54.2C364.8,158,358.2,139.9,344.7,124.4z"/>
            <path className={ styling }
                  d="M350.4,221.6c2.8-10,3.9-19.7,3.1-29.7c-0.9-9.9-3.6-19.3-8.1-28.3C339.7,183.5,341.3,202.7,350.4,221.6z"/>
            <path className={ styling }
                  d="M63.2,249.7c-4.9-8.7-11.2-16.3-19-22.6c-7.8-6.3-16.4-10.8-26-13.8C28.6,231.2,43.6,243.3,63.2,249.7z"/>
            <path className={ styling } d="M102.7,7.9c-18.2,9.6-30.9,24.1-38,43.5C83,41.8,95.7,27.3,102.7,7.9z"/>
            <path className={ styling }
                  d="M330.6,285.7c-9.9-1.6-19.7-1.5-29.5,0.6c-8.6,1.8-24.5,8.6-27.1,11.7C294.5,301,313.3,297,330.6,285.7z"/>
            <path className={ styling } d="M311.1,48.5c9.7,18.2,24.2,30.9,43.6,37.9C345,68.1,330.4,55.5,311.1,48.5z"/>
            <path className={ styling } d="M371.6,51.6c-7.7,19.5-8.1,38.8-1,58.2C378.4,90.6,378.7,71.4,371.6,51.6z"/>
            <path className={ styling } d="M387.1,101.6c-5.7,8.3-9.6,17.2-12,26.9c-2.5,10.2-2.3,25.1-0.9,29.5c6.3-9.5,10.7-19.6,12.6-30.7
	C388.3,118.7,388.4,110.1,387.1,101.6z"/>
            <path className={ styling }
                  d="M50.2,221.2c4.5-9,7.2-18.4,8.1-28.3c0.9-9.9-0.2-19.7-3.1-29.2C46.1,182.2,44.5,201.4,50.2,221.2z"/>
            <path className={ styling } d="M42.4,72.3C55.2,56.2,61,37.8,59.7,17.3C46.9,33.4,41.1,51.8,42.4,72.3z"/>
            <path className={ styling } d="M392.6,160.8c-13.3,7.6-28.4,32.4-30.3,49.7c8.5-6.6,15.5-14.3,20.7-23.4C387.7,178.9,390.9,170.2,392.6,160.8z
	"/>
            <path className={ styling }
                  d="M106.5,313.4c9.7,2.9,19.5,4.2,29.4,3.5c10-0.7,19.4-3.3,28.6-7.6C149,303.1,119.5,305.2,106.5,313.4z"/>
            <path className={ styling }
                  d="M338.5,87.7c1.6,9.8,4.9,19,10,27.6c5.1,8.6,11.7,15.9,19.6,22C364.6,116.9,354.8,100.4,338.5,87.7z"/>
            <path className={ styling }
                  d="M8.1,160.9c1.7,9.8,5,19.1,10.2,27.6c5.2,8.5,11.8,15.7,19.8,21.8C34.5,189.9,24.5,173.5,8.1,160.9z"/>
            <path className={ styling } d="M28.9,51.6c-7.1,19.7-6.7,39,1,58.2C37,90.4,36.7,71.1,28.9,51.6z"/>
            <path className={ styling } d="M337.4,249.6c19.6-6.4,34.6-18.5,44.9-36.4C362.6,219.6,347.7,231.7,337.4,249.6z"/>
            <path className={ styling }
                  d="M294,313.3c-6.5-3.6-20.1-7-28.6-7.6c-11.3-0.9-26.1,2-29.1,3.6C249.9,317.5,280.4,319.7,294,313.3z"/>
            <path className={ styling }
                  d="M88.4,230.7c0.1,10,1.9,19.6,5.6,28.8c3.7,9.3,9.1,17.5,16,24.7C109.6,263.7,102.5,245.8,88.4,230.7z"/>
            <path className={ styling } d="M312.2,230.7c-14.1,15.1-21.3,32.9-21.6,53.5C304.7,269.2,311.8,251.3,312.2,230.7z"/>
            <path className={ styling }
                  d="M32.3,137.3c4.8-3,14.3-12.6,19.8-22c4.7-8,10.4-23.7,9.9-27.8C45.8,100.4,35.9,116.9,32.3,137.3z"/>
            <path className={ styling } d="M335.8,51.4c-7.1-19.4-19.8-33.9-38-43.5C304.9,27.3,317.6,41.8,335.8,51.4z"/>
            <path className={ styling } d="M45.9,86.3c9.3-3.3,17.8-8.1,25.2-14.5c7.6-6.6,13.7-14.4,18.4-23.4c-9.5,3.4-18,8.2-25.6,14.8
	C56.4,69.8,50.5,77.5,45.9,86.3z"/>
            <path className={ styling }
                  d="M340.9,17.2c-0.7,9.9,0.3,19.7,3.3,29.2c3,9.5,7.7,18.1,14,25.9C359.4,51.8,353.7,33.4,340.9,17.2z"/>
            <path className={ styling } d="M64.3,206c-1.9,20.6,3.4,39.1,15.8,55.9C81.9,241.1,76.6,222.5,64.3,206z"/>
            <path className={ styling } d="M55.9,124.4C42.3,140,35.7,158,36.1,178.6C49.6,163.1,56.2,145,55.9,124.4z"/>
            <path className={ styling } d="M148,300.9c-5.1-20-16.2-35.7-33.4-47.1C119.7,273.8,130.8,289.5,148,300.9z"/>
            <path className={ styling } d="M285.9,253.8c-17.1,11.4-28.3,27.1-33.3,47.1C269.7,289.5,280.8,273.8,285.9,253.8z"/>
            <path className={ styling } d="M206.7,224c-2.7,1.7-5.2,3.4-7.8,5.1c-0.5,0.3-1.1,0.7-1.6,1c-1.8,1.1-3.4,2.5-4.6,4.4c-2.6,4-6.6,6.1-10.9,7.9
	c-5.7,2.3-11.8,3.4-17.9,4c-6.3,0.7-12.5,0.8-18.8-0.2c-5.7-0.9-11.2-2.5-16-5.8c-7.1-5-11.4-12.1-13-20.5
	c-0.9-4.9-1.4-9.9-1.7-14.9c-0.4-7.1-0.6-14.2-0.5-21.3c0.1-10,1-19.9,3.8-29.5c0.6-2.1,1.5-4.2,2.4-6.2c1-2.3,2.5-4.3,4.7-5.8
	c-1.9-1.7-3.7-3.4-5.7-4.8c-1.7-1.3-2.3-2.6-2-4.8c0.9-8.5,1.6-17,2.4-25.5c0.5-5.6,0.9-11.2,1.6-16.7c1.5-13.2,5-25.6,13-36.5
	c6-8.2,13.7-14.3,22.7-19c10.1-5.2,20.9-8.4,32-10.5c9.5-1.9,19.1-3.3,28.9-3.5c14.2-0.4,28,1.3,41,7.4c10.1,4.7,18.3,11.6,24,21.3
	c4.5,7.7,6,16,5.5,24.8c-0.6,12.4-1.4,24.8-1.8,37.2c-0.9,29.1,3.4,57.4,14,84.7c0.9,2.4,1.9,4.7,2.9,7.2c-1.7,0.8-3.5,1.5-5.2,2.3
	c-1.8,0.8-3.5,1.5-5.4,2.3c-4.9-11.4-8.8-23.1-11.8-35.2c-16.2,6.4-32.2,12.8-48.4,19.2c0.6,3.1,1.2,6.2,1.8,9.3
	c0.2,1.1,0.4,2.2,0.7,3.3c0.2,0.8,0,1.1-0.7,1.5c-5.5,3.6-11,7.2-16.5,10.7c-0.9,0.6-1,1.1-0.6,2c2.2,5.6,4.2,11.2,6.3,16.8
	c0.2,0.4,0.3,0.9,0.5,1.4c-3.6,1.4-7.1,2.7-10.8,4.1C211,235.4,208.9,229.7,206.7,224z M211.9,123.3c1.5-4.9,5-10.7,8.4-14.3
	c7.7-8.1,19.1-7.8,26.2,0.8c5.6,6.8,7.1,14.7,4.9,23.1c-2.5,9.7-6.2,19-9.8,28.4c-0.4,0.9-0.9,1.5-1.8,1.9c-3.7,1.9-7.3,3.9-11,5.9
	c-0.3,0.2-0.9,0.6-0.8,0.8c0.2,1.7,0.6,3.3,0.9,5.1c9-4.1,18-7.7,26.3-12.7c8-4.8,13.5-11.7,16.3-20.7c2.4-7.7,2.4-15.5,0.7-23.3
	c-1.3-5.7-3.4-11.2-7.3-15.7c-6-6.8-13.5-9.8-22.6-8.4c-5.8,0.9-10.9,3.4-15.8,6.6c-8.4,5.6-15.3,12.8-21.5,20.7
	c-11.5,14.7-23,29.5-34.5,44.3c-1.5,1.9-3,3.8-4.6,5.8c-3.8-4.6-7.4-9.2-11.1-13.7c0.2-0.3,0.4-0.5,0.5-0.7
	c8.8-10.6,17.5-21.2,26.3-31.8c13.9-16.9,26.7-34.5,38.8-52.7c5.1-7.8,9.8-15.8,12.8-24.7c1.5-4.4,2.5-8.9,2.1-13.6
	c-0.1-0.8-0.3-1.2-1.2-1.3c-4.1-0.2-8.1-0.7-12.2-0.8c-9.4-0.2-18.6,1.1-27.9,2.7c-10.4,1.9-20.7,4.5-30.3,9.2
	c-8.3,4-15.6,9.4-21,17c-6.1,8.7-8.9,18.5-10.2,28.8c-0.7,5.3-1.1,10.7-1.5,16c-0.7,7.7-1.4,15.4-2.1,23.2c-0.1,0.9,0.2,1.5,0.9,2
	c2.5,2.1,5,4.3,7.6,6.5c0.7,0.5,0.8,1,0.5,1.8c-0.4,1.3-0.7,2.6-1.1,3.9c-0.2,0.7,0,1,0.5,1.4c2.6,2.4,5.2,4.7,7.7,7.2
	c13.4,13.9,25.5,28.9,36.4,44.8c4.7,6.8,8.9,13.8,12,21.6c1.6-1,3.1-2,4.7-3c7.7-5,15.3-10,22.9-15.1c0.3-0.2,0.7-0.7,0.7-0.9
	c-0.1-1.1-0.4-2.2-0.7-3.3c-10.3,4.1-20.4,8.1-30.5,12.1c-2.9-4.7-5.7-9.4-8.5-14.1c0.5-0.2,0.9-0.4,1.3-0.5
	c11.1-4.5,22.2-9,33.3-13.4c0.9-0.4,1.1-0.8,0.9-1.8c-1-4.6-1.8-9.2-2.8-13.9c-0.2-0.9,0-1.3,0.8-1.8c5-2.6,10-5.3,15-8
	c0.5-0.3,1-0.7,1.2-1.2c2.7-7.9,5.6-15.7,8.1-23.7c1.4-4.3,0.5-8.5-2.1-12.2c-2.7-3.7-6-3.9-9.3-0.7c-0.2,0.2-0.4,0.3-0.5,0.5
	c-1.4,2.3-2.9,4.5-4.2,6.8c-0.6,0.9-0.9,2-1.4,3C219.1,126.1,215.5,124.7,211.9,123.3z M146.8,236.4c0,0,0.2,0,0.3,0.1
	c10.3,1.2,20.4,0.3,30.2-3.1c2.4-0.8,4.6-1.9,6.5-3.8c1.4-1.4,1.9-3,1.4-4.9c-1.1-4-2.9-7.6-5-11.1c-12.8-21.4-28.4-40.7-46.1-58.4
	c-1.4-1.4-2.9-2.6-4.6-4c-2.8,5.5-3.6,11.1-4.5,16.9c3.2-0.9,6.2-1.7,9.2-2.5c0.5,1.7,0.9,3.2,1.3,4.8c-0.4,0.1-0.7,0.2-1.1,0.3
	c-3.1,0.9-6.1,1.7-9.2,2.6c-0.3,0.1-0.9,0.4-0.9,0.6c-0.2,1.2-0.2,2.5-0.3,3.8c5-1.4,9.9-2.8,14.8-4.2c0.5,1.6,0.9,3.2,1.4,4.8
	c-5.5,1.6-11,3.1-16.7,4.7c0.2,8,0.4,15.9,0.6,23.8c3.2,0,5.9,0.8,8.2,2.5C141.9,215.9,147.1,224.6,146.8,236.4z M138.5,232.9
	c0.1,0,0.2-0.1,0.3-0.1c-0.7-2-1.1-4.1-2-6.1c-2.1-4.6-5-8.4-9.5-10.9c-0.7-0.4-1.6-0.4-2.4-0.5c-0.1,0.8-0.4,1.6-0.4,2.4
	c0.2,3.2,1.4,6,3,8.6c2.1,3.5,4.6,6.5,8.3,8.4C138,235.9,138.6,235.4,138.5,232.9z"/>
            <path
                className={ styling } d="M320.6,261.6c-1.9-20.5,3.3-39.1,15.7-55.6c1,9.9,0.2,19.7-2.5,29.3C331.1,244.9,326.6,253.7,320.6,261.6z"/>
            <path className={ styling } d="M126.4,297.8C106.1,301,87.2,297,70,285.7C90.3,282.5,109.2,286.5,126.4,297.8z"/>
            <path
                className={ styling } d="M26.5,157.8c-11.6-17.1-15.9-35.8-13.1-56.2c5.7,8.2,9.7,17.1,12,26.8C27.7,138.1,27.9,147.9,26.5,157.8z"/>
            <path className={ styling } d="M366.3,258.8c-16.8,12-35.4,16.9-55.9,14.6C327.2,261.4,345.8,256.5,366.3,258.8z"/>
            <path className={ styling } d="M90.2,273.5c-20.5,2.4-40.1-3-56-14.6c4.6-1.2,18.7-1,29.3,1.8C74.5,263.5,86,269.9,90.2,273.5z"/>
            <path className={ styling } d="M344.7,124.4c13.6,15.5,20.2,33.6,19.8,54.2C350.9,163.1,344.3,145,344.7,124.4z"/>
            <path
                className={ styling } d="M350.4,221.6c-9.1-18.9-10.7-38.1-5-58c4.4,8.9,7.2,18.3,8.1,28.3C354.3,201.8,353.2,211.6,350.4,221.6z"/>
            <path className={ styling } d="M63.2,249.7c-19.6-6.4-34.6-18.5-44.9-36.4c9.5,3,18.2,7.5,26,13.8C52,233.4,58.3,241,63.2,249.7z"/>
            <path className={ styling } d="M102.7,7.9c-7.1,19.4-19.7,33.9-38,43.5C71.8,32,84.5,17.5,102.7,7.9z"/>
            <path
                className={ styling } d="M330.6,285.7c-17.3,11.3-36.1,15.3-56.5,12.2c2.6-3.1,18.5-9.9,27.1-11.7C310.9,284.2,320.7,284.1,330.6,285.7z"/>
            <path className={ styling } d="M311.1,48.5c19.4,7,33.9,19.7,43.6,37.9C335.2,79.3,320.7,66.7,311.1,48.5z"/>
            <path className={ styling } d="M371.6,51.6c7.1,19.8,6.7,39-1,58.2C363.6,90.4,363.9,71.1,371.6,51.6z"/>
            <path className={ styling } d="M387.1,101.6c1.3,8.6,1.2,17.1-0.3,25.6c-2,11.1-6.4,21.3-12.6,30.7c-1.4-4.4-1.5-19.3,0.9-29.5
	C377.5,118.8,381.4,109.8,387.1,101.6z"/>
            <path className={ styling } d="M50.2,221.2c-5.7-19.8-4.1-39,4.9-57.5c2.9,9.5,4,19.3,3.1,29.2C57.4,202.8,54.7,212.2,50.2,221.2z"/>
            <path className={ styling } d="M42.4,72.3c-1.3-20.6,4.4-38.9,17.2-55.1C61,37.8,55.2,56.2,42.4,72.3z"/>
            <path
                className={ styling } d="M392.6,160.8c-1.7,9.4-4.8,18.1-9.5,26.3c-5.3,9.2-12.3,16.8-20.7,23.4C364.2,193.2,379.3,168.4,392.6,160.8z"/>
            <path
                className={ styling } d="M106.5,313.4c13-8.3,42.5-10.4,58.1-4.1c-9.2,4.3-18.7,6.9-28.6,7.6C125.9,317.6,116.2,316.4,106.5,313.4z"/>
            <path
                className={ styling } d="M338.5,87.7c16.2,12.7,26.1,29.2,29.6,49.6c-7.9-6.1-14.5-13.4-19.6-22C343.4,106.7,340.2,97.5,338.5,87.7z"/>
            <path className={ styling } d="M8.1,160.9c16.3,12.6,26.3,29,30,49.3c-8-6-14.6-13.2-19.8-21.8C13.2,179.9,9.9,170.7,8.1,160.9z"/>
            <path className={ styling } d="M28.9,51.6c7.7,19.5,8.1,38.8,1,58.2C22.2,90.6,21.8,71.4,28.9,51.6z"/>
            <path className={ styling } d="M337.4,249.6c10.3-17.9,25.3-30,44.9-36.4C372,231.1,357,243.3,337.4,249.6z"/>
            <path className={ styling } d="M294,313.3c-13.6,6.3-44.1,4.2-57.7-4c3.1-1.6,17.9-4.5,29.1-3.6C274,306.3,287.5,309.7,294,313.3z"/>
            <path
                className={ styling } d="M88.4,230.7c14.1,15.1,21.2,32.9,21.6,53.6c-6.9-7.3-12.3-15.5-16-24.7C90.2,250.3,88.4,240.7,88.4,230.7z"/>
            <path className={ styling } d="M312.2,230.7c-0.3,20.6-7.5,38.4-21.6,53.5C290.9,263.7,298.1,245.8,312.2,230.7z"/>
            <path
                className={ styling } d="M32.3,137.3c3.7-20.4,13.5-36.9,29.7-49.8c0.5,4.1-5.2,19.8-9.9,27.8C46.5,124.7,37,134.3,32.3,137.3z"/>
            <path className={ styling } d="M335.8,51.4c-18.2-9.6-30.9-24.1-38-43.5C316,17.5,328.7,32,335.8,51.4z"/>
            <path className={ styling } d="M45.9,86.3c4.6-8.8,10.5-16.5,18-23.1c7.6-6.6,16.1-11.5,25.6-14.8c-4.7,9-10.7,16.8-18.4,23.4
	C63.7,78.3,55.2,83.1,45.9,86.3z"/>
            <path
                className={ styling } d="M340.9,17.2c12.8,16.2,18.5,34.5,17.2,55.1c-6.3-7.8-11-16.4-14-25.9C341.2,36.9,340.2,27.2,340.9,17.2z"/>
            <path className={ styling } d="M64.3,206c12.4,16.5,17.6,35,15.8,55.9C67.6,245.1,62.4,226.6,64.3,206z"/>
            <path className={ styling } d="M55.9,124.4c0.4,20.6-6.2,38.7-19.8,54.2C35.7,158,42.3,140,55.9,124.4z"/>
            <path className={ styling } d="M148,300.9c-17.2-11.4-28.3-27.1-33.4-47.1C131.8,265.2,142.9,280.9,148,300.9z"/>
            <path className={ styling } d="M285.9,253.8c-5.1,20-16.2,35.7-33.3,47.1C257.6,280.9,268.8,265.2,285.9,253.8z"/>
            <path className={ styling } d="M172.7,104.4c1.6,3.6,3.2,7,4.7,10.2c-6.9,4.3-13.8,8.5-20.8,12.7c2,3.2,2.3,6.4-0.2,9.5c-1.9,2.4-4.6,3.3-7.6,2.5
	c-3.6-0.9-5.4-3.5-5.8-7.1c-3,0.5-6,1-9.2,1.5c-0.3-4-0.6-7.8-0.8-11.6c1.9-0.3,3.6-0.6,5.4-1c7-1.5,13.6-4.1,19.5-8.3
	C162.5,109.6,167.4,106.8,172.7,104.4z"/>
    </svg>
    )
}

export default VoBadge;