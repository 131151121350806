import React from 'react'

function PowerIcon(props) {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
        <g>
            <defs>
                <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
            </defs>
            <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_"/>
            </clipPath>
            <path  fill={props.color} d="M66.02,67.3h-0.57c-1.9,0-3.44-1.55-3.44-3.44v-28.5c0-1.9,1.54-3.44,3.44-3.44h0.57
        c1.9,0,3.44,1.54,3.44,3.44v28.5C69.47,65.75,67.92,67.3,66.02,67.3 M65.45,33.6c-0.97,0-1.76,0.79-1.76,1.76v28.5
        c0,0.97,0.79,1.76,1.76,1.76h0.57c0.97,0,1.76-0.79,1.76-1.76v-28.5c0-0.97-0.79-1.76-1.76-1.76H65.45z"/>
            <path  fill={props.color} d="M74.92,62.56h-0.58c-1.9,0-3.44-1.54-3.44-3.44V40.94c0-1.9,1.54-3.44,3.44-3.44h0.58
        c1.9,0,3.44,1.54,3.44,3.44v18.17C78.36,61.01,76.82,62.56,74.92,62.56 M74.34,39.19c-0.97,0-1.76,0.79-1.76,1.76v18.17
        c0,0.97,0.79,1.76,1.76,1.76h0.58c0.97,0,1.76-0.79,1.76-1.76V40.94c0-0.97-0.79-1.76-1.76-1.76H74.34z"/>
            <path  fill={props.color} d="M25.01,62.56h-0.57c-1.9,0-3.44-1.54-3.44-3.44V40.94c0-1.9,1.55-3.44,3.44-3.44h0.57
        c1.9,0,3.44,1.54,3.44,3.44v18.17C28.45,61.01,26.9,62.56,25.01,62.56 M24.43,39.19c-0.97,0-1.76,0.79-1.76,1.76v18.17
        c0,0.97,0.79,1.76,1.76,1.76h0.57c0.97,0,1.76-0.79,1.76-1.76V40.94c0-0.97-0.79-1.76-1.76-1.76H24.43z"/>
            <path  fill={props.color} d="M34.04,67.3h-0.57c-1.9,0-3.44-1.55-3.44-3.44v-28.5c0-1.9,1.54-3.44,3.44-3.44h0.57
        c1.9,0,3.44,1.54,3.44,3.44v28.5C37.48,65.75,35.94,67.3,34.04,67.3 M33.46,33.6c-0.97,0-1.76,0.79-1.76,1.76v28.5
        c0,0.97,0.79,1.76,1.76,1.76h0.57c0.97,0,1.76-0.79,1.76-1.76v-28.5c0-0.97-0.79-1.76-1.76-1.76H33.46z"/>
            <path  fill={props.color} d="M82.54,57.7H82.2c-1.31,0-2.37-1.06-2.37-2.37v-9.41c0-1.31,1.06-2.37,2.37-2.37h0.34
        c1.31,0,2.37,1.06,2.37,2.37v9.41C84.91,56.64,83.85,57.7,82.54,57.7 M82.2,45.24c-0.38,0-0.69,0.31-0.69,0.69v9.41
        c0,0.38,0.31,0.69,0.69,0.69h0.34c0.38,0,0.68-0.31,0.68-0.69v-9.41c0-0.38-0.31-0.69-0.68-0.69H82.2z"/>
            <path  fill={props.color} d="M17.01,57.7h-0.34c-1.31,0-2.37-1.06-2.37-2.37v-9.41c0-1.31,1.06-2.37,2.37-2.37h0.34
        c1.31,0,2.37,1.06,2.37,2.37v9.41C19.38,56.64,18.32,57.7,17.01,57.7 M16.67,45.24c-0.37,0-0.69,0.31-0.69,0.69v9.41
        c0,0.37,0.31,0.69,0.69,0.69h0.34c0.38,0,0.68-0.31,0.68-0.69v-9.41c0-0.38-0.31-0.69-0.68-0.69H16.67z"/>
            <path  fill={props.color} d="M60.12,47.25H39.37c-0.46,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h20.75c0.47,0,0.84,0.38,0.84,0.84
        C60.96,46.87,60.58,47.25,60.12,47.25"/>
            <path  fill={props.color} d="M60.12,53.65H39.37c-0.46,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h20.75c0.47,0,0.84,0.38,0.84,0.84
        C60.96,53.27,60.58,53.65,60.12,53.65"/>
        </g>
        </svg>
    )
}

export default PowerIcon;