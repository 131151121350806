import React from 'react'

function PowerBadge(props) {

        let styling = props.badge ? 'active-badge' : 'inactive';
        const showSvg = props.badge ? 'initial':'none';

        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" style={{display: showSvg}}
                 viewBox="0 0 400.5 340.4" xmlSpace="preserve">
                    <path className={styling} d="M200.2,180.4c-16.5,0-32.9,0-49.4,0c-1.3,0-2.2-0.3-3.2-1.2c-7.2-7-14.5-13.8-21.8-20.8c-0.4-0.4-0.7-1.1-0.7-1.6
	c0-15.2,0-30.4,0-45.6c0-0.6,0.3-1.3,0.7-1.7c7.4-7.1,14.9-14.1,22.4-21.1c0.4-0.4,1-0.6,1.6-0.6c33.6,0,67.2,0,100.7,0
	c0.6,0,1.3,0.3,1.7,0.7c7.5,7,14.9,14,22.3,21.1c0.4,0.4,0.7,1.1,0.7,1.7c0,15.2,0,30.4,0.1,45.6c0,1-0.5,1.4-1.1,2
	c-7.3,7-14.7,13.9-22,20.9c-0.5,0.4-1.2,0.7-1.9,0.7C233.7,180.4,216.9,180.4,200.2,180.4z M243.3,168.7c0-23.1,0-46.2,0-69.3
	c-28.8,0-57.5,0-86.2,0c0,23.2,0,46.2,0,69.3C185.9,168.7,214.6,168.7,243.3,168.7z M145.4,107.2c-0.2,0.1-0.3,0.1-0.4,0.2
	c-2.7,2.5-5.3,5-7.9,7.5c-0.3,0.3-0.4,0.8-0.4,1.2c0,12,0,23.9,0,35.9c0,0.5,0.2,1,0.6,1.3c2.3,2.3,4.7,4.5,7.1,6.8
	c0.3,0.3,0.6,0.5,1.1,0.9C145.4,142.9,145.4,125.1,145.4,107.2z M255.1,107.1c0,18,0,35.7,0,53.8c2.9-2.7,5.6-5.3,8.2-7.9
	c0.2-0.2,0.4-0.7,0.4-1.1c0-12,0-24,0-36c0-0.4-0.2-0.9-0.4-1.1C260.7,112.3,258,109.8,255.1,107.1z"/>
                    <path className={styling} d="M215.5,242c0,7.6,0,15,0,22.5c-10.2,0-20.3,0-30.6,0c0-2.5,0-4.9,0-7.3c0-1.2-0.3-2.6,0.2-3.4c0.5-0.8,2-1,3.1-1.4
	c8.6-3.3,17.1-6.5,25.7-9.8C214.4,242.4,214.9,242.3,215.5,242z"/>
                    <path className={styling}
                          d="M178.4,280.4c0-3.9,0-7.6,0-11.4c14.5,0,29,0,43.5,0c0,3.8,0,7.6,0,11.4C207.5,280.4,193,280.4,178.4,280.4z"/>
                    <path className={styling} d="M193.2,8.1c5.3,0,10.3,0,15.6,0c-2.6,5.2-5.1,10.3-7.8,15.6c3.8,0,7.4,0,11.3,0c-5.4,8.8-10.7,17.3-15.9,25.8
	c-0.1,0-0.2,0-0.2-0.1c0.7-5.7,1.3-11.4,2-17.3c-3.4,0-6.7,0-10.2,0C189.8,24,191.5,16.1,193.2,8.1z"/>
                    <path className={styling} d="M185,204.4c0-6.8,0-13.6,0-20.4c10.2,0,20.2,0,30.4,0c0,0.2,0.1,0.5,0.1,0.8c0,2.4,0,4.8,0,7.2c0,0.4-0.3,1-0.6,1.1
	c-9.9,3.8-19.8,7.5-29.6,11.3C185.3,204.4,185.3,204.4,185,204.4z"/>
                    <path className={styling}
                          d="M79.9,276.9c-12.4-16.5-17.6-35-15.7-55.5C76.6,237.8,81.8,256.3,79.9,276.9z"/>
                    <path className={styling} d="M69.9,301c20.4-3.2,39.2,0.8,56.4,12C106,316.3,87.2,312.3,69.9,301z"/>
                    <path className={styling} d="M274,313.1c6.3-4.3,13.2-7.5,20.5-9.8c11.8-3.7,23.8-4.2,36-2.3c-8.3,5.5-17.3,9.4-27,11.5
	C293.8,314.6,283.9,314.7,274,313.1z"/>
                    <path className={styling}
                          d="M371.6,66.9c7.1,19.7,6.7,39-0.9,58.2c-1.8-2.7-5.6-17.2-5.3-29.1C365.7,86,367.8,76.4,371.6,66.9z"/>
                    <path className={styling}
                          d="M293.8,328.6c-19.7,6-38.9,4.7-57.5-4.1C256,318.6,275.1,319.9,293.8,328.6z"/>
                    <path className={styling}
                          d="M45.9,101.6c9.7-18.2,24.2-30.8,43.6-37.9C79.8,81.9,65.3,94.6,45.9,101.6z"/>
                    <path className={styling}
                          d="M345.3,178.9c9,18.6,10.7,37.8,5,57.9C341.3,217.9,339.6,198.8,345.3,178.9z"/>
                    <path className={styling}
                          d="M368.3,152.7c-13.5-8.3-28.9-34.1-29.9-50C354.7,115.6,364.5,132.1,368.3,152.7z"/>
                    <path className={styling}
                          d="M26.4,173.1c-6.4-9.5-10.9-19.8-12.8-31.1c-1.4-8.4-1.6-16.8-0.2-25.2C25,133.9,29.3,152.7,26.4,173.1z"/>
                    <path className={styling}
                          d="M387.2,116.6c2.8,20.6-1.6,39.4-13.1,56.6c-1.3-3.5-1.6-18.7,0.9-29.4C377.5,133.3,383.2,121.7,387.2,116.6z"/>
                    <path className={styling}
                          d="M252.5,316.2c5.1-20,16.2-35.7,33.4-47.1c-2.4,9.7-6.3,18.6-12.1,26.8C268,303.9,260.9,310.7,252.5,316.2z"/>
                    <path className={styling}
                          d="M164.1,324.6c-18.6,8.7-37.8,10.1-57.5,4.1c9-4.3,18.4-6.9,28.3-7.6C144.9,320.3,154.6,321.5,164.1,324.6z"/>
                    <path className={styling} d="M55.1,178.9c2.7,8.7,3.7,17.6,3.3,26.6c-0.5,10.9-3.4,21.2-8.3,31.4c-2.8-9.9-3.9-19.7-3.1-29.6
	C47.9,197.3,50.6,187.8,55.1,178.9z"/>
                    <path className={styling}
                          d="M366.2,274.1c-16.7,12-35.4,16.9-55.9,14.6c8-5.9,16.8-10.2,26.5-12.7C346.5,273.5,356.3,272.9,366.2,274.1z"/>
                    <path className={styling}
                          d="M88.1,245.8c12.2,10.8,23.3,38.2,21.8,54C95.8,284.5,88.6,266.6,88.1,245.8z"/>
                    <path className={styling} d="M28.8,66.9c7.7,19.5,8.1,38.8,1,58.2C22.1,105.9,21.8,86.6,28.8,66.9z"/>
                    <path className={styling}
                          d="M61.9,102.9c-3.5,20.3-13.4,36.8-29.6,49.6C35.9,132.2,45.7,115.6,61.9,102.9z"/>
                    <path className={styling}
                          d="M36,193.9c-0.4-20.6,6.3-38.7,19.8-54.2C56.1,160.3,49.6,178.4,36,193.9z"/>
                    <path className={styling}
                          d="M290.5,299.6c0.3-20.6,7.5-38.5,21.6-53.6c-0.1,10-1.9,19.6-5.6,28.9C302.8,284.1,297.4,292.3,290.5,299.6z"/>
                    <path className={styling}
                          d="M364.4,193.9c-13.6-15.5-20.1-33.6-19.8-54.2C358.2,155.2,364.8,173.3,364.4,193.9z"/>
                    <path className={styling}
                          d="M310.8,63.7c15,3.3,37.8,23.2,43.9,38.2C335.2,94.6,320.7,82,310.8,63.7z"/>
                    <path className={styling}
                          d="M90.1,288.7c-20.5,2.3-39.1-2.5-55.9-14.6C54.7,271.8,73.3,276.7,90.1,288.7z"/>
                    <path className={styling}
                          d="M336.2,221.3c1.9,20.6-3.4,39.1-15.7,55.6c-1-9.9-0.2-19.7,2.5-29.3C325.7,238,330.2,229.3,336.2,221.3z"/>
                    <path className={styling} d="M114.6,269c8.9,5.9,16.4,13.1,22.3,21.9c5.2,7.7,8.9,16.2,11,25.3c-8.9-5.9-16.5-13.1-22.4-22
	C120.4,286.4,116.7,278,114.6,269z"/>
                    <path className={styling}
                          d="M362.3,225.5c3.7-20.3,13.7-36.8,30.1-49.4c-1.8,10.4-5.5,20.2-11.2,29.1C376.1,213.1,369.8,219.9,362.3,225.5z"/>
                    <path className={styling}
                          d="M340.8,32.6c6.7,8.3,11.6,17.5,14.5,27.7c2.5,8.9,3.4,18.1,2.7,27.4C345.2,71.5,339.5,53.1,340.8,32.6z"/>
                    <path className={styling}
                          d="M297.7,23.2c18.3,9.6,30.9,24.1,38,43.5C317.5,57.1,304.8,42.5,297.7,23.2z"/>
                    <path className={styling}
                          d="M102.7,23.2c-7.1,19.4-19.7,33.9-38,43.5C71.8,47.3,84.4,32.8,102.7,23.2z"/>
                    <path className={styling}
                          d="M337.3,264.9c10.3-17.9,25.3-30,44.9-36.4C371.9,246.4,356.9,258.6,337.3,264.9z"/>
                    <path className={styling}
                          d="M42.4,87.9c-2.9-15.3,6.4-45,17.4-55.5c0.6,10.1-0.4,19.9-3.4,29.4C53.3,71.3,48.6,79.9,42.4,87.9z"/>
                    <path className={styling}
                          d="M8.1,176.1c16.4,12.6,26.4,29.1,30,49.4c-7.9-6-14.6-13.2-19.8-21.8C13.1,195.2,9.8,186,8.1,176.1z"/>
                    <path className={styling}
                          d="M63.1,264.9c-19.6-6.4-34.6-18.5-44.9-36.4C37.9,234.9,52.8,247,63.1,264.9z"/>
                    <path className={styling} d="M215.6,220.9c0,4.6,0,9.1,0,13.5c0,0.3-0.5,0.7-0.9,0.9c-8.6,3.3-17.1,6.6-25.7,9.8c-1.3,0.5-2.5,1-3.9,1.5
	c0-0.4-0.1-0.8-0.1-1.1c0-4,0-8.1,0-12.1c0-0.6,0.1-1,0.8-1.2c9.8-3.7,19.5-7.4,29.3-11.1C215.2,221,215.3,221,215.6,220.9z"/>
                    <path className={styling}
                          d="M226.1,76.4c0,2.7,0,5.4,0,8.2c-17.3,0-34.5,0-51.7,0c0-2.7,0-5.4,0-8.2C191.5,76.4,208.7,76.4,226.1,76.4z"/>
                    <path className={styling} d="M185,225.4c0-0.5-0.1-0.8-0.1-1.2c0-3.9,0-7.8,0-11.6c0-0.6,0-1,0.7-1.3c9.8-3.7,19.6-7.5,29.4-11.2c0.1,0,0.2,0,0.5,0
	c0,0.8,0,1.6,0,2.4c0,3.5,0,7,0,10.4c0,0.6-0.1,1-0.7,1.3c-9.8,3.7-19.5,7.4-29.3,11.1C185.4,225.3,185.3,225.3,185,225.4z"/>
                    <path className={styling} d="M156.9,21.8c0.2,5,0.4,9.8,0.7,14.9c2.9-1.6,5.6-3.1,8.5-4.7c-0.4,8.8-0.8,17.4-1.2,26c-0.1,0-0.2,0.1-0.2,0.1
	c-1.9-4.5-3.7-9.1-5.7-13.8c-2.6,1.4-5.1,2.8-7.7,4.3c-0.3-1-0.6-1.9-0.9-2.9c-1.7-5.5-3.3-10.9-5-16.4c-0.2-0.8-0.1-1.2,0.6-1.6
	c3.3-1.8,6.6-3.6,9.9-5.4C156.2,22.2,156.5,22.1,156.9,21.8z"/>
                    <path className={styling} d="M237.3,57.3c1.9-4.5,3.8-9,5.7-13.7c-2.8-0.8-5.5-1.6-8.4-2.4c0.2-0.4,0.4-0.8,0.6-1.1c2.9-5.5,5.9-11,8.8-16.6
	c0.4-0.8,0.8-0.9,1.7-0.7c3.9,1.1,7.7,2.2,11.9,3.4c-3.4,3.7-6.7,7.3-10.1,11.1c3.1,0.9,6.1,1.8,9.3,2.7
	c-6.5,5.9-12.8,11.7-19.2,17.5C237.5,57.4,237.4,57.4,237.3,57.3z"/>
            </svg>

        )
}

export default PowerBadge;