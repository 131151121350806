import React from 'react';

export const Magnesium = (props)  => {
    return (
        
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path
                    d="M53.2,56.7L53.2,56.7c0,0.3,0,0.7,0,1.1v-6.9c0,0.2,0,0.4,0,0.5v0.1c0,0,0,0,0,0v0.4C53.2,51.9,53.2,53.8,53.2,56.7z"/>
                <g>
                    <path d="M54.7,51.9L54.7,51.9c0-0.1,0-0.3,0-0.4v-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4v6.9c0-0.4,0-0.7,0-1.1
				c0,0,0,0,0,0C54.7,53.9,54.7,52.1,54.7,51.9z"/>
                    <path
                        d="M43.3,64.3c-0.1-0.1-0.1-0.2-0.2-0.4c0,0,0.1,0,0.1,0.1C43.2,64.1,43.3,64.2,43.3,64.3z"/>
                    <path
                        d="M43.3,64.3c-0.1-0.1-0.1-0.2-0.2-0.4c0,0,0.1,0,0.1,0.1C43.2,64.1,43.3,64.2,43.3,64.3z"/>
                </g>
                <path d="M53.2,51.9v-0.4c0,0,0,0,0,0C53.2,51.6,53.2,51.8,53.2,51.9c0,0.1,0,0.8,0,2c0,0.8,0,1.7,0,2.8
			C53.2,53.8,53.2,51.9,53.2,51.9z M54.7,50.9v2.9c0-1.2,0-2,0-2v-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
			C54.7,51.2,54.7,51.1,54.7,50.9z M54.7,53.9v3.9c0-0.4,0-0.7,0-1.1c0,0,0,0,0,0C54.7,55.6,54.7,54.6,54.7,53.9z"/>
                <path d="M64.8,63.9c-0.1,0.1-0.1,0.3-0.2,0.4c0-0.1,0-0.2,0.1-0.3C64.7,64,64.8,63.9,64.8,63.9z"/>
                <path d="M64.8,63.9c-0.1,0.1-0.1,0.3-0.2,0.4c0-0.1,0-0.2,0.1-0.3C64.7,64,64.8,63.9,64.8,63.9z"/>
                <path d="M82.3,35.2l-0.3-0.6l-0.6,0.2c-0.1,0-7.8,1.9-12.4,2.7c-0.1-0.4-0.2-1-0.6-1.9c-0.7-1.6-1.7-3.3-3-5.1
			c-3.3-4.3-10.7-9.6-11-9.8L54,20.4l-0.4,0.3c-0.1,0-6.2,4.6-10,7.8c-2.6,2.1-4,5.3-4.8,7.6c-0.2,0.5-0.3,1-0.4,1.3
			c-4.7-0.8-11.8-2.6-11.9-2.6l-0.6-0.2l-0.3,0.6c-0.2,0.3-3.5,8.1-3.3,16.9c0.2,9.2,2,13.4,7.5,16.6c4,2.3,9,2.7,12.7,3
			c1.5,0.1,2.8,0.2,3.6,0.4c0.2,0,0.3,0.1,0.5,0.1c1.7,7.8,1.8,14.6,1.8,14.6c0,0.4,0.3,0.7,0.8,0.7c0,0,0,0,0,0
			c0.4,0,0.7-0.3,0.7-0.8c0-0.1-0.1-7.1-1.9-15.1c0,0,0,0,0-0.1c0.5-0.5,0.7-1.4,0.9-2.1c0.2-0.5,0.3-1,0.5-1.5
			c0,0.1,0.1,0.1,0.2,0.2l3.7,2.9v9.9c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-10l3.9-3c0.2,0.5,0.4,1,0.5,1.5
			c0.3,0.8,0.5,1.6,0.9,2.1c0,0.1,0,0.1,0,0.2c-1.8,8.1-1.9,15.1-1.9,15.1c0,0.4,0.3,0.8,0.7,0.8c0,0,0,0,0,0c0.4,0,0.7-0.3,0.8-0.7
			c0-0.1,0.1-6.9,1.9-14.8c0.2,0,0.3-0.1,0.5-0.1c0.7-0.2,2-0.3,3.6-0.4c3.7-0.2,8.7-0.5,12.5-2.8c5.5-3.2,7.4-7.4,7.5-16.6
			C85.8,43.3,82.5,35.5,82.3,35.2z M53.2,69l-2.8-2.2c-0.1-0.1-0.3-0.2-0.5-0.2c0.1-0.1,0.2-0.3,0.3-0.4c0.5-0.5,1-0.9,1.4-1.3
			c0.5-0.5,1-0.9,1.5-1.4V69z M53.2,56.7L53.2,56.7c0,0.3,0,0.7,0,1.1c0,0.4,0,0.7,0,1.1c-0.1,2.9-1,3.7-2.4,4.9
			c-0.5,0.4-1,0.9-1.5,1.4c-1.1,1.1-1.5,2.5-1.8,3.7c0,0.2-0.1,0.3-0.1,0.5c-0.4-1-0.8-2.1-1.4-3.2c0-0.1-0.1-0.1-0.1-0.2
			c0,0,0,0,0,0c-0.3-0.7-0.7-1.4-1.1-2.2c0,0,0,0,0,0c-0.2-0.8-1.1-4.2-0.5-7.9c0.5-2.6,1.2-4.1,1.2-4.1c0.2-0.4,0-0.8-0.3-1
			c-0.4-0.2-0.8,0-1,0.3c0,0.1-0.9,1.6-1.3,4.5c-0.3,1.5-0.3,2.9-0.2,4.1c-1.3-2.4-2.8-4.8-4.2-7c-0.1-1.5-0.1-3,0.2-4.3
			c0.4-2.6,1.2-4.1,1.2-4.1c0.2-0.4,0.1-0.8-0.3-1c-0.4-0.2-0.8,0-1,0.3c0,0.1-0.9,1.6-1.4,4.5c-0.1,0.7-0.2,1.4-0.2,2.1
			c-2.6-4.1-4.7-7.1-4.9-7.5c-0.2-0.3-0.7-0.4-1-0.2c-0.3,0.2-0.4,0.7-0.2,1c0.1,0.1,3,4.3,6.3,9.7c0.1,0.1,0.1,0.2,0.2,0.3
			c0,0,0,0,0,0c0.4,0.6,0.7,1.2,1.1,1.8c-1.3-0.8-2.9-1.6-4.7-2.1c-2.8-0.8-4.6-0.7-4.7-0.7c-0.4,0-0.7,0.4-0.7,0.8
			c0,0.4,0.4,0.7,0.8,0.7c0,0,1.6-0.1,4.2,0.6c3.7,1,6.5,3.5,7,3.9c1,1.8,2,3.5,2.9,5.2c0,0,0.1,0,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3
			c-0.1-0.1-0.1-0.2-0.2-0.4c-1.3-0.8-3.1-1.7-5.2-2.3c-2.8-0.8-4.6-0.7-4.7-0.7c-0.4,0-0.7,0.4-0.7,0.8c0,0.4,0.4,0.7,0.8,0.7
			c0,0,1.6-0.1,4.2,0.6c3.6,1,6.4,3.2,7,3.7c0.6,1.3,1.2,2.6,1.5,3.7c-0.9-0.2-2.1-0.3-3.5-0.4c-3.5-0.3-8.4-0.6-12.1-2.8
			c-5-2.9-6.6-6.6-6.8-15.3c-0.1-7.1,2.2-13.7,3-15.7c2.1,0.5,8.9,2.1,12.8,2.7c4.3,0.6,6.7,2,6.8,2c0.1,0,6.3,3.1,6.9,9.8
			c0,0.2,0,0.4,0,0.5v0.1c0,0,0,0,0,0v0.4C53.2,51.9,53.2,53.8,53.2,56.7z M53.9,31.7c-0.4,0-0.8,0.3-0.8,0.8v7.1
			c-1.7-3.3-4.4-5.7-4.5-5.8c-0.3-0.3-0.8-0.3-1.1,0.1c-0.3,0.3-0.3,0.8,0.1,1.1c0,0,5.1,4.6,5.5,9.6v1.4c-2.2-4-6-5.9-6.2-6
			c-0.1-0.1-2.6-1.5-7.2-2.2c0.1-0.3,0.2-0.6,0.3-1.1c0.6-1.7,2-4.9,4.4-6.9c3.2-2.7,8.1-6.3,9.5-7.4c1.7,1.2,7.6,5.6,10.3,9.2
			c1.6,2.1,2.4,3.8,2.8,4.8c0.3,0.7,0.4,1.3,0.5,1.5c-4.3,0.7-6.6,2-6.6,2c-0.2,0.1-4,1.9-6.2,5.9c0.9-4.7,5.4-8.8,5.4-8.8
			c0.3-0.3,0.3-0.8,0.1-1.1c-0.3-0.3-0.8-0.3-1.1-0.1c-0.2,0.2-2.8,2.6-4.6,5.9v-9.2C54.7,32,54.3,31.7,53.9,31.7z M54.7,68.9v-5.5
			c0.4,0.6,1,1,1.5,1.5c0.5,0.4,0.9,0.8,1.4,1.3c0.1,0.1,0.2,0.2,0.2,0.3L54.7,68.9z M77.4,67.4c-3.5,2-8.3,2.3-11.9,2.6
			c-1.5,0.1-2.8,0.2-3.6,0.4c0.4-1,0.9-2.2,1.4-3.5c0.6-0.5,3.4-2.8,7-3.7c2.6-0.7,4.2-0.6,4.2-0.6c0.4,0,0.8-0.3,0.8-0.7
			c0-0.4-0.3-0.8-0.7-0.8c-0.1,0-1.8-0.1-4.7,0.7c-2,0.6-3.8,1.5-5.2,2.3c-0.1,0.1-0.1,0.3-0.2,0.4c0-0.1,0-0.2,0.1-0.3
			c0,0,0.1,0,0.1-0.1c0.9-1.7,1.9-3.5,2.9-5.2c0,0,0,0,0,0c0.5-0.5,3.4-2.9,7-3.9c2.6-0.7,4.2-0.6,4.2-0.6c0.4,0,0.8-0.3,0.8-0.7
			c0-0.4-0.3-0.8-0.7-0.8c-0.1,0-1.8-0.1-4.7,0.7c-1.8,0.5-3.4,1.3-4.7,2.1c0.4-0.6,0.7-1.2,1.1-1.8c0,0,0,0,0,0
			c0-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c3.3-5.3,6.2-9.6,6.3-9.7c0.2-0.3,0.2-0.8-0.2-1c-0.3-0.2-0.8-0.1-1,0.2
			c-0.2,0.3-2.3,3.4-4.9,7.4c0-0.7-0.1-1.4-0.2-2.1c-0.5-2.9-1.3-4.5-1.3-4.5c-0.2-0.4-0.7-0.5-1-0.3c-0.4,0.2-0.5,0.6-0.3,1
			c0,0,0.8,1.5,1.2,4.1c0.2,1.3,0.3,2.8,0.1,4.3c-1.4,2.2-2.8,4.6-4.2,7c0.1-1.3,0-2.7-0.2-4.1c-0.5-2.9-1.3-4.5-1.4-4.5
			c-0.2-0.4-0.6-0.5-1-0.3c-0.4,0.2-0.5,0.6-0.3,1c0,0,0.7,1.4,1.2,4.1c0.6,3.7-0.3,7.1-0.5,7.9c0,0,0,0,0,0
			c-0.4,0.7-0.7,1.5-1.1,2.2c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c-0.5,1.1-1,2.2-1.3,3.2c0-0.2-0.1-0.3-0.2-0.5
			c-0.4-1.1-0.8-2.5-1.8-3.6c-0.5-0.6-1.1-1-1.5-1.4c-1.4-1.2-2.3-2-2.4-4.9c0-0.8,0-1.5-0.1-2.2c0,0.3,0,0.7,0,1.1v-6.9
			c0,0.2,0,0.3,0,0.4c0.3-7,6.9-10.2,7-10.3c0,0,2.4-1.4,6.8-2c3.9-0.6,10.7-2.2,12.8-2.7c0.8,2,3.1,8.5,3,15.7
			C84,60.7,82.4,64.4,77.4,67.4z"/>
            </g>
        </svg>
    
    );
};