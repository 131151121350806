import React from 'react';

export const Preventive = (props) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M81.7,57.6c-1.6-1.7-4.3-1.8-6-0.3l-10.3,9.1c-1-1.4-2.9-2.2-4.6-1.7L59.9,65c-7,2.1-10.8-0.6-10.9-0.7
	c-2.4-2-5.6-2.9-8.7-2.5c-2.8,0.3-5.4,1.2-7.7,2.8l-7.5,5c-1,0.7-1.4,2-0.9,3.2c1.9,4.3,4.6,8.1,7.9,11.5c0.9,1,2.4,1.1,3.5,0.3
	l1.4-1.1c1.9-1.4,4.3-1.9,6.5-1.2l9.7,2.8c0.8,0.2,1.7,0.4,2.6,0.4c2.4,0,4.7-0.9,6.4-2.6l0.1-0.1c0.1,0,0.2-0.1,0.3-0.2l10.7-10.7
	c0.1,0,0.1-0.1,0.2-0.1l8.1-8.1C83.2,62,83.3,59.3,81.7,57.6z M80.4,62.4L73,69.7c-0.1,0-0.1,0.1-0.2,0.1L61.3,81.4h0
	c-0.1,0.1-0.2,0.1-0.3,0.2c-2,1.9-4.8,2.6-7.4,1.8L44,80.6c-2.8-0.8-5.7-0.2-8,1.5l-1.4,1.1c-0.4,0.3-0.9,0.2-1.2-0.1
	c-3.1-3.2-5.7-6.9-7.5-11c-0.2-0.4-0.1-0.9,0.3-1.1l7.5-5c2.1-1.4,4.4-2.3,6.9-2.5c2.7-0.4,5.4,0.4,7.5,2.2c0.2,0.1,4.5,3.3,12.4,1
	l0.8-0.2c1.3-0.3,2.6,0.3,3.1,1.5c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1c0.1,0.2,0.1,0.5,0.1,0.7
	c0,1.3-0.9,2.4-2.1,2.7c-4.2,1-11.6,2.5-15.2,1.5c-0.4-0.1-0.9,0.1-1,0.6c-0.1,0.5,0.1,0.9,0.6,1c3.7,1,10.7-0.2,16-1.5
	c2-0.5,3.4-2.3,3.4-4.3c0-0.3,0-0.7-0.1-1l10.7-9.5c1-0.9,2.7-0.9,3.6,0.2C81.4,59.8,81.4,61.4,80.4,62.4z"/>
                <g>
                    <path d="M56.5,60c-10.5,0-19-8.5-19-19c0-10.5,8.5-19,19-19s19,8.5,19,19C75.4,51.5,66.9,60,56.5,60z M56.5,23.6
		C46.8,23.6,39,31.4,39,41c0,9.6,7.8,17.4,17.4,17.4S73.9,50.6,73.9,41C73.9,31.4,66.1,23.6,56.5,23.6z"/>
                    <path d="M59.6,52.1h-6.3c-0.9,0-1.6-0.7-1.6-1.6v-4.8H47c-0.9,0-1.6-0.7-1.6-1.6v-6.3c0-0.9,0.7-1.6,1.6-1.6h4.8v-4.8
		c0-0.9,0.7-1.6,1.6-1.6h6.3c0.9,0,1.6,0.7,1.6,1.6v4.8H66c0.9,0,1.6,0.7,1.6,1.6v6.3c0,0.9-0.7,1.6-1.6,1.6h-4.8v4.8
		C61.2,51.4,60.5,52.1,59.6,52.1z M47,37.8l0,6.4l6.3,0v6.3l6.4,0l0-6.3H66l0-6.4l-6.3,0l0-6.3h-6.3l0,6.3H47z"/>
                </g>
            </g>
        </svg>
    );
};