import React from 'react';
import Navigation from "../Navigation/Navigation";
import {Link,NavLink} from "react-router-dom";
import {useStore} from "../../contexts/StateContext";
import text from "../../assets/strings"
import {Zinc} from "../../assets/icons/Zinc";
import {Nutrition} from "../../assets/icons/Nutrition";
import {Preventive} from "../../assets/icons/Preventive";
import {Athletic} from "../../assets/icons/Athletic";
import config from "../../config/config"

function NavBar() {
    const [{lang,hash, company}] = useStore();
    const {plan} = text[lang];
    return (
        <div className="header-container">
            <header className="large">
                <div className="header-left">
                    <div className="logo-container">
                        <Link to={ `/webapp/${hash}/plan/athletic` }>
                            <img src={`${process.env.PUBLIC_URL}/img/${company}.png`} alt="logo" className="logo"/>
                        </Link>
                    </div>
                </div>
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={`/webapp/${hash}/plan/athletic`} activeClassName="active">
                            <div className="image-wrapper">
                                <Athletic />
                                <span>{plan.areas.athletic}</span>
                            </div>

                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`/webapp/${hash}/plan/preventive`} activeClassName="active">
                            <div className="image-wrapper">
                                <Preventive/>
                                <span>{plan.areas.preventive}</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`/webapp/${hash}/plan/nutrition`} activeClassName="active">
                            <div className="image-wrapper">
                                <Nutrition/>
                                <span>{plan.areas.nutrition}</span>
                            </div>
                        </NavLink>
                    </li>
                </ul>
                <div className='settings'>
                    <ul>
                        <li className="settings__menu"><Navigation hash={hash} /></li>
                    </ul>
                </div>
            </header>
            <header className="small">
                <ul className="nav">
                    <li className="nav-item">
                        <NavLink to={`/webapp/${hash}/plan/athletic`} activeClassName="active">
                            <div className="image-wrapper">
                                <Athletic/>
                            </div>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`/webapp/${hash}/plan/preventive`} activeClassName="active">
                            <div className="image-wrapper">
                                <Preventive/>
                            </div>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to={`/webapp/${hash}/plan/nutrition`} activeClassName="active">
                            <div className="image-wrapper">
                                <Nutrition/>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </header>
        </div>
    )
}

export default NavBar;