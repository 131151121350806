import React from 'react'

function EnduranceBadge(props) {

        let styling = props.badge ? 'active-badge' : 'inactive';
        const showSvg = props.badge ? 'initial':'none';

        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" style={{display: showSvg}}
                 viewBox="0 0 400.6 323.7" xmlSpace="preserve">
                    <path className={styling} d="M205.9,211.4c0.9,2,1.8,3.9,2.7,5.9c1.5-0.7,2.9-1.3,4.3-2c3.1-1.4,6.2-2.9,9.3-4.3c0.4-0.2,1-0.2,1.3-0.1
	c4,2.1,8,4.3,12,6.5c5.1,2.8,9.9,6,14.1,10c2.3,2.3,4.4,4.7,5.6,7.8c0.1,0.3,0.2,0.7,0.3,1.1c-0.9,0.1-1.7,0.1-2.6,0.1
	c-6.9,0-13.8,0.1-20.7-0.2c-11.9-0.6-23.2-3.7-34-8.8c-13.7-6.5-27.5-13.1-41.2-19.6c-0.2-0.1-0.4-0.2-0.7-0.4
	c3.6-8.2,7.1-16.3,10.8-24.5c2.5,0.9,4.9,1.7,7.4,2.6c3.7,1.3,7.4,2.6,11.1,3.9c0.6,0.2,1.3,0.2,1.9,0c6.2-2.1,12.4-4.2,18.6-6.4
	c0.2-0.1,0.4-0.1,0.7-0.2c1.3,4.1,2.6,8.1,3.9,12.3c-5,2.2-10,4.3-15,6.5c0.9,2,1.7,3.9,2.6,6c5.2-2.2,10.3-4.4,15.4-6.6
	c1.3,1.6,2.5,3.2,3.8,4.9C213.7,207.7,209.8,209.5,205.9,211.4z"/>
                    <path className={styling} d="M188.4,125.8c-3-1.6-5.9-3.1-8.8-4.6c5.7-10.9,11.3-21.6,17-32.5c-14.1-8.2-28.1-16.4-42.3-24.7c21-10.7,41.8-21.3,62.6-32
	c1.7,3.2,3.3,6.3,4.9,9.4c2.8,5.5,5.7,10.9,8.4,16.4c0.4,0.9,1,1.1,1.9,1.1c10,0,20,0,30,0c0.5,0,0.9,0,1.5,0c0,3.4,0,6.6,0,10
	c-0.5,0-0.9,0-1.3,0c-12.1,0-24.2,0-36.3,0c-1,0-1.4-0.3-1.8-1.1c-3.6-7.1-7.3-14.3-11-21.4c-0.1-0.3-0.3-0.6-0.5-1
	c-12.4,6.3-24.9,12.7-37.5,19.1c11.7,6.8,23.2,13.6,34.7,20.3C202.7,98.5,195.6,112.1,188.4,125.8z"/>
                    <path className={styling} d="M297.2,151.4c-4.3,0.1-8.4-0.6-12.4-2.2c-2.4-0.9-4.7-1.9-7-3.1c-8.8-4.5-17.6-4.2-26.3,0.3c-3.8,1.9-7.7,3.6-11.9,4.4
	c-7.3,1.3-14.4,0.7-21.2-2.3c-2.8-1.2-5.6-2.6-8.5-3.8c-6.6-2.7-13.2-2.5-19.7,0.2c-2.9,1.2-5.8,2.7-8.8,3.9
	c-9.5,3.7-18.9,3.5-28.2-0.5c-2.1-0.9-4.2-1.9-6.3-2.9c-7.5-3.4-15-3.4-22.5,0c-2.9,1.3-5.7,2.6-8.6,3.8c-3.4,1.4-7,2.1-10.6,2.2
	c-0.6,0-1.1,0-1.7,0c0-3.3,0-6.6,0-9.8c1.5-0.2,3-0.2,4.5-0.5c3.5-0.6,6.8-2.1,10-3.7c5.7-2.8,11.7-4.6,18.2-4.5
	c6.2,0.1,11.9,1.9,17.5,4.5c3.4,1.7,6.9,3.3,10.7,3.8c5.5,0.8,10.7-0.2,15.7-2.5c3.7-1.7,7.4-3.5,11.3-4.6
	c8.1-2.3,16.1-1.5,23.9,1.9c2,0.9,4.1,1.9,6.1,2.8c7.6,3.5,15.3,3.5,22.9,0c3.4-1.5,6.7-3.2,10.2-4.3c8.9-2.8,17.6-2.1,26,1.8
	c1.5,0.7,2.9,1.3,4.3,2c3.9,1.9,8,3.2,12.5,3.1C297.2,144.8,297.2,148.1,297.2,151.4z"/>
                    <path className={styling} d="M297.2,169.4c-2.9,0.1-5.8-0.1-8.5-1c-3.5-1.2-6.9-2.5-10.2-4c-4.9-2.3-9.8-3.8-15.2-3.5c-4.2,0.3-8,1.7-11.7,3.5
	c-4.3,2-8.6,4-13.3,4.6c-7.6,1.1-14.8,0-21.7-3.3c-3.2-1.5-6.5-3.1-10-4.1c-6.4-1.8-12.5-0.6-18.5,2.1c-3.9,1.8-7.8,3.6-11.9,4.6
	c-7.8,2-15.5,1.2-22.9-2.2c-1.9-0.9-3.9-1.7-5.8-2.7c-7.9-3.8-15.8-3.7-23.7,0c-3.6,1.7-7.3,3.2-11.1,4.5c-2.9,1-6.1,1.3-9.3,1.2
	c0-3.3,0-6.5,0-9.8c1.5-0.2,3.1-0.3,4.6-0.5c3.5-0.6,6.6-2.1,9.7-3.6c5.6-2.7,11.4-4.6,17.8-4.6c6.4,0,12.3,1.8,18,4.6
	c3.4,1.6,6.8,3.2,10.6,3.7c5.7,0.8,11.1-0.4,16.3-2.8c3.8-1.8,7.7-3.6,11.8-4.6c8.7-2.2,17-0.7,25,3.1c3.3,1.6,6.6,3,10.1,3.9
	c5.7,1.4,11.2,0.4,16.6-2c3.9-1.8,7.8-3.7,12-4.8c8.5-2.4,16.8-1.3,24.9,2.4c3.4,1.6,6.9,3,10.5,4.2c1.8,0.6,3.8,0.6,5.9,0.9
	C297.2,162.8,297.2,166.1,297.2,169.4z"/>
                    <path className={styling} d="M174.3,123.3c-3-1.4-5.9-2.8-8.9-4.3c3.2-7.3,3.4-14.6,0-21.8c-2.3-4.8-5.8-8.5-10.4-11.1c-9.2-5.2-20.7-3.9-28.6,3
	c-7.9,6.9-11.3,18.7-6.2,29.9c-3,1.4-5.9,2.9-8.9,4.3c-6.2-11.9-4.9-30,8.6-41.8c12.7-11.1,31.9-11.4,45.1-0.6
	C178.8,92.2,180.7,110.5,174.3,123.3z"/>
                    <path className={styling} d="M277.7,123.3c-3-1.4-5.9-2.8-8.7-4.2c4.8-13.5,2.1-24.7-10.1-32.6c-8.7-5.6-20.1-4.5-28.4,2c-6.8,5.4-12.9,17-7,30.5
	c-2.9,1.4-5.9,2.8-8.8,4.2c-5.8-10.8-5.4-28.7,7.6-40.9c12.4-11.7,31.9-12.6,45.5-1.9C281.9,91.5,284.4,109.9,277.7,123.3z"/>
                    <path className={styling} d="M199.1,11.8c9.9-1.6,19.4-3.5,29.1-3.7c5.2-0.1,10.5-0.1,15.6,1.2c7.9,1.9,13.4,8.7,13.6,16.8c0.2,8.1-4.9,15.2-12.4,17.4
	c-7.5,2.2-15.1-0.9-18.9-7.7c-0.8-1.4-1.5-2.8-2-4.3c-1.2-3.2-3.1-5.7-5.9-7.6c-5-3.4-10.2-6.5-15.4-9.8
	C201.7,13.4,200.5,12.7,199.1,11.8z M227.1,18.3c3.6,3.1,5.3,7.3,7,11.4c0.1,0.3,0.3,0.6,0.5,1c2,3.5,5.9,4.6,9.3,2.3
	c2.8-1.9,3.8-4.7,3.3-7.9c-0.6-3.3-2.7-5.4-5.9-6.2C236.7,17.8,232,18,227.1,18.3z"/>
                    <path className={styling} d="M149.9,222.4c1.3-3,2.6-5.9,3.9-8.9c2.7,1.3,5.4,2.6,8,3.8c12.4,5.9,24.7,11.9,37.2,17.6c11.9,5.4,24.6,8,37.7,8.1
	c5.6,0.1,11.3,0,16.9,0c0.4,0,0.8,0,1.3,0c-0.8,2.8-1.6,5.5-2.4,8.3c-0.1,0.2-0.4,0.4-0.6,0.4c-19.1,1.9-37.7,0.4-55.4-7.6
	c-13.5-6.1-26.9-12.4-40.3-18.7C154.1,224.4,152.1,223.4,149.9,222.4z"/>
                    <path className={styling}
                          d="M330.6,284.3c-17.3,11.2-36.1,15.3-56.5,12c8.3-5.5,17.3-9.4,27-11.5C310.9,282.7,320.8,282.6,330.6,284.3z"/>
                    <path className={styling}
                          d="M164.3,307.8c-18.7,8.7-37.8,10.1-57.6,4.2c2.8-2.7,19.9-7.3,28.4-7.8C145,303.7,154.7,304.8,164.3,307.8z"/>
                    <path className={styling}
                          d="M236.3,307.8c19.7-6,38.9-4.7,57.6,4.1c-9.5,3-19.2,4.3-29.1,3.6C254.8,314.7,245.3,312.1,236.3,307.8z"/>
                    <path className={styling}
                          d="M29.8,108.5c-7.6-19.3-8-38.6-1-58c2.6,3.5,6.1,20.3,6.2,28.9C35.3,90.8,32,104.3,29.8,108.5z"/>
                    <path className={styling} d="M70,284.2c11.1-1.8,22.1-1.4,33,1.4c9.6,2.5,20.8,8.3,23.6,10.8c-10.7,1.6-21.3,1.4-31.7-1.2C86,293.1,77.7,289.4,70,284.2z
	"/>
                    <path className={styling} d="M50.2,219.7c-2.9-9.6-4-19.3-3.1-29.2c0.9-9.9,3.6-19.4,8.1-28.4c2.7,9,3.8,18.1,3.2,27.4C57.8,200.1,54.9,210.2,50.2,219.7
	z"/>
                    <path className={styling}
                          d="M374.1,156.4c-2.9-20.4,1.5-39.2,13-56.3c1.5,9.9,1.2,19.7-1.1,29.4C383.8,139.2,379.7,148.1,374.1,156.4z"/>
                    <path className={styling}
                          d="M336.3,204.3c3.4,14.9-4.9,44.3-15.8,56c-0.9-10.1-0.1-19.9,2.6-29.5C325.8,221.2,330.3,212.5,336.3,204.3z"/>
                    <path className={styling} d="M311.1,47c19.4,7,33.9,19.7,43.6,37.9C335.3,77.9,320.8,65.2,311.1,47z"/>
                    <path className={styling} d="M89.5,47c-9.7,18.2-24.2,30.9-43.6,37.9C55.6,66.6,70.2,54,89.5,47z"/>
                    <path className={styling}
                          d="M364.6,177.3c-11.5-10.1-21.9-38.6-20-54.7c6.7,7.7,11.8,16.1,15.2,25.4C363.3,157.5,364.8,167.2,364.6,177.3z"/>
                    <path className={styling}
                          d="M32.5,135.7c3.5-20.3,13.4-36.8,29.6-49.6c-1.6,9.8-4.9,19.1-10,27.7C47,122.4,40.4,129.6,32.5,135.7z"/>
                    <path className={styling}
                          d="M371.7,50.5c7.1,19.4,6.7,38.7-1,57.8C363.6,88.9,363.9,69.7,371.7,50.5z"/>
                    <path className={styling}
                          d="M13.5,100.1c11.6,17.1,15.9,35.8,13.1,56.3c-5.7-8.2-9.7-17.1-12-26.9C12.3,119.8,12,110,13.5,100.1z"/>
                    <path className={styling}
                          d="M34.3,257.4c20.5-2.3,39.1,2.6,55.9,14.6c-9.9,1.2-19.7,0.6-29.3-1.9C51.2,267.6,42.3,263.3,34.3,257.4z"/>
                    <path className={styling} d="M42.5,70.9c-1.3-20.6,4.4-39,17.2-55.1C61,36.3,55.3,54.7,42.5,70.9z"/>
                    <path className={styling}
                          d="M338.4,86c12.9,7.4,29.3,34.7,29.8,49.9c-8-6.3-14.5-13.6-19.7-22.1C343.4,105.3,340.2,96,338.4,86z"/>
                    <path className={styling}
                          d="M366.3,257.3c-16.7,12-35.4,16.9-55.9,14.6c8-5.9,16.8-10.2,26.5-12.7C346.6,256.7,356.4,256.1,366.3,257.3z"/>
                    <path className={styling} d="M148,299.4c-7.8-5.1-14.6-11.3-20.1-18.8c-6.4-8.5-10.6-18-13.2-28.3c8.4,5.5,15.5,12.2,21.3,20.4
	C141.7,280.7,145.7,289.7,148,299.4z"/>
                    <path className={styling}
                          d="M88.4,229.2c14.1,15.1,21.2,33,21.6,53.6C95.9,267.7,88.7,249.9,88.4,229.2z"/>
                    <path className={styling}
                          d="M337.4,248.2c10.3-17.9,25.2-30,44.9-36.4C372,229.7,357,241.8,337.4,248.2z"/>
                    <path className={styling}
                          d="M312.2,229.2c-0.3,20.6-7.5,38.4-21.6,53.5C291,262.2,298.2,244.3,312.2,229.2z"/>
                    <path className={styling}
                          d="M345.4,162.1c9,18.5,10.7,37.7,5,57.6c-4.4-8.9-7.2-18.3-8-28.3C341.5,181.5,342.6,171.7,345.4,162.1z"/>
                    <path className={styling}
                          d="M362.2,208.9c3.8-20.3,13.8-36.8,30.1-49.6c0.6,3.7-5.4,19.9-10.1,27.7C376.6,196.4,367,205.9,362.2,208.9z"/>
                    <path className={styling}
                          d="M38.2,208.7c-16.3-12.6-26.4-29-30-49.3C24.5,171.9,34.5,188.4,38.2,208.7z"/>
                    <path className={styling}
                          d="M335.8,49.9c-18.2-9.6-30.9-24.1-38-43.5c8.9,4.6,16.7,10.6,23.2,18.1C327.6,32,332.5,40.5,335.8,49.9z"/>
                    <path className={styling}
                          d="M18.3,211.8c19.6,6.4,34.6,18.5,45.2,36.6c-9.8-3.2-18.5-7.8-26.2-14C29.5,228.1,23.3,220.5,18.3,211.8z"/>
                    <path className={styling}
                          d="M285.9,252.3c-5.1,20-16.2,35.7-33.3,47.1C257.7,279.4,268.8,263.7,285.9,252.3z"/>
                    <path className={styling}
                          d="M358.1,70.8c-12.8-16.2-18.5-34.5-17.2-55c6.3,7.8,11,16.3,13.9,25.9C357.9,51.2,358.8,60.9,358.1,70.8z"/>
                    <path className={styling}
                          d="M36.1,177.2c-0.4-20.6,6.2-38.7,19.8-54.2c0.3,10-1.2,19.7-4.6,29C47.8,161.3,42.7,169.7,36.1,177.2z"/>
                    <path className={styling}
                          d="M64.3,204.6c12.4,16.5,17.6,35,15.7,55.6C67.6,243.6,62.4,225.1,64.3,204.6z"/>
                    <path className={styling}
                          d="M102.8,6.4C95.7,25.8,83,40.3,64.8,50c3.3-9.4,8.2-17.9,14.7-25.4C86.1,17,93.9,11,102.8,6.4z"/>
                    <path className={styling}
                          d="M147.4,187.4c0,2.7,0,5.4,0,8.2c-11.6,0-23.2,0-34.8,0c0-2.7,0-5.4,0-8.2C124.2,187.4,135.7,187.4,147.4,187.4z"/>
                    <path className={styling}
                          d="M141.3,200.8c0,2.7,0,5.4,0,8.2c-6.3,0-12.5,0-18.7,0c0-2.7,0-5.4,0-8.2C128.8,200.8,135,200.8,141.3,200.8z"/>
            </svg>

        )
}

export default EnduranceBadge;