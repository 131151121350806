import React from 'react';
import Areas from '../../../assets/areas';
import Text from '../../../assets/strings';
import {useStore} from "../../../contexts/StateContext";

/**
 * @return {null}
 */
function LegacyData(props) {
    let [{lang}] = useStore();

    const recommendationTitle = Text[lang].web.areas["recommendation-title"];
    const advancedTitle = Text[lang].web.areas["advanced-level"];
    const intermediateTitle = Text[lang].web.areas["intermediate-level"];
    const beginnerTitle = Text[lang].web.areas["beginner-level"];
    const recommendationFooter = Text[lang].web.areas["recommendation-footer"];

    let recommendation = Areas[lang][props.location.state.name][props.location.state.group];
    let recommendations = null;
    if (typeof recommendation === 'string' && props.location.state.name !== 'refuel') {
        recommendations = (
            <div>
                <h4>{recommendationTitle}</h4>

                <p>{recommendation}</p>

                <small><i>{recommendationFooter}</i></small>
            </div>
        )
    }else {
        if( props.location.state.name !== 'refuel') {
            recommendations = (
                <div>
                    <h4>{recommendationTitle}</h4>

                    {recommendation.hasOwnProperty("extra") ? <p>{recommendation.extra}</p> : null}

                    <h5>{advancedTitle}</h5>
                    <p>{recommendation.advanced}</p>

                    <h5>{intermediateTitle}</h5>
                    <p>{recommendation.intermediate}</p>

                    <h5>{beginnerTitle}</h5>
                    <p>{recommendation.beginner}</p>

                    <small><i>{recommendationFooter}</i></small>
                </div>
            )
        }

    }

    return recommendations;
}

export default LegacyData;