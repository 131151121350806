import React from 'react';
import {useStore} from "../../contexts/StateContext";
import strings from "../../assets/refs";
import "./Refs.scss";

export const Refs = () => {
    const [{lang}] = useStore();
    const {title} = strings[lang];
    const {refs} = strings[lang];
    return (
        <section className="container-inner">
            <h3>{title}</h3>
            <div className="refs-container">
                <ul>
                    {refs.map((e,i) => {
                        return (
                            <div key={i} style={{marginBottom:"10px"}}>
                                <li key={Math.random()}><b>{e.title}</b></li>
                                {e.hasOwnProperty("list") ?
                                    <ul key={Math.random()} className="list">
                                        {e.list.map(e => <li key={Math.random()} dangerouslySetInnerHTML={{__html: e}}/>)}
                                    </ul>
                                    : null
                                }
                                {e.hasOwnProperty("sublist") ?
                                    <ul key={Math.random()}>
                                        {e.sublist.map(e => {
                                            return (
                                                <div key={Math.random()} className="list">
                                                    <li key={Math.random()} dangerouslySetInnerHTML={{__html: e.title}}/>
                                                    <ul key={Math.random()} className="sublist">
                                                        {
                                                            e.list.map(e => <li key={Math.random()}
                                                                                     dangerouslySetInnerHTML={{__html: e}}/>)
                                                        }
                                                    </ul>
                                                </div>)
                                        })}
                                    
                                    </ul>
                                    
                                    : null
                                }
                            </div>
                        )
                    })}
                </ul>
            </div>
        
        </section>
    );
};