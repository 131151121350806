import React from 'react';
import dna from '../../assets/img/404.png';
import "./NoMatch.scss";

const NoMatch = function(props){
    let error = props.error ? props.error.data.error : null;
    let codeStatus = props.error ? props.error.status : null;
    let message = typeof props.error?.data === 'string' ? props.error?.data : null;

    return(
        <div className="error-wrapper">
            <img src={ dna } alt="DNA" />
            <div className="text-wrapper">
                <h1>Oops!</h1>
                <h3>{error}</h3>
                <h3>{message}!</h3>
                <p>Error code: {codeStatus}</p>
            </div>
        </div>
	);
};

export default NoMatch;