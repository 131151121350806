import React from 'react';

export const Selenium = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M86.3,56.6c-1.6,0-2.8-1.3-2.8-2.8s1.3-2.8,2.8-2.8s2.8,1.3,2.8,2.8S87.9,56.6,86.3,56.6z M86.3,52.4
			c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3S87.1,52.4,86.3,52.4z"/>
                <path d="M47.7,64.3L47.7,64.3c-0.2,0-0.4-0.1-0.6-0.1C47.3,64.2,47.5,64.2,47.7,64.3z"/>
                <path d="M61,65.6c-0.2,0-0.5-0.1-0.8-0.1v0C60.5,65.5,60.7,65.5,61,65.6z"/>
                <path d="M64.6,67.4c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0L64.6,67.4z"/>
                <path d="M64.4,65.9C64.4,65.9,64.4,65.9,64.4,65.9c0.5,0,1,0.1,1.6,0.1C65.4,66,64.9,66,64.4,65.9z"/>
                <path d="M64.4,65.9C64.4,65.9,64.4,65.9,64.4,65.9c0.5,0,1,0.1,1.6,0.1C65.4,66,64.9,66,64.4,65.9z"/>
                <path d="M96.6,59l-2.7-1.8l3-2c0.3-0.2,0.4-0.5,0.4-0.8s-0.1-0.6-0.4-0.8c-2-1.5-11.8-8.3-23.3-9.5c-0.2-2.8-1.9-7-4.4-9l-0.3-0.3
			L68.5,35c-0.4,0.2-0.5,0.5-0.5,0.7c-0.5,2.3-3.3,4.1-7.7,4.8c-4.8,0.8-6.6,2.3-7.3,3.5c-2-1.7-5.7-4.5-8.7-4.9
			c-0.4,0-0.8,0.1-1,0.5c-0.2,0.3-0.2,0.7,0.1,1c0.9,1.2,2.7,4.3,1,7.7c-2.1,0.6-4,1.3-5.9,1.9c-5.2,1.8-9.8,3.3-13.6,3.4
			c-1.3-1.8-7.9-10.6-13.5-12.3c-0.4-0.1-0.8,0-1,0.3s-0.3,0.8,0,1.1c3,4.5,6.5,10.6,6.5,13c0,2.4-3.5,8.5-6.5,13
			c-0.2,0.3-0.2,0.8,0,1.1c0.2,0.3,0.5,0.4,0.8,0.4c0.1,0,0.2,0,0.3,0C17.1,68.6,23.7,59.8,25,58c3.8,0.1,8.4,1.6,13.6,3.4
			c0.9,0.3,1.9,0.6,2.8,1c-0.4,1.8-1.2,4-2.4,5.4c-0.3,0.3-0.3,0.7,0,1c0.1,0.1,0.6,0.2,1.2,0.2c2.3,0,7.1-1,10.5-3.8
			c0.4,0.1,0.8,0.2,1.2,0.3c0.5,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.2,1.4,0.3l3.2,0.6c0.5,0.1,1,0.2,1.5,0.2l0.5,0.1
			c0.5,0.1,1,0.1,1.5,0.2l0.5,0.1c0.5,0.1,1.1,0.1,1.6,0.2l0.5,0l0.4,0c0.3,0,0.7,0.1,1,0.1c0.1,0,0.1,0,0.2,0H66v0
			c0.4,1.6,0.5,3.7,0.6,4.5c0,0.3,0.2,0.7,0.5,0.8c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.2c2-1.4,3.5-4.1,4-5.2l0.8,0
			c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0l0.5,0c0.3,0,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.3,0,0.5-0.1l0.2,0l0.4-0.1
			c0.1,0,0.1,0,0.2,0l0.9-0.2c0.2,0,0.4-0.1,0.6-0.1l0.6-0.1l0.2,0l0.2,0c0.7-0.2,1.4-0.3,2-0.5l2.2-0.7c2.8-0.9,5.2-2,7.2-2.8
			c2.1-0.9,3.8-1.6,4.9-1.8c0.4-0.1,0.7-0.3,0.8-0.7C97.1,59.6,97,59.2,96.6,59z M52.7,45.7c0.3,0.2,0.6,0.3,1,0.2
			c0.3-0.1,0.6-0.4,0.6-0.8c0.2-1,1.9-2.4,6.3-3.1c5.6-0.9,7.7-3.1,8.5-4.9c1.7,1.9,2.8,4.8,3,7c-0.6,0-1.2,0-1.8,0
			c-9.4,0-17.2,1.8-24,3.8c0.9-2.8-0.1-5.3-0.9-6.8C47.7,41.7,50.6,43.8,52.7,45.7z M41.2,67.4c0.8-1.3,1.4-2.9,1.8-4.6l1.3,0.4
			c0.5,0.2,0.9,0.3,1.4,0.4l0.7,0.2h0c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0,0.4,0.1,0.6,0.1c0.4,0.1,0.9,0.3,1.3,0.4
			C46.3,66.5,43.1,67.2,41.2,67.4z M68,70.9c-0.1-0.9-0.2-2.2-0.5-3.3c1,0,1.9,0.1,2.9,0C69.9,68.6,69.1,69.9,68,70.9z M75.2,65.9
			c-0.3,0-0.5,0.1-0.8,0.1L73.9,66c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0l-1.4,0.1c-1.8,0.1-3.6,0-5.5-0.1c-0.5,0-1-0.1-1.5-0.1
			c0,0,0,0-0.1,0h0l-0.5,0c-0.5,0-1-0.1-1.6-0.2l-0.5-0.1c-0.3,0-0.5-0.1-0.8-0.1c-0.2,0-0.5-0.1-0.8-0.1c0,0,0,0-0.1,0l-0.5-0.1
			c-0.5-0.1-1-0.1-1.5-0.2l-0.2,0l-3-0.6c-0.5-0.1-0.9-0.2-1.4-0.3c-0.5-0.1-0.9-0.2-1.4-0.3c-0.7-0.2-1.3-0.3-2-0.5l-0.9-0.2
			c-0.5-0.1-1-0.3-1.4-0.4c-0.5-0.1-1-0.3-1.4-0.4L46,62.2c-0.5-0.1-0.9-0.3-1.4-0.4l-1.4-0.4c-1.4-0.5-2.8-0.9-4.1-1.4
			c-5.5-1.9-10.2-3.5-14.4-3.5c-0.3,0-0.6,0.2-0.8,0.4c-2.4,3.4-7.2,9.1-11.3,11.3c2.3-3.5,5.8-9.5,5.8-12.3c0-2.8-3.5-8.8-5.8-12.3
			c5.2,2.8,11.1,11,11.4,11.4l0.2,0.3h0.4c4.2,0,9-1.6,14.5-3.5c8.1-2.8,18.3-6.2,31.2-6.2c1.9,0,3.8,0.2,5.6,0.5
			c-0.5,1.3-1.8,5-1.8,9.4c0,4.2,1,8.6,1.5,10.4C75.5,65.8,75.3,65.8,75.2,65.9z M90.7,61.2c-2,0.9-4.3,1.9-7.1,2.8l-2.1,0.7
			c-0.6,0.2-1.3,0.3-2,0.5l-0.4,0.1l-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.1l-0.9,0.2c-0.4-1.6-1.5-6-1.5-10.2c0-4.4,1.5-8.2,1.8-9.1
			c8.4,1.9,15.4,6.4,18,8.1l-3,2c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.3,0.2,0.6,0.4,0.8l2.4,1.6C93.6,59.9,92.3,60.5,90.7,61.2z"/>
            </g>
        </svg>
    );
};