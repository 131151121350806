import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import config from "./config/config";
import { BrowserRouter, Route } from "react-router-dom";
import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_HOST +
  process.env.REACT_APP_BACKEND_URL_PREFIX;

var instance = null;

if (process.env.REACT_APP_ENV === "prod") {
  instance = createInstance({
    urlBase: `${process.env.REACT_APP_FRONTEND_HOST}/webapp`,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${process.env.MATOMO_HOST}/matomo.php`,
    srcUrl: `${process.env.MATOMO_HOST}/matomo.js`,
    linkTracking: true,
  });
}

ReactDOM.render(
  <MatomoProvider value={instance}>
    <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
      <BrowserRouter>
        <Route path={`/webapp/:hash`} component={App} />
      </BrowserRouter>
    </CookieConsentsProvider>
  </MatomoProvider>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/sw.js");
  });
}
