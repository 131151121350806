import React from 'react';
import Category from "../Category/Category";
import {Link} from 'react-router-dom';
import { useStore} from "../../contexts/StateContext";
import '../../styles/react-tabs.scss';
import LoadingCategories from "../LoadingCategories/LoadingCategories";

import PWAPopup from '../PWA/PWAPopup'


function Categories(props) {
    
    const areas = {
        athletic: <LoadingCategories quantity={5}/>,
        preventive: <LoadingCategories quantity={2}/>,
        nutrition: <LoadingCategories quantity={8}/>
    };

    const [{hash}] = useStore();

    if(props.data !== null){
        areas[props.area] = props.data[props.area].map( (category) => {
            return (
                <Link
                    to={{
                        pathname: `/webapp/${hash}/area/${category.name}`,
                        state: {...category}
                    }}
                    key={ Math.random()+category.value }>
                    <Category
                        name={category.name}
                        label={props.text['category-labels'][category.name]}
                        value={category.value}
                        badge={category.badge}
                        priority={category.priority}
                        descriptions={category.descriptions}
                        recommendations={category.recommendations}
                        predispositions={category.predispositions}
                        predispositionExtra={category.predispositionExtra}
                        color={props.color}
                        effect="scale"/>
                </Link>
            );
        });
    }

    return (
        <>
            <div className="title-wrapper">
                <h4>{props.text.plan[props.area].title}</h4>
    
                <small>{props.text.plan[props.area].subtitle}</small>
            </div>
            

    
            <ul>
                {areas[props.area]}
            </ul>

            <PWAPopup/>
        </>
    );
}

export default Categories;