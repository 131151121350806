import React, { useState, useCallback } from 'react';
import { createPortal } from 'react-dom';

const Modal = ({ children, isOpen = false, close, elementId = 'root' }) => {
    if (isOpen === false) {
        return null;
    }
    return createPortal(
        <div className="wrapper" onClick={close}>
            <div className="modal-container">{children}</div>
        </div>,
        document.getElementById(elementId)
    );
};

const useModal = elementId => {
    const [isOpen, setOpen] = useState(false);
    const open = useCallback(() => setOpen(true), [setOpen]);
    const close = useCallback(() => setOpen(false), [setOpen]);

    const ModalWrapper = ({ children }) => (
        <Modal isOpen={isOpen} close={close} elementId={elementId}>
            <div className="bm-cross-button" onClick={close}>
                <span className="bm-cross" style={{position: 'absolute', width: '3px', height: '14px', transform: 'rotate(45deg)'}}/>
                <span className="bm-cross" style={{position: 'absolute', width: '3px', height: '14px', transform: 'rotate(-45deg)'}}/>
            </div>
            {children}
        </Modal>
    );

    return [ModalWrapper, open, close];
};

export default useModal;