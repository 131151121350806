import React, { useState, useEffect } from "react";

import Text from "../../assets/strings";
import { useStore } from "../../contexts/StateContext";

import PWAPrompt from "react-ios-pwa-prompt";
import IOSPrompt from "./IOSPrompt";

const PWAPopup = () => {
  const [{ lang }] = useStore();

  const [popup, setPopup] = useState("none");

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  // Detects if device is is Safari
  function isInSafari() {
    let userAgentString = window.navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
      userAgentString.indexOf("Chrome") > -1 ||
      userAgentString.indexOf("CriOS") > -1;

    // Detect Safari
    let safariAgent = userAgentString.indexOf("Safari") > -1;

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf("FxiOS") > -1;

    // Safari matches Chrome and firefox
    if ((chromeAgent && safariAgent) || (firefoxAgent && safariAgent))
      return false;
    return safariAgent;
  }

  const checkPropmtConditions = () => {
    if (!isInStandaloneMode() && isIos()) {
      if (isInSafari()) {
        return "PWAPrompt";
      } else {
        return "IOSPrompt";
      }
    } else {
      return "none";
    }
  };

  let whichPopup = localStorage.getItem("whichPopup");
  if (whichPopup === null) {
    whichPopup = checkPropmtConditions();
    localStorage.setItem("whichPopup", whichPopup);
  }

  React.useEffect(() => {
    if (whichPopup !== "none") {
      setPopup(whichPopup);
    }
  }, [whichPopup]);

  let popupText = Text[lang].web["pwa-popup"];
  let popupDelay = 7000;

  return (
    <>
      {popup === "PWAPrompt" ? (
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={2}
          delay={popupDelay}
          permanentlyHideOnDismiss={false}
          copyTitle={popupText["in-safari"].title}
          copyBody={popupText["in-safari"].body}
          copyShareButtonLabel={popupText["in-safari"].shareButtonLabel}
          copyAddHomeButtonLabel={popupText["in-safari"].addHomeButtonLabel}
          copyClosePrompt={popupText["in-safari"].close}
        />
      ) : popup === "IOSPrompt" ? (
        <IOSPrompt
          title={popupText["not-safari"].title}
          body={popupText["not-safari"].body}
          delay={popupDelay}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PWAPopup;
