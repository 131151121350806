import React from 'react'

function RecoveryIcon(props) {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"/>
                </clipPath>
                <path  fill={props.color} d="M88.6,72.16c-0.22,0-0.44-0.08-0.6-0.25L72.34,55.93c-2.9,1.14-16.6,6.4-22.4,6.44h-0.01
                    c-0.46,0-0.84-0.37-0.84-0.84c0-0.47,0.37-0.85,0.84-0.85c6.04-0.04,22.15-6.46,22.31-6.53c0.32-0.13,0.68-0.05,0.92,0.19
                    l16.06,16.38c0.32,0.33,0.32,0.87-0.01,1.19C89.03,72.08,88.82,72.16,88.6,72.16"/>
                <path  fill={props.color} d="M52.11,49.3c-0.09,0-0.17-0.01-0.26-0.04c-4.39-1.39-12.24-7.09-12.58-7.33c-0.38-0.27-0.46-0.8-0.19-1.18
                    c0.28-0.38,0.8-0.46,1.18-0.19c0.08,0.06,7.36,5.35,11.6,6.92c1.8-1.95,9.6-10.77,9.13-16.42c-0.04-0.46,0.31-0.87,0.77-0.91
                    c0.42-0.04,0.87,0.31,0.91,0.77c0.59,7.11-9.53,17.67-9.96,18.12C52.55,49.21,52.33,49.3,52.11,49.3"/>
                <path  fill={props.color} d="M90.55,81.34c-0.17,0-0.35-0.05-0.5-0.16l-17.57-12.9H42.79c-0.26,0-0.51-0.12-0.67-0.33L13.03,30.21
                    c-0.28-0.37-0.21-0.9,0.15-1.18c0.37-0.28,0.9-0.22,1.18,0.15L43.2,66.59h29.55c0.18,0,0.35,0.06,0.5,0.16l17.79,13.06
                    c0.38,0.27,0.46,0.8,0.18,1.18C91.06,81.22,90.8,81.34,90.55,81.34"/>
                <path  fill={props.color} d="M32.59,81.34c-0.18,0-0.36-0.06-0.52-0.18c-0.37-0.29-0.43-0.82-0.15-1.18l10.2-13.06
                    c0.29-0.37,0.82-0.43,1.19-0.15c0.37,0.29,0.43,0.82,0.15,1.18l-10.2,13.06C33.09,81.23,32.84,81.34,32.59,81.34"/>
                <path  fill={props.color} d="M49.93,62.37c-0.15,0-0.31-0.04-0.45-0.13c-8.23-5.23-13.51-14.08-15.1-20.52c-1.06-4.31,0.04-6.77,0.77-8.41
                    l0.09-0.21c1.79-4.03,2.53-7.43,0.33-10.91c-1.03-1.63-2.68-2.57-4.64-2.63c-2.11-0.09-4.34,0.93-5.47,2.48
                    c-2.6,3.58-1.49,7.95,0.95,10.41c1.54,1.55,3.57,2.24,3.59,2.24c0.44,0.15,0.68,0.62,0.53,1.06c-0.15,0.44-0.62,0.68-1.06,0.54
                    c-0.1-0.03-2.43-0.82-4.26-2.66c-2.94-2.97-4.27-8.25-1.12-12.59c1.46-2.02,4.21-3.25,6.89-3.17c2.54,0.08,4.67,1.29,6.01,3.41
                    c2.76,4.35,1.53,8.58-0.22,12.5L36.7,34c-0.67,1.51-1.6,3.57-0.67,7.32c1.45,5.92,6.57,14.55,14.36,19.5
                    c0.39,0.25,0.51,0.77,0.26,1.16C50.48,62.23,50.21,62.37,49.93,62.37"/>
                <path  fill={props.color} d="M59.45,24.69c-0.19,0-0.39-0.07-0.55-0.2l-4.17-3.58c-0.35-0.3-0.39-0.83-0.09-1.19
                    c0.3-0.35,0.84-0.39,1.19-0.09L60,23.2c0.35,0.3,0.39,0.83,0.09,1.19C59.92,24.59,59.69,24.69,59.45,24.69"/>
                <path  fill={props.color} d="M49.84,37.57c-0.19,0-0.39-0.07-0.54-0.19c-0.23-0.19-0.34-0.49-0.29-0.78l1.92-11.76
                    c0.06-0.36,0.35-0.64,0.71-0.7l7.69-1.12c0.27-0.04,0.53,0.05,0.72,0.24c0.19,0.19,0.28,0.46,0.23,0.73l-1.64,10.04
                    c-0.05,0.3-0.26,0.55-0.55,0.66l-7.98,2.85C50.03,37.56,49.93,37.57,49.84,37.57 M52.49,25.71l-1.59,9.75l6.16-2.2l1.37-8.41
                    L52.49,25.71z"/>
            </g>
        </svg>
    )
}

export default RecoveryIcon;