import React from 'react';
import {withRouter} from 'react-router-dom';
import Category from "../Category/Category";
import GoBack from "../GoBack/GoBack";
import Areas from '../../assets/areas';
import Text from '../../assets/strings';
import {useStore} from "../../contexts/StateContext";
import {Redirect} from "react-router-dom";
import NewData from "./NewData/NewData";
import LegacyData from "./LegacyData/LegacyData";
import config from "../../config/config";
import {Axis} from "../Axis/Axis";


function FullCategory(props) {
    let [{lang, hash}] = useStore();
    if(props.location.state === undefined || lang === undefined) {
        return <Redirect to={`/webapp/${hash}/plan`} />
    }
    const areaTypeLabel = Text[lang].plan[props.location.state.type].title;
   
    return (
        <section className="container-inner">
            <GoBack history={props.history}/>
            <h4>{areaTypeLabel}</h4>
            <div className="full-category">
                <Category
                    name={props.location.state.name}
                    label={Text[lang]['category-labels'][props.location.state.name]}
                    value={props.location.state.value}
                    badge={props.location.state.badge}
                    color={config["area-colors"][props.location.state.type]}
                    priority={props.location.state.priority}
                    />
            </div>

            {
                props.location.state.hasOwnProperty('recommendations')
                ? <NewData {...props}/>
                : <LegacyData {...props}/>
            }


        </section>
    )
}

export default withRouter(FullCategory);