import React from 'react'

function PowerenduranceBadge(props) {

        let styling = props.badge ? 'active-badge' : 'inactive';
        const showSvg = props.badge ? 'initial':'none';

        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" style={{display: showSvg}}
                 viewBox="0 0 401 343" xmlSpace="preserve">

                    <path className={styling} d="M246.5,154.3c-1.4-2.6-2.8-5.1-4.2-7.7c-0.4,0.2-0.7,0.3-1.1,0.5c-8.4,4.4-16.8,8.8-25.3,13.2c-0.8,0.4-0.9,0.9-0.8,1.6
	c0.7,6.4,1.4,12.7,2,19.1c0.4,4.1,0.9,8.1,1.3,12.2c0.1,0.5-0.1,1.2-0.4,1.6c-5.6,7.1-11.2,14.2-16.9,21.2c-0.2,0.2-0.4,0.4-0.6,0.7
	c-2.2-2.7-4.3-5.4-6.5-8.1c-3.6-4.5-7.1-9-10.7-13.4c-0.6-0.7-0.8-1.3-0.7-2.3c1.1-10.4,2.2-20.8,3.3-31.2c0-0.4-0.3-1-0.6-1.2
	c-8.6-4.6-17.2-9.1-25.9-13.6c-0.2-0.1-0.4-0.2-0.7-0.3c-1.4,2.5-2.8,5.1-4.2,7.7c5.2,2.7,10.2,5.4,15.3,8.1c2.9,1.5,5.7,3,8.6,4.5
	c0.6,0.3,0.9,0.7,0.9,1.5c0.3,13.5,0.6,27,0.9,40.5c0.2,9.9,0.5,19.7,0.7,29.6c0.3,13.4,0.6,26.9,1,40.3c0.1,4.7,0.3,9.4,0.4,14.1
	c-0.1,0-0.2,0.1-0.3,0c-4.8-3.4-9.9-6.3-14.1-10.5c-6.3-6.2-11.8-13.2-16.7-20.5c-8.7-12.8-15.9-26.4-20.9-41.1
	c-3.2-9.5-5.4-19.2-6-29.3c-0.6-10.4-1.5-20.7-1.8-31.1c-0.4-12.8-0.3-25.6-0.6-38.4c0-2.4,0.5-4,2.9-4.8c2.7-0.9,4-2.6,4.6-5.6
	c2.7-12,8.4-22.5,17.2-31.1c8.4-8.2,18.6-13.4,29.9-16.4c10.8-2.8,21.8-3.5,32.9-2.5c9.4,0.8,18.5,2.9,27.1,6.9
	c18.6,8.8,30,23.4,34.8,43.3c0.6,2.5,1.4,4.4,4,5.1c2.9,0.9,3.5,2.8,3.4,5.7c-0.2,9.1,0.1,18.2-0.2,27.3c-0.3,11-0.9,22-1.6,33
	c-0.3,5.9-0.8,11.9-1.7,17.8c-1.6,11.2-5.1,21.9-9.7,32.2c-7.1,15.9-16.2,30.5-27.5,43.7c-4.8,5.6-10.4,10.2-16.6,14.2
	c-1,0.6-1.9,1.3-3,2.1c0-2,0-3.9,0-5.8c0.1-6.4,0.3-12.8,0.5-19.2c0.3-11.3,0.6-22.6,0.9-33.9c0.2-6.1,0.3-12.1,0.4-18.2
	c0.3-10.6,0.5-21.2,0.8-31.8c0.1-5.2,0.2-10.4,0.3-15.6c0-0.9,0.3-1.4,1.1-1.8c7.5-3.9,15-7.9,22.5-11.9
	C245.6,154.8,246,154.6,246.5,154.3z M231.2,267.3c0.1-0.2,0.3-0.3,0.5-0.5c8.9-10.9,16.4-22.6,22.3-35.4c4.8-10.2,8.6-20.8,10.1-32
	c1-7.8,1.6-15.7,2.1-23.5c1-16.3,1.5-32.7,1.3-49c0-0.7-0.2-1.1-0.9-1.5c-4.3-2.3-8.6-4.6-12.9-6.9c-17.4-9.3-34.8-18.7-52.3-28
	c-0.4-0.2-1.2-0.3-1.6-0.1c-21.8,11.6-43.5,23.3-65.2,34.9c-0.7,0.3-0.9,0.8-0.9,1.5c0.1,5.2,0.1,10.5,0.1,15.7
	c0.1,15.7,1,31.3,2.1,46.9c0.7,9,2.2,17.7,5,26.3c5.8,17.4,14.7,33.1,25.8,47.6c1,1.3,2.1,2.6,3.4,4.2c0-0.5,0.1-0.6,0.1-0.7
	c-0.2-7.8-0.4-15.7-0.6-23.5c-0.3-9.9-0.5-19.9-0.7-29.8c-0.3-12.5-0.6-25.1-0.8-37.6c0-1.1-0.4-1.6-1.3-2.1
	c-8.9-4.7-17.9-9.4-26.8-14.2c-0.4-0.2-0.7-0.4-1.1-0.7c5.2-9.4,10.2-18.7,15.4-28.1c3.4,1.8,6.6,3.4,9.8,5.1
	c11.2,5.9,22.3,11.7,33.5,17.5c0.7,0.4,1,0.7,0.9,1.6c-0.6,5-1.1,10.1-1.6,15.1c-0.7,6.4-1.4,12.8-2,19.2c0,0.4-0.1,1,0.1,1.3
	c1.8,2.4,3.7,4.7,5.7,7.2c1.9-2.4,3.8-4.7,5.6-7.1c0.2-0.3,0.3-0.9,0.2-1.3c-0.3-3.4-0.7-6.8-1.1-10.1c-0.8-7.9-1.6-15.8-2.5-23.7
	c-0.1-1.3,0.2-1.8,1.3-2.4c10.9-5.7,21.9-11.4,32.8-17.2c3.4-1.8,6.7-3.5,10.2-5.3c5.1,9.4,10.2,18.7,15.4,28.1
	c-0.4,0.2-0.8,0.5-1.1,0.6c-9,4.8-18,9.5-27,14.3c-0.8,0.4-1.1,0.9-1.2,1.9c-0.1,7-0.3,14.1-0.5,21.1c-0.3,11.9-0.6,23.8-0.9,35.7
	c-0.2,8.1-0.4,16.1-0.5,24.2C231.1,260.3,231.2,263.8,231.2,267.3z M142.9,107.7c0.5-0.3,0.7-0.4,0.9-0.5
	c18.4-9.9,36.9-19.7,55.3-29.7c1.1-0.6,1.9-0.5,2.9,0c18.4,9.9,36.8,19.8,55.2,29.6c0.3,0.1,0.6,0.3,1,0.4c-0.1-0.4-0.2-0.6-0.2-0.8
	c-5-12.6-13.6-21.8-25.9-27.6c-7.7-3.6-15.8-5.4-24.3-6.1c-9.9-0.7-19.6-0.2-29.2,2.5c-12.8,3.6-23.3,10.5-30.6,21.8
	C146,100.7,144.3,104,142.9,107.7z"/>
                    <path className={styling}
                          d="M182.3,8.1c12.2,0,24.3,0,36.5,0c-1.6,16.5-3.3,33-4.9,49.6c-8.9-0.7-17.7-0.7-26.6,0C185.6,41.2,184,24.7,182.3,8.1z"/>
                    <path className={styling}
                          d="M64.6,223.9c6.5,8.6,11.3,18.1,13.9,28.6c2.2,8.9,2.8,17.9,1.9,27C67.9,263,62.7,244.5,64.6,223.9z"/>
                    <path className={styling}
                          d="M26.8,175.7c-11.6-17.1-15.9-35.8-13.1-56.3c5.7,8.2,9.7,17.1,12,26.8C28,156,28.2,165.8,26.8,175.7z"/>
                    <path className={styling}
                          d="M358.4,90.2c-5.1-6.2-9-13-12-20.4c-4.5-11.2-6-22.8-5.2-34.8c6.3,7.8,11,16.4,14,25.9C358.1,70.5,359.1,80.3,358.4,90.2z"
                    />
                    <path className={styling}
                          d="M350.6,239c-9-18.6-10.7-37.8-4.9-57.6C354.7,200,356.4,219.2,350.6,239z"/>
                    <path className={styling}
                          d="M345,142.3c13.6,15.5,20.1,33.6,20,54.2c-3.4-2.2-12.1-16.5-15.3-25.2C346.2,162,344.7,152.3,345,142.3z"/>
                    <path className={styling}
                          d="M370.9,128.1c-7.1-19.7-6.7-39.1,1-58.6C379,89.3,378.7,108.6,370.9,128.1z"/>
                    <path className={styling}
                          d="M274.4,315.7c17.3-11.3,36.1-15.3,56.5-12.1c-8.3,5.5-17.3,9.4-27,11.5C294.1,317.2,284.3,317.3,274.4,315.7z"/>
                    <path className={styling}
                          d="M89.8,66.3c-9.7,18.2-24.2,30.9-43.6,38c1.5-4.3,9.6-16.1,18.1-23.3C71.9,74.6,80.4,69.7,89.8,66.3z"/>
                    <path className={styling}
                          d="M362.5,228.2c1.9-10.2,5.4-19.6,10.7-28.2c5.2-8.3,11.7-15.4,19.6-21.5C391.4,194.9,376.2,219.9,362.5,228.2z"/>
                    <path className={styling}
                          d="M110.2,302.1c-14-15-21.2-32.9-21.6-53.5c6.8,7.2,12.2,15.4,15.9,24.6C108.4,282.5,110.2,292.1,110.2,302.1z"/>
                    <path className={styling}
                          d="M252.9,318.8c5.1-20,16.2-35.7,33.4-47.2C281.1,291.6,270,307.3,252.9,318.8z"/>
                    <path className={styling}
                          d="M126.7,315.7c-20.4,3.2-39.2-0.8-56.5-12.1C90.6,300.4,109.4,304.4,126.7,315.7z"/>
                    <path className={styling}
                          d="M366.6,276.7c-16.7,12-35.4,16.9-55.9,14.6c8-5.9,16.9-10.2,26.5-12.7C346.9,276.1,356.7,275.5,366.6,276.7z"/>
                    <path className={styling}
                          d="M312.5,248.6c-0.3,20.6-7.5,38.4-21.6,53.5C291.2,281.5,298.4,263.7,312.5,248.6z"/>
                    <path className={styling}
                          d="M32.7,155.1c3.5-20.3,13.4-36.8,29.6-49.5C58.8,125.9,48.9,142.4,32.7,155.1z"/>
                    <path className={styling}
                          d="M90.4,291.3c-20.5,2.3-39.1-2.6-56-14.5c5.1-1.3,19.1-1.1,29.4,1.8C73.5,281.2,82.4,285.4,90.4,291.3z"/>
                    <path className={styling}
                          d="M42.7,90.2c-1.3-20.6,4.4-38.9,17.2-55.1c0.7,9.9-0.3,19.7-3.2,29.2C53.7,73.9,49,82.5,42.7,90.2z"/>
                    <path className={styling}
                          d="M374.4,175.7c-2.9-20.4,1.5-39.1,13-56.2c1.5,9.9,1.2,19.7-1.1,29.4C384.1,158.5,380,167.4,374.4,175.7z"/>
                    <path className={styling}
                          d="M36.4,196.5c-0.3-20.6,6.2-38.7,19.8-54.2C56.5,162.9,49.9,181,36.4,196.5z"/>
                    <path className={styling}
                          d="M336.1,69.3c-18.3-9.6-30.9-24.2-38-43.6C316.3,35.4,329,49.9,336.1,69.3z"/>
                    <path className={styling}
                          d="M114.9,271.7c17.2,11.4,28.3,27.1,33.4,47.1C131.1,307.4,120,291.7,114.9,271.7z"/>
                    <path className={styling}
                          d="M294.2,331.2c-19.7,6-38.9,4.7-57.7-3.9c2.4-1.6,17.9-4.7,29.1-3.7C275.7,324.4,285.1,326.9,294.2,331.2z"/>
                    <path className={styling}
                          d="M18.6,231.1c10.8,3.5,20.6,8.8,29,16.5c6.4,5.8,11.7,12.4,15.9,19.9C43.8,261.1,28.9,249,18.6,231.1z"/>
                    <path className={styling}
                          d="M38.5,228.1c-16.3-12.6-26.4-29-30-49.3c8,6,14.6,13.2,19.8,21.8C33.4,209.1,36.8,218.3,38.5,228.1z"/>
                    <path className={styling}
                          d="M337.5,267.6c9.7-17.1,25.2-30.2,44.9-36.5c-0.8,3.7-11.6,16.6-18.8,22.6C355.3,260.6,343.2,266.2,337.5,267.6z"/>
                    <path className={styling}
                          d="M165,327.1c-19.1,8.8-38.3,10.1-58.4,4.1C125.6,322.5,144.8,321.1,165,327.1z"/>
                    <path className={styling}
                          d="M311.3,66.4c19.4,7,33.9,19.6,43.6,37.9C335.5,97.2,321,84.6,311.3,66.4z"/>
                    <path className={styling}
                          d="M50.5,239.1c-5.7-19.8-4.1-39,5-57.6C61.1,201.3,59.5,220.5,50.5,239.1z"/>
                    <path className={styling} d="M65,69.3c7.1-19.4,19.8-33.9,38-43.5C96,45.2,83.3,59.7,65,69.3z"/>
                    <path className={styling} d="M29.2,69.9c7.7,19.1,8,38.4,1,57.7C22.5,108.5,22.1,89.3,29.2,69.9z"/>
                    <path className={styling}
                          d="M338.8,105.5c16.2,12.7,26.1,29.3,29.6,49.6c-7.9-6.1-14.5-13.3-19.6-21.9C343.7,124.6,340.4,115.4,338.8,105.5z"/>
                    <path className={styling}
                          d="M336.6,223.9c1.9,20.5-3.3,39.1-15.7,55.6C319,258.9,324.2,240.4,336.6,223.9z"/>
            </svg>

        )
}

export default PowerenduranceBadge;