import React from 'react';
import strings from '../../assets/strings.json';
import Text from '../../assets/help.json';
import "./Help.scss";
import {useStore} from "../../contexts/StateContext";
import {withRouter} from 'react-router-dom';
import GoBack from "../GoBack/GoBack";
import {Athletic} from "../../assets/icons/Athletic";
import {Preventive} from "../../assets/icons/Preventive";
import {Nutrition} from "../../assets/icons/Nutrition";
import OuterBadge from "../../assets/badges/OuterBadge";
import BulletproofBody from "../../assets/badgesWithName/BulletproofBody.png";
import HammerStrength from "../../assets/badgesWithName/HammerStrength.png";
import HumanMachine from "../../assets/badgesWithName/HumanMachine.png";
import IronmanTriathlon from "../../assets/badgesWithName/IronmanTriathlon.png";
import NaturalBodybuilder from "../../assets/badgesWithName/NaturalBodybuilder.png";
import PureWarrior from "../../assets/badgesWithName/PureWarrior.png";
import QuickRecharger from "../../assets/badgesWithName/QuickRecharger.png";
import TrueSpartan from "../../assets/badgesWithName/TrueSpartan.png";
import highPriority from "../../assets/img/high.png";

const Help = function (props) {
    
    const [{lang}] = useStore();
    
    return (
        <section className="container-inner">
            <GoBack history={props.history}/>
            <div className="help-container">
                <h3>{Text[lang].title}</h3>
                <p>{Text[lang].p1}</p>
                <ul className="functionalities">
                    <li>{Text[lang].functionalities["1"]}</li>
                    <li>{Text[lang].functionalities["2"]}</li>
                    <li>{Text[lang].functionalities["3"]}</li>
                </ul>
                
                <p>{Text[lang].p2}</p>
                
                
                <ul className="areas">
                    <li>
                        <Athletic/>
                        <span>
                            {Text[lang].areas["1"]}
                        </span>
                    </li>
                    <li>
                        <Preventive/>
                        <span>
                            {Text[lang].areas["2"]}
                        </span>
                    </li>
                    <li>
                        <Nutrition/>
                        <span>
                            {Text[lang].areas["3"]}
                        </span>
                    </li>
                </ul>
                
                <p dangerouslySetInnerHTML={{__html: Text[lang].p3}}/>
                
                <h4>{Text[lang].title1}</h4>
                
                
                <ul className="symbol-desc">
                    <li>
                        <OuterBadge size={90}/>
                        <p>
                            {Text[lang].sub1}
                        </p>
                    </li>
                </ul>
                
                <p dangerouslySetInnerHTML={{__html: Text[lang].p4}}/>
                
                <p dangerouslySetInnerHTML={{__html: Text[lang].p5}}/>
                
                <ul className="badges-desc">
                    <li>
                        <img src={HammerStrength}/>
                    </li>
                    
                    <li>
                        <img src={IronmanTriathlon}/>
                    </li>
                    
                    <li>
                        <img src={TrueSpartan}/>
                    </li>
                    
                    <li>
                        <img src={HumanMachine}/>
                    </li>
    
                    <li>
                        <img src={NaturalBodybuilder}/>
                    </li>
                    
                    <li>
                        <img src={BulletproofBody}/>
                    </li>
    
                    <li>
                        <img src={QuickRecharger}/>
                    </li>

                </ul>
                
                <p dangerouslySetInnerHTML={{__html: Text[lang].p6}}/>
    
                <table>
                    <tbody>
                        <tr>
                            <th>{Text[lang].table.h1}</th>
                            <th>{Text[lang].table.h2}</th>
                        </tr>
                        <tr>
                            <td><b><p>{Text[lang].table.levels["1"]}</p></b></td>
                            <td>
                                <ul>
                                    <li dangerouslySetInnerHTML={{__html: Text[lang].table.description["1"]["1"]}}/>
                                    <li dangerouslySetInnerHTML={{__html: Text[lang].table.description["1"]["2"]}}/>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><b><p>{Text[lang].table.levels["2"]}</p></b></td>
                            <td>
                                <ul>
                                    <li dangerouslySetInnerHTML={{__html: Text[lang].table.description["2"]["1"]}}/>
                                    <li dangerouslySetInnerHTML={{__html: Text[lang].table.description["2"]["2"]}}/>
                                    <li dangerouslySetInnerHTML={{__html: Text[lang].table.description["2"]["3"]}}/>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td><b><p>{Text[lang].table.levels["3"]}</p></b></td>
                            <td>
                                <ul>
                                    <li dangerouslySetInnerHTML={{__html: Text[lang].table.description["3"]["1"]}}/>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="table-sub">
                    <p>
                        <small dangerouslySetInnerHTML={{__html: Text[lang].table.sub.p1}}/>
                    </p>
                    <p>
                        <small dangerouslySetInnerHTML={{__html: Text[lang].table.sub.p2}}/>
                    </p>
                </div>
    
                <h4>{Text[lang].title2}</h4>
    
                <ul className="symbol-desc">
                    <li>
                        <img src={highPriority}  alt="high priority"/>
                        <p>
                            {Text[lang].sub2}
                        </p>
                    </li>
                </ul>
    
                <p dangerouslySetInnerHTML={{__html: Text[lang].p7}}/>
                
                <p dangerouslySetInnerHTML={{__html: Text[lang].p8}}/>
    
                <hr/>
                
                <h4>{Text[lang].shortcut["how-to-make-shortcut-title-android"]}</h4>
                
                <ul className="mobile-instructions">
                    <li>{Text[lang].shortcut["how-to-make-shortcut-step-one"]}</li>
                    <li>{Text[lang].shortcut["how-to-make-shortcut-step-two"]}</li>
                    <li>{Text[lang].shortcut["how-to-make-shortcut-step-three"]}</li>
                </ul>
    
                <h4>{Text[lang].shortcut["how-to-make-shortcut-title-ios"]}</h4>
                
                <ul className="mobile-instructions">
                    <li>{Text[lang].shortcut["how-to-make-shortcut-ios-step-one"]}</li>
                    <li>{Text[lang].shortcut["how-to-make-shortcut-ios-step-two"]}</li>
                    <li>{Text[lang].shortcut["how-to-make-shortcut-ios-step-three"]}</li>
                </ul>
                
                
                {/* <h3>{strings[lang].help.title}</h3>
                <p>{strings[lang].help["sub-title"]}</p>

                <h4>{strings[lang].help["how-to-make-shortcut-title-android"]}</h4>
                <ul>
                    <li>{strings[lang].help["how-to-make-shortcut-step-one"]}</li>
                    <li>{strings[lang].help["how-to-make-shortcut-step-two"]}</li>
                    <li>{strings[lang].help["how-to-make-shortcut-step-three"]}</li>
                </ul>
                <p>{strings[lang].help["watch-video"]}</p>
                <div className="iframe-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/x05fsRH_3tg" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen title="android chrome shortcut"/>
                </div>

                <h4>{strings[lang].help["how-to-make-shortcut-title-ios"]}</h4>
                <ul>
                    <li>{strings[lang].help["how-to-make-shortcut-ios-step-one"]}</li>
                    <li>{strings[lang].help["how-to-make-shortcut-ios-step-two"]}</li>
                    <li>{strings[lang].help["how-to-make-shortcut-ios-step-three"]}</li>
                </ul>
                <p>{strings[lang].help["watch-video"]}</p>
                <div className="iframe-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/dSIarEvDZbk" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen title="ios safari shortcut"/>
                </div>*/}
            </div>
        </section>
    )
};

export default withRouter(Help);