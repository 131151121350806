import React from "react";
import icon from "../../assets/img/back.svg"

const GoBack = (props) => {
    return (
        <button
            className="go-back pull-right"
            onClick={ ()=> props.history.goBack()}>
            <img src={icon} alt="arrow back"/>
        </button>
    )
};

export default GoBack;