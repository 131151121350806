import React from 'react';
import GoBack from "../GoBack/GoBack";
import {withRouter} from 'react-router-dom';
import cookiesData from "../../assets/cookies";
import {useStore} from "../../contexts/StateContext";

const Cookies = function(props){

    const [{lang}] = useStore();

    return(
        <section className="container-inner">
            <GoBack history={ props.history } />
            <div className="cookies" dangerouslySetInnerHTML={{__html: cookiesData[lang]}}/>
        </section>
    )
};

export default withRouter(Cookies);