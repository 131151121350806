import React from 'react';

export const Antioxidants = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M53.8,85.2c-0.4,0-0.9-0.1-1.4-0.4c-12.2-6.2-20.5-16.3-24.7-29.7c-2.1-6.7-3-14-2.9-21.6c0-1.2,0.5-1.8,1.7-2.2
		c4.4-1.4,8.7-2.8,13.1-4.3c4.4-1.4,8.7-2.9,13.1-4.3c0.6-0.2,1.4-0.2,2.1,0c4.9,1.6,9.8,3.2,14.7,4.8c3.9,1.3,7.8,2.5,11.6,3.8
		c1.1,0.4,1.6,1,1.6,2.1c0,0.8,0,1.7,0,2.5l0,1.1c-0.3,8.6-1.8,16.1-4.6,23c-0.5,1.3-1.1,2.6-1.8,3.9c-3,5.9-7.1,11-12.1,15.1
		c-2.7,2.2-5.6,4.1-9,5.9C54.7,85.1,54.3,85.2,53.8,85.2z M53.9,24.1c-0.2,0-0.4,0-0.6,0.1c-4.4,1.4-8.7,2.8-13.1,4.3
		c-4.4,1.4-8.7,2.9-13.1,4.3c-0.5,0.2-0.6,0.3-0.6,0.8c-0.2,7.6,0.7,14.5,2.8,21.1c4,13.1,12.1,22.8,24,28.9c0.5,0.3,0.8,0.3,1.3,0
		c3.3-1.7,6.2-3.6,8.8-5.7c4.9-4,8.8-8.9,11.8-14.6c0.6-1.2,1.2-2.5,1.7-3.8c2.8-6.7,4.3-14,4.5-22.4l0-1.1c0-0.9,0-1.7,0-2.6
		c0-0.4,0-0.5-0.5-0.7c-3.9-1.3-7.8-2.5-11.6-3.8c-4.9-1.6-9.8-3.2-14.7-4.8C54.2,24.1,54.1,24.1,53.9,24.1z"/>
                <g>
                    <path d="M59.7,64.3c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5S61.7,64.3,59.7,64.3z M59.7,58.7c-1.1,0-2,0.9-2,2
			s0.9,2,2,2s2-0.9,2-2S60.9,58.7,59.7,58.7z"/>
                    <path d="M45.6,54.1c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7C48.3,52.9,47.1,54.1,45.6,54.1z M45.6,50.2
			c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2s1.2-0.5,1.2-1.2C46.8,50.8,46.3,50.2,45.6,50.2z"/>
                    <path d="M48,67.2c-3.2,0-5.7-2.6-5.7-5.7c0-3.2,2.6-5.7,5.7-5.7s5.7,2.6,5.7,5.7C53.7,64.7,51.1,67.2,48,67.2z M48,57.3
			c-2.3,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2S50.3,57.3,48,57.3z"/>
                    <g>
                        <path d="M64.8,49.9l-2.7-0.6c0-0.2,0-0.4,0-0.5c0-1.6-0.6-3-1.6-4.1l1.2-1.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0
				l-1.3,1.3c-0.9-0.6-1.9-0.9-3-0.9c-0.5,0-0.9,0-1.4,0.2L54.1,41c-0.2-0.4-0.6-0.5-1-0.4c-0.4,0.2-0.5,0.6-0.4,1l0.8,1.8
				c-0.5,0.3-1,0.6-1.4,1c-0.7,0.7-1.1,1.4-1.4,2.3l-2.8-0.7c-0.4-0.1-0.8,0.2-0.9,0.5c-0.1,0.4,0.2,0.8,0.6,0.9l2.9,0.7
				c0,0.1,0,0.3,0,0.4c0,1.6,0.6,3,1.6,4l-1.2,1.2c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l1.3-1.3
				c0.9,0.6,1.9,0.9,3,0.9h0.1c0.4,0,0.8,0,1.1-0.1l0.9,1.8c0.1,0.3,0.4,0.4,0.7,0.4c0.1,0,0.2,0,0.3-0.1c0.4-0.2,0.5-0.6,0.4-1
				l-0.8-1.7c0.6-0.3,1.1-0.6,1.5-1.1c0.6-0.6,1.1-1.3,1.4-2.1l2.7,0.6c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.7-0.6
				C65.5,50.4,65.2,50,64.8,49.9z M60.6,48.7c0,1.2-0.5,2.3-1.3,3.1c-0.8,0.8-2,1.2-3.1,1.2c-2.4,0-4.3-2-4.3-4.4
				c0-1.2,0.5-2.2,1.3-3.1c0.8-0.8,1.9-1.2,3-1.2h0c0.9,0,1.8,0.3,2.5,0.8c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0,0
				C60,46.2,60.6,47.3,60.6,48.7L60.6,48.7z"/>
                        <g>
                            <path d="M59.1,45.3c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0-0.1-0.1C58.9,45.2,59,45.3,59.1,45.3z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};