import React from 'react';
import Skeleton from "react-loading-skeleton";

function LoadingCategory(props) {

    const style = props.effect ? ['category-inner', props.effect].join(' ') : 'category-inner';

    return (
        <div className="category">
            <div className={style}>
                <div className="category-icon">
                    <Skeleton circle={true} height={60} width={60}/>
                </div>
                <div className="progress">
                    <h5><Skeleton width={100} /></h5>
                    <Skeleton />
                </div>
            </div>
        </div>
    )
}

export default LoadingCategory;