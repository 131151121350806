import React from 'react';

export const FoodList = (props) => {
    const data = props.data;
    //console.log(data);
    return (
        <div className="food-list">
            
            {
                data.hasOwnProperty('text') ?
                    <div className="text">
                        <b>
                            <p dangerouslySetInnerHTML={{__html: data.text}}/>
                        </b>
                    </div>
                    : null
            }
            
            {
                data.areas.map((area, i) => {
                    return (
                        <div key={i}>
                            {
                                area.hasOwnProperty('title') ?
                                    <b>
                                        <p dangerouslySetInnerHTML={{__html: area.title}}/>
                                    </b>
                                : null
                            }
                            {
                                area.hasOwnProperty('list') ?
                                    <ul>
                                        {area.list.map((e,i) => <li key={i} dangerouslySetInnerHTML={{__html: e}}/>)}
                                    </ul>
                                    : null
                            }
                            {
                                area.hasOwnProperty('subareas') ?
                                    area.subareas.map((sub, i) => {
                                        return (
                                          <div className="subarea" key={i}>
                                            <b><p>{sub.title}</p></b>
                                            <ul>
                                                {sub.list.map((e,i) => <li key={i} dangerouslySetInnerHTML={{__html: e}}/>)}
                                            </ul>
                                          </div>
                                        )
                                    })
                                    : null
                            }
                            
                        </div>
                    )
                })}
            {
                data.hasOwnProperty('warning') ?
                    <p>
                        <sub>{data.warning}</sub>
                    </p>
                    : null
            }
            {
                data.hasOwnProperty('quantity') ?
                    <p>
                        <sub>{data.quantity}</sub>
                    </p>
                    : null
            }
            {
                data.hasOwnProperty('ref') ?
                    <p>
                        <sub>{data.ref}</sub>
                    </p>
                    : null
            }
        </div>
    );
};