import React from 'react'

function EnduranceIcon(props) {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"/>
                </clipPath>
                <path  fill={props.color} d="M84.07,70.42c-6.24,0-13.96-2.88-15.89-4.05c-0.67-0.41-2.25-1.41-4.32-2.7
                    c-6.05-3.81-18.65-11.74-20.17-12.25c-0.27-0.09-0.76-0.23-1.41-0.41c-8.13-2.26-16.45-5.18-16.45-9.6c0-0.41,0.12-0.52,0.93-1.23
                    c1.05-0.93,3.84-3.4,4.41-4.72c0.22-0.51,0.48-1.33,0.76-2.2c1.06-3.28,1.91-5.65,3.3-6.31c2.1-1,3.37-0.1,9.49,4.22
                    c6.12,4.32,7.69,3.47,7.71,3.46c4.05-5.3,7.91-7.5,10.89-6.22c0.71,0.3,1.1,1.27,2.35,4.56c2.51,6.63,7.18,18.96,16.49,24.24
                    c1.39,0.79,2.88,1.13,4.33,1.47c2.38,0.55,4.85,1.13,6.32,3.73c0.47,0.84,1.65,3.8-2.96,6.68C88.35,70.05,86.31,70.42,84.07,70.42
                     M27.56,41.74c0.43,2.39,5.53,4.96,15.19,7.65c0.69,0.19,1.2,0.33,1.49,0.43c1.58,0.53,11.01,6.42,20.53,12.42
                    c2.05,1.29,3.62,2.28,4.29,2.69c2.63,1.6,15.07,5.76,19.91,2.74c3.25-2.03,2.75-3.77,2.38-4.42c-1.1-1.96-2.92-2.38-5.23-2.92
                    c-1.49-0.35-3.18-0.74-4.78-1.65C71.51,53.11,66.7,40.4,64.11,33.58c-0.6-1.6-1.23-3.25-1.53-3.65c-2.17-0.82-5.36,1.24-8.76,5.69
                    c-0.78,1.01-3.05,1.88-10.05-3.07c-5.76-4.07-6.66-4.61-7.79-4.07c-0.84,0.4-1.87,3.59-2.42,5.3c-0.31,0.94-0.57,1.76-0.82,2.35
                    c-0.63,1.47-2.7,3.43-4.84,5.32C27.76,41.56,27.65,41.66,27.56,41.74 M62.67,29.97C62.67,29.97,62.68,29.97,62.67,29.97
                    C62.68,29.97,62.67,29.97,62.67,29.97"/>
                <path  fill={props.color} d="M81.21,73.03c-2.52,0-5.71-0.31-9.18-1.08c-4.75-1.06-15.23-6.73-21.5-10.11c-1.71-0.92-3.02-1.63-3.7-1.96
                    c-2.08-1.02-4.87-1.62-6.91-2.07c-0.83-0.18-1.53-0.34-2.02-0.48c-0.11-0.03-11.62-3.65-13.81-8.53c-1.65-3.68,0.63-6.87,2.06-8.03
                    c0.36-0.29,0.89-0.24,1.18,0.12c0.29,0.36,0.24,0.89-0.12,1.19c-0.13,0.11-3.12,2.62-1.59,6.03c1.51,3.38,9.99,6.8,12.74,7.61
                    c0.46,0.13,1.12,0.28,1.91,0.45c2.12,0.46,5.03,1.1,7.29,2.2c0.68,0.33,2.02,1.06,3.76,1.99c5.78,3.12,16.54,8.94,21.06,9.95
                    c5.83,1.3,10.71,1.25,12.79,0.64c1.86-0.54,5.2-4.1,5.55-4.6c0.26-0.38,0.78-0.49,1.17-0.24c0.38,0.25,0.5,0.76,0.25,1.15
                    c-0.28,0.45-3.99,4.58-6.5,5.31C84.67,72.86,83.13,73.03,81.21,73.03"/>
                <path  fill={props.color} d="M59.41,40.71c-0.28,0-0.55-0.14-0.71-0.39c-0.25-0.39-0.14-0.91,0.25-1.17c0.95-0.61,3.81-2.69,3.84-2.71
                    c0.37-0.27,0.9-0.19,1.18,0.19c0.27,0.38,0.19,0.9-0.19,1.18c-0.12,0.09-2.93,2.13-3.92,2.77C59.72,40.67,59.56,40.71,59.41,40.71"
                />
                <path  fill={props.color} d="M63.13,46c-0.28,0-0.55-0.14-0.71-0.39c-0.25-0.39-0.14-0.91,0.25-1.17c0.94-0.61,2.82-2.05,2.84-2.06
                    c0.37-0.28,0.9-0.22,1.18,0.15c0.29,0.37,0.22,0.9-0.15,1.18c-0.08,0.06-1.96,1.5-2.96,2.15C63.44,45.96,63.28,46,63.13,46"/>
                <path  fill={props.color} d="M66.85,51.46c-0.28,0-0.55-0.14-0.71-0.39c-0.25-0.39-0.14-0.91,0.25-1.17c0.94-0.61,2.36-1.69,2.37-1.7
                    c0.37-0.28,0.9-0.21,1.18,0.15c0.28,0.37,0.21,0.9-0.16,1.18c-0.06,0.05-1.48,1.14-2.49,1.78C67.16,51.42,67,51.46,66.85,51.46"/>
                <path  fill={props.color} d="M21.93,26.31H10.39c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.54c0.47,0,0.84,0.38,0.84,0.84
                    C22.77,25.93,22.4,26.31,21.93,26.31"/>
                <path  fill={props.color} d="M18.28,31.37H6.74c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.54c0.46,0,0.84,0.38,0.84,0.84
                    C19.12,30.99,18.74,31.37,18.28,31.37"/>
                <path  fill={props.color} d="M44.41,70.3H32.87c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.54c0.46,0,0.84,0.38,0.84,0.84
                    C45.25,69.92,44.88,70.3,44.41,70.3"/>
                <path  fill={props.color} d="M41.04,74.59H29.5c-0.47,0-0.84-0.38-0.84-0.84c0-0.47,0.38-0.84,0.84-0.84h11.54c0.46,0,0.84,0.38,0.84,0.84
                    C41.88,74.21,41.5,74.59,41.04,74.59"/>
            </g>
        </svg>
    )
}

export default EnduranceIcon;