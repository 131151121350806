import React from 'react';

function InjuryBadge(props) {

        let styling = props.badge ? 'active-badge' : 'inactive';
        const showSvg = props.badge ? 'initial':'none';
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" style={{display: showSvg}}
                 viewBox="0 0 400.8 325.3" xmlSpace="preserve">
                    <path className={styling} d="M129.3,42.6c13.4-3.3,26.6-6.5,39.9-9.8c0,2.5-0.1,4.8,0,7.1c0.2,2.7,0.4,5.5,1,8.2c3,12.3,14.2,21.9,26.7,23.2
	c16.4,1.6,30.6-8.8,34-24.8c0.4-1.9,0.5-3.8,0.6-5.7c0.1-2.6,0-5.2,0-7.9c4.1,1,8.1,2,12.2,3c8.8,2.2,17.6,4.3,26.4,6.5
	c1.1,0.3,1.4,0.7,1.4,1.8c-0.2,5.6-0.3,11.3-0.4,16.9c-0.3,11.1-0.5,22.1-0.8,33.2c-0.1,6.4-0.3,12.8-0.4,19.2c0,2-0.1,4.1-0.2,6.1
	c-0.1,0.8,0.2,1.4,0.8,2c2.7,2.9,5.4,5.8,8.1,8.7c0.4,0.4,0.7,1.2,0.7,1.8c0,7.3,0,14.6,0,21.8c0,0.5,0,0.9,0,1.6
	c5.2-0.8,10.2-1.5,15.4-2.2c0,10.2,0,20.3,0,30.5c-5.1,0.7-10.1,1.5-15.4,2.3c0,0.5,0,0.9,0,1.4c0,9.7,0,19.4,0,29.2
	c0,1-0.3,1.6-1.1,2.1c-9.6,6.2-19.2,12.4-28.8,18.6c-0.5,0.3-1.2,0.6-1.9,0.6c-32.2,0-64.4,0-96.7,0c-0.6,0-1.2-0.2-1.7-0.5
	c-9.1-6.3-18.1-12.7-27.2-19c-0.7-0.5-0.7-1-0.7-1.7c0-9.8,0-19.5,0-29.3c0-1-0.2-1.4-1.2-1.5c-4.2-0.5-8.3-1.2-12.4-1.8
	c-0.5-0.1-1-0.2-1.6-0.3c0-10.1,0-20.2,0-30.4c5,0.7,10,1.5,15.3,2.2c0-0.6,0-1,0-1.5c0-7.2,0-14.3,0-21.5c0-1,0.2-1.7,0.9-2.4
	c2.7-2.8,5.4-5.7,8-8.6c0.4-0.4,0.7-1.2,0.7-1.8c-0.2-11.3-0.5-22.7-0.8-34c-0.2-9.6-0.4-19.3-0.6-28.9
	C129.6,52,129.4,47.3,129.3,42.6z M267.8,157c0-0.3,0.1-0.4,0.1-0.5c0-6.8,0-13.5,0-20.3c0-0.5-0.3-1-0.7-1.4
	c-2.7-2.9-5.4-5.8-8.1-8.7c-0.8-0.8-1-1.6-1-2.7c0.2-8.3,0.5-16.6,0.7-24.9c0.2-7.7,0.3-15.3,0.5-23c0.1-3.4,0.2-6.9,0.2-10.3
	c0-0.3-0.2-0.8-0.5-0.9c-1.9-0.6-3.8-1.1-5.8-1.6c-0.8,3.4-1.7,6.7-2.5,10.1c-2.8-0.7-5.4-1.3-8.1-2c0.8-3.5,1.7-6.8,2.5-10.3
	c-2.1-0.6-4.1-1.1-6.1-1.6c-2.4,4.8-5.4,8.9-9.2,12.5c-13.6,13-34.9,15.4-51.1,5.7c-7.1-4.2-12.6-10-16.4-17.4
	c-0.2-0.3-0.7-0.7-1-0.7c-1.8,0.4-3.6,0.9-5.6,1.5c0.8,3.4,1.6,6.8,2.5,10.3c-2.8,0.7-5.4,1.3-8.1,2c-0.8-3.4-1.7-6.7-2.5-10
	c-1.7,0.5-3.3,1-5,1.3c-1,0.2-1.3,0.7-1.3,1.7c0.3,9.3,0.5,18.7,0.7,28c0.2,10.1,0.4,20.1,0.7,30.2c0,0.8-0.2,1.3-0.7,1.9
	c-2.8,3-5.6,5.9-8.3,8.9c-0.4,0.4-0.6,1.1-0.7,1.7c0,6.5,0,13,0,19.4c0,0.3,0,0.7,0.1,1.1c2.6,0.4,5.2,0.8,7.7,1.2
	c0,10.2,0,20.3,0,30.4c-2.6-0.4-5.2-0.7-7.7-1.1c-0.1,0.3-0.1,0.4-0.1,0.5c0,7.6,0,15.2,0,22.8c0,0.4,0.4,0.9,0.7,1.2
	c6.4,4.5,12.7,9,19.1,13.4c0.5,0.3,1.2,0.5,1.7,0.5c29.9,0,59.7,0,89.6,0c0.6,0,1.2-0.2,1.7-0.5c7-4.5,14-9.1,21-13.6
	c0.6-0.4,0.9-0.8,0.9-1.6c0-7.2,0-14.4,0-21.6c0-0.4,0-0.7-0.1-1.2c-2.7,0.4-5.2,0.7-7.8,1.1c0-10.2,0-20.3,0-30.5
	C262.6,157.7,265.2,157.4,267.8,157z M259.7,55.8c0-0.8,0-1.4,0-2.1c0-2.1,0-2.1-2.1-2.7c-4.9-1.2-9.9-2.4-14.9-3.7
	c-0.3,1.2-0.5,2.4-0.8,3.6C247.9,52.6,253.7,54.2,259.7,55.8z M141.3,55.8c5.9-1.6,11.7-3.2,17.6-4.8c-0.3-1.2-0.5-2.4-0.8-3.5
	c-0.5,0.1-0.9,0.2-1.3,0.3c-4,1-8,2-12.1,3c-3.8,0.9-3.8,0.9-3.5,4.8C141.3,55.6,141.3,55.7,141.3,55.8z"/>
                    <path className={styling}
                          d="M126.8,298.1c-20.6,3.1-39.4-0.9-56.8-12.1c4.3-1.3,18.7-1.9,29.5,0.4C108.1,288.3,121.9,294,126.8,298.1z"/>
                    <path className={styling} d="M320.6,262c-0.9-11.1,0-21.9,3.5-32.3c2.8-8.5,6.9-16.4,12.4-23.5c0.9,8.3,0.4,16.5-1.3,24.6
	C332.4,244.2,325,256.7,320.6,262z"/>
                    <path className={styling}
                          d="M366.7,258.9c-16.1,11.8-35.7,17.2-56.5,14.8c8.3-5.9,17.1-10.2,26.8-12.8C346.7,258.3,356.6,257.8,366.7,258.9z"/>
                    <path className={styling} d="M34.4,259c20.5-2.3,39.1,2.6,55.9,14.6C69.8,275.9,51.2,271,34.4,259z"/>
                    <path className={styling}
                          d="M13.5,101.5c10.2,11.4,16.8,39.7,13.2,56.7C15.1,140.9,10.8,122.2,13.5,101.5z"/>
                    <path className={styling} d="M64.9,51.6c7.1-19.4,19.7-33.9,38-43.5C95.8,27.4,83.2,41.9,64.9,51.6z"/>
                    <path className={styling}
                          d="M374.2,158c-2.9-20.4,1.5-39.2,13-56.3c1.5,9.9,1.2,19.7-1.1,29.4C384,140.8,379.9,149.7,374.2,158z"/>
                    <path className={styling}
                          d="M364.6,178.8c-13.6-15.5-20.1-33.6-19.8-54.2C358.4,140.1,365,158.2,364.6,178.8z"/>
                    <path className={styling}
                          d="M350.5,221.4c-9-18.6-10.7-37.8-5-57.6c4.4,8.9,7.2,18.3,8.1,28.3C354.5,202,353.4,211.8,350.5,221.4z"/>
                    <path className={styling}
                          d="M354.8,86.5c-19.4-7-33.9-19.6-43.6-37.9C330.6,55.6,345.1,68.3,354.8,86.5z"/>
                    <path className={styling}
                          d="M371.8,51.8c7.1,19.7,6.7,39-1,58.2c-3.5-9.4-5.3-19-5.1-29C365.9,70.9,368,61.3,371.8,51.8z"/>
                    <path className={styling}
                          d="M330.8,285.9c-8.7,5.8-18.2,9.8-28.4,11.8c-9.3,1.8-18.7,1.9-28.1,0.3C291.6,286.7,310.4,282.7,330.8,285.9z"/>
                    <path className={styling} d="M18.4,213.4c8.1,2.5,15.6,6.2,22.5,11.2c9.4,6.7,16.7,15.2,22.5,25.2c-9.5-3-18.2-7.5-25.9-13.8
	C29.7,229.7,23.4,222.1,18.4,213.4z"/>
                    <path className={styling}
                          d="M392.6,161c-3.7,20.3-13.7,36.8-30.2,49.7c1.9-10.1,5.2-19.3,10.4-27.9C377.9,174.3,384.6,167.1,392.6,161z"/>
                    <path className={styling} d="M59.8,17.4c1.3,20.6-4.4,38.9-17.2,55.1C41.3,51.9,47,33.6,59.8,17.4z"/>
                    <path className={styling}
                          d="M50.4,221.3c-5.7-19.8-4.1-39,4.9-57.6C61.1,183.6,59.4,202.8,50.4,221.3z"/>
                    <path className={styling}
                          d="M106.8,313.5c18.7-8.7,37.9-10.1,57.6-4.1C145.8,318.2,126.6,319.5,106.8,313.5z"/>
                    <path className={styling} d="M29.1,51.8c7.7,19.5,8.1,38.8,1,58.2C22.3,90.8,22,71.5,29.1,51.8z"/>
                    <path className={styling}
                          d="M38.5,210.5c-13.4-8-29.1-33.6-30.3-49.7C24.6,173.6,34.6,190.1,38.5,210.5z"/>
                    <path className={styling}
                          d="M110.3,284.6c-11.8-9.6-23.1-37.8-21.8-54c6.9,7.5,12.3,15.7,16,24.9C108.3,264.8,110,274.4,110.3,284.6z"/>
                    <path className={styling}
                          d="M236.4,309.4c19.7-6,39-4.7,58,4.1c-9.9,3-19.6,4.2-29.5,3.5C254.9,316.4,245.5,313.8,236.4,309.4z"/>
                    <path className={styling}
                          d="M338.7,87.8c8.5,6.6,15.5,14.4,20.7,23.8c4.5,8,7.4,16.6,8.9,25.8C352.1,124.7,342.2,108.1,338.7,87.8z"/>
                    <path className={styling}
                          d="M382.4,213.4c-10.3,17.9-25.3,30-44.9,36.4C347.8,231.9,362.8,219.8,382.4,213.4z"/>
                    <path className={styling}
                          d="M290.8,284.4c0.3-20.6,7.5-38.4,21.6-53.5C312,251.5,304.8,269.3,290.8,284.4z"/>
                    <path className={styling}
                          d="M297.9,7.9c18.3,9.8,31,24.3,38.2,43.7c-4.3-1-17.7-11.5-23.4-18C305.1,24.8,299.2,12.9,297.9,7.9z"/>
                    <path className={styling}
                          d="M32.6,137.4C36.1,117,46,100.5,62.1,87.7c0.5,3.9-5.1,19.8-9.9,27.8C47.1,124,40.5,131.3,32.6,137.4z"/>
                    <path className={styling}
                          d="M341.1,17.4c12.8,16.2,18.5,34.5,17.2,55.1C345.5,56.4,339.8,38,341.1,17.4z"/>
                    <path className={styling}
                          d="M89.8,48.4C84.6,62.6,61,83.2,46.2,86.5c0.6-3.7,11.3-17.4,18-23.2C71.7,56.8,80.2,52,89.8,48.4z"/>
                    <path className={styling} d="M148.2,301.1c-9.3-6.2-17.2-13.8-23.2-23.2c-4.6-7.1-10.1-20.3-10.1-24.1c9.4,6.4,17.3,14.1,23.3,23.7
	C142.8,284.7,146.2,292.6,148.2,301.1z"/>
                    <path className={styling}
                          d="M64.4,206.2c12.4,16.5,17.6,35,15.8,55.9c-6.1-8.2-10.6-16.9-13.3-26.5C64.2,226,63.4,216.2,64.4,206.2z"/>
                    <path className={styling}
                          d="M56,124.6c0.4,20.6-6.2,38.7-19.8,54.2c-0.3-10,1.2-19.7,4.6-29C44.3,140.4,49.4,132.1,56,124.6z"/>
                    <path className={styling}
                          d="M252.7,301c5.1-20,16.2-35.7,33.3-47.1C281,273.9,269.9,289.6,252.7,301z"/>
                    <path className={styling}
                          d="M154.2,107.4c0-3.3,0-6.5,0-9.8c30.8,0,61.6,0,92.5,0c0,3.3,0,6.5,0,9.8C215.9,107.4,185.1,107.4,154.2,107.4z"/>
                    <path className={styling}
                          d="M246.7,118.2c0,3.3,0,6.5,0,9.8c-30.8,0-61.6,0-92.5,0c0-3.3,0-6.5,0-9.8C185.1,118.2,215.9,118.2,246.7,118.2z"/>
                    <path className={styling}
                          d="M154.2,148.6c0-3.3,0-6.5,0-9.9c30.8,0,61.6,0,92.5,0c0,3.3,0,6.5,0,9.9C215.9,148.6,185.1,148.6,154.2,148.6z"/>
                    <path className={styling}
                          d="M154.2,169.2c0-3.3,0-6.5,0-9.8c30.8,0,61.6,0,92.4,0c0,3.2,0,6.5,0,9.8C215.9,169.2,185.1,169.2,154.2,169.2z"/>
                    <path className={styling}
                          d="M246.7,180c0,3.3,0,6.5,0,9.8c-30.8,0-61.6,0-92.4,0c0-3.2,0-6.5,0-9.8C185,180,215.8,180,246.7,180z"/>
                    <path className={styling}
                          d="M246.7,200.6c0,3.3,0,6.5,0,9.8c-30.9,0-61.6,0-92.5,0c0-3.3,0-6.5,0-9.8C185,200.6,215.8,200.6,246.7,200.6z"/>
            </svg>
        )
}

export default InjuryBadge;