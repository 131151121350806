import React from 'react'

function OuterBadge(props) {
    
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 400.6 325.2" xmlSpace="preserve">
            <g fill={props.color}>
                <path
                    d="M106.5,313.4c9.7,2.9,19.5,4.2,29.4,3.5c10-0.7,19.4-3.3,28.6-7.6C149,303.1,119.5,305.2,106.5,313.4z"/>
                <path
                    d="M338.5,87.7c1.6,9.8,4.9,19,10,27.6c5.1,8.6,11.7,15.9,19.6,22C364.6,116.9,354.8,100.4,338.5,87.7z"/>
                <path
                    d="M8.1,160.9c1.7,9.8,5,19.1,10.2,27.6c5.2,8.5,11.8,15.7,19.8,21.8C34.5,189.9,24.5,173.5,8.1,160.9z"/>
                <path d="M28.9,51.6c-7.1,19.7-6.7,39,1,58.2C37,90.4,36.7,71.1,28.9,51.6z"/>
                <path
                    d="M337.4,249.6c19.6-6.4,34.6-18.5,44.9-36.4C362.6,219.6,347.7,231.7,337.4,249.6z"/>
                <path
                    d="M294,313.3c-6.5-3.6-20.1-7-28.6-7.6c-11.3-0.9-26.1,2-29.1,3.6C249.9,317.5,280.4,319.7,294,313.3z"/>
                <path
                    d="M88.4,230.7c0.1,10,1.9,19.6,5.6,28.8c3.7,9.3,9.1,17.5,16,24.7C109.6,263.7,102.5,245.8,88.4,230.7z"/>
                <path
                    d="M312.2,230.7c-14.1,15.1-21.3,32.9-21.6,53.5C304.7,269.2,311.8,251.3,312.2,230.7z"/>
                <path
                    d="M32.3,137.3c4.8-3,14.3-12.6,19.8-22c4.7-8,10.4-23.7,9.9-27.8C45.8,100.4,35.9,116.9,32.3,137.3z"/>
                <path d="M335.8,51.4c-7.1-19.4-19.8-33.9-38-43.5C304.9,27.3,317.6,41.8,335.8,51.4z"/>
                <path d="M45.9,86.3c9.3-3.3,17.8-8.1,25.2-14.5c7.6-6.6,13.7-14.4,18.4-23.4c-9.5,3.4-18,8.2-25.6,14.8
	C56.4,69.8,50.5,77.5,45.9,86.3z"/>
                <path
                    d="M340.9,17.2c-0.7,9.9,0.3,19.7,3.3,29.2c3,9.5,7.7,18.1,14,25.9C359.4,51.8,353.7,33.4,340.9,17.2z"/>
                <path d="M64.3,206c-1.9,20.6,3.4,39.1,15.8,55.9C81.9,241.1,76.6,222.5,64.3,206z"/>
                <path d="M55.9,124.4C42.3,140,35.7,158,36.1,178.6C49.6,163.1,56.2,145,55.9,124.4z"/>
                <path d="M148,300.9c-5.1-20-16.2-35.7-33.4-47.1C119.7,273.8,130.8,289.5,148,300.9z"/>
                <path
                    d="M285.9,253.8c-17.1,11.4-28.3,27.1-33.3,47.1C269.7,289.5,280.8,273.8,285.9,253.8z"/>
                <path
                    d="M320.6,261.6c-1.9-20.5,3.3-39.1,15.7-55.6c1,9.9,0.2,19.7-2.5,29.3C331.1,244.9,326.6,253.7,320.6,261.6z"/>
                <path d="M126.4,297.8C106.1,301,87.2,297,70,285.7C90.3,282.5,109.2,286.5,126.4,297.8z"/>
                <path
                    d="M26.5,157.8c-11.6-17.1-15.9-35.8-13.1-56.2c5.7,8.2,9.7,17.1,12,26.8C27.7,138.1,27.9,147.9,26.5,157.8z"/>
                <path
                    d="M366.3,258.8c-16.8,12-35.4,16.9-55.9,14.6C327.2,261.4,345.8,256.5,366.3,258.8z"/>
                <path
                    d="M90.2,273.5c-20.5,2.4-40.1-3-56-14.6c4.6-1.2,18.7-1,29.3,1.8C74.5,263.5,86,269.9,90.2,273.5z"/>
                <path
                    d="M344.7,124.4c13.6,15.5,20.2,33.6,19.8,54.2C350.9,163.1,344.3,145,344.7,124.4z"/>
                <path
                    d="M350.4,221.6c-9.1-18.9-10.7-38.1-5-58c4.4,8.9,7.2,18.3,8.1,28.3C354.3,201.8,353.2,211.6,350.4,221.6z"/>
                <path
                    d="M63.2,249.7c-19.6-6.4-34.6-18.5-44.9-36.4c9.5,3,18.2,7.5,26,13.8C52,233.4,58.3,241,63.2,249.7z"/>
                <path d="M102.7,7.9c-7.1,19.4-19.7,33.9-38,43.5C71.8,32,84.5,17.5,102.7,7.9z"/>
                <path
                    d="M330.6,285.7c-17.3,11.3-36.1,15.3-56.5,12.2c2.6-3.1,18.5-9.9,27.1-11.7C310.9,284.2,320.7,284.1,330.6,285.7z"/>
                <path d="M311.1,48.5c19.4,7,33.9,19.7,43.6,37.9C335.2,79.3,320.7,66.7,311.1,48.5z"/>
                <path d="M371.6,51.6c7.1,19.8,6.7,39-1,58.2C363.6,90.4,363.9,71.1,371.6,51.6z"/>
                <path d="M387.1,101.6c1.3,8.6,1.2,17.1-0.3,25.6c-2,11.1-6.4,21.3-12.6,30.7c-1.4-4.4-1.5-19.3,0.9-29.5
	C377.5,118.8,381.4,109.8,387.1,101.6z"/>
                <path
                    d="M50.2,221.2c-5.7-19.8-4.1-39,4.9-57.5c2.9,9.5,4,19.3,3.1,29.2C57.4,202.8,54.7,212.2,50.2,221.2z"/>
                <path d="M42.4,72.3c-1.3-20.6,4.4-38.9,17.2-55.1C61,37.8,55.2,56.2,42.4,72.3z"/>
                <path
                    d="M392.6,160.8c-1.7,9.4-4.8,18.1-9.5,26.3c-5.3,9.2-12.3,16.8-20.7,23.4C364.2,193.2,379.3,168.4,392.6,160.8z"/>
                <path
                    d="M106.5,313.4c13-8.3,42.5-10.4,58.1-4.1c-9.2,4.3-18.7,6.9-28.6,7.6C125.9,317.6,116.2,316.4,106.5,313.4z"/>
            </g>
        </svg>
    )
}

export default OuterBadge;