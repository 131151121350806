import React from 'react';

function RecoveryBadge(props) {

        let styling = props.badge ? 'active-badge' : 'inactive';
        const showSvg = props.badge ? 'initial':'none';

    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px" style={{display: showSvg}}
             viewBox="0 0 401 325.4" xmlSpace="preserve">
            <path className={ styling } d="M167.2,29.7c21.5,0.8,42.8,1.5,64.4,2.3c-10.1,22.7-20.2,45.3-30.3,68c4,3.1,7.9,6.1,11.9,9.1c7,5.4,13.9,10.7,20.9,16.1
	c0.6,0.5,0.8,0.8,0.5,1.6c-17.7,50.1-35.4,100.2-53.1,150.3c-0.1,0.4-0.3,0.7-0.6,1.1c0-0.3,0-0.6,0-0.8c0.7-7.7,1.3-15.4,2-23.2
	c0.7-7.8,1.4-15.7,2-23.5c0.8-8.8,1.5-17.5,2.3-26.3c0.7-7.8,1.4-15.6,2-23.4c0.7-7.9,1.4-15.8,2.1-23.6c0.3-2.9,0.5-5.9,0.8-8.8
	c0.1-0.8-0.3-1-0.9-1.3c-10.5-5.3-20.9-10.6-31.4-15.9c-0.8-0.4-1.2-0.8-1.1-1.8c0.7-8.3,1.4-16.6,2.1-24.8c0.7-8.1,1.3-16.2,2-24.3
	c0.7-8.1,1.4-16.2,2.1-24.4c0.7-8.1,1.3-16.2,2-24.3C167,31.1,167.1,30.4,167.2,29.7z M200.3,188.2c0.1,0,0.1,0,0.2,0
	c0.1-0.3,0.2-0.5,0.3-0.8c6.6-18.8,13.3-37.6,20-56.4c0.3-0.9,0.1-1.3-0.6-1.8c-8.5-6.5-16.9-13-25.4-19.5c-2.6-2-5.2-4-7.9-6.1
	c9-20.2,18-40.4,27-60.7c-12.2-0.4-24.1-0.9-36.1-1.3c-0.1,1.7-0.3,3.1-0.4,4.6c-0.8,9.2-1.5,18.3-2.3,27.5c-0.7,8-1.3,16-2,24
	c-0.7,8.3-1.4,16.6-2.1,24.8c-0.1,1,0.2,1.4,1.1,1.8c10.3,5.2,20.6,10.4,30.9,15.6c1.1,0.6,1.5,1.2,1.4,2.5c-0.4,3.3-0.6,6.7-0.9,10
	c-0.7,7.9-1.4,15.8-2.1,23.6C201,180.1,200.7,184.1,200.3,188.2z"/>
            <path className={ styling } d="M236.3,220.9c0.7-1.4,1.3-2.9,2-4.3c7.5-16.1,15-32.2,22.5-48.3c0.4-0.8,0.3-1.3-0.2-1.9c-4.1-5.8-8.1-11.7-12.1-17.6
	c-0.3-0.4-0.4-1.1-0.3-1.5c5.6-20.9,11.2-41.7,16.8-62.6c0-0.1,0.1-0.3,0.2-0.7c8.4,6.9,16.7,13.8,25.1,20.7c-1.1,2-2.1,3.9-3.2,5.9
	c-5,9.2-9.9,18.3-14.9,27.5c-0.4,0.7-0.4,1.3,0,1.9c4.1,7.2,8.3,14.5,12.4,21.7c0.4,0.6,0.5,1-0.1,1.7c-15.9,19-31.8,38-47.7,57
	c-0.1,0.2-0.3,0.3-0.5,0.5C236.4,220.9,236.4,220.9,236.3,220.9z"/>
            <path className={ styling } d="M158.1,217.6c-1.8-5.1-3.5-10.2-5.3-15.4c-3.6-10.5-7.2-20.9-10.8-31.4c-0.3-0.8-0.7-1.1-1.5-1.1
	c-6.6-0.1-13.2-0.3-19.8-0.4c-0.6,0-1-0.2-1.2-0.8c-6.4-18.7-12.8-37.3-19.2-56c-0.1-0.2-0.1-0.4-0.2-0.7
	c9.9-3.4,19.7-6.8,29.8-10.3c0,3,0,5.9,0,8.8c0,3.4-0.1,6.9-0.1,10.3c0,8.1-0.1,16.2-0.1,24.2c0,1,0,1.9-0.1,2.9
	c-0.1,0.8,0.2,1.1,1,1.3c7.4,1.5,14.8,3.2,22.1,4.7c0.8,0.2,1.1,0.5,1.2,1.3c0.6,9.4,1.3,18.9,2,28.3c0.8,10.7,1.5,21.4,2.3,32.2
	c0,0.7,0.1,1.3,0.1,2C158.3,217.6,158.2,217.6,158.1,217.6z"/>
            <path className={ styling } d="M274.2,298.1c17.3-11.3,36.1-15.3,56.4-12C313.3,297.3,294.5,301.3,274.2,298.1z"/>
            <path className={ styling } d="M164.2,309.6c-18.7,8.7-37.8,10.1-57.5,4.1C125.4,304.9,144.5,303.6,164.2,309.6z"/>
            <path className={ styling }
                  d="M236.4,309.6c11.2-3.5,22.5-4.6,34.1-3c10.1,1.4,20.9,5.4,23.6,7.2C274.2,319.7,255,318.4,236.4,309.6z"/>
            <path className={ styling } d="M126.5,298.1c-10.9,1.8-21.8,1.5-32.5-1.3c-8.6-2.2-16.6-5.8-24-10.8c9.9-1.7,19.7-1.5,29.5,0.6
	C109.2,288.7,118.2,292.6,126.5,298.1z"/>
            <path className={ styling } d="M55.2,163.9c5.7,19.8,4.1,39-4.9,57.6C44.5,201.7,46.2,182.5,55.2,163.9z"/>
            <path className={ styling }
                  d="M28.9,52.1c7.6,14.4,8.1,44.9,1,58.2c-3.8-9.4-5.8-19-6-29C23.7,71.3,25.4,61.6,28.9,52.1z"/>
            <path className={ styling }
                  d="M320.6,261.9c-1.9-20.5,3.3-39.1,15.7-55.6c1,9.9,0.2,19.7-2.5,29.3C331.2,245.2,326.7,254,320.6,261.9z"/>
            <path className={ styling } d="M374.1,158.2c-2.8-20.4,1.5-39.2,13-56.3C390,122.3,385.7,141,374.1,158.2z"/>
            <path className={ styling } d="M354.7,86.7c-19.4-7-33.9-19.6-43.6-37.9C330.5,55.8,345,68.4,354.7,86.7z"/>
            <path className={ styling }
                  d="M344.5,124.5c11.5,10,21.6,37.6,20,54.7c-6.7-7.7-11.8-16.1-15.2-25.4C345.9,144.4,344.5,134.7,344.5,124.5z"/>
            <path className={ styling } d="M45.9,86.7C51,77,57.6,68.7,66,61.8c7.1-5.7,14.9-10.1,23.5-13.1C84.9,57.6,78.9,65.4,71.4,72
	C63.9,78.5,55.4,83.3,45.9,86.7z"/>
            <path className={ styling } d="M32.5,137.5c1.2-8.1,3.8-15.8,7.5-23.1c5.3-10.5,12.8-19.2,22.1-26.6c-0.1,4.5-4,18.1-9.9,27.8
	C46.9,124.1,40.4,131.4,32.5,137.5z"/>
            <path className={ styling } d="M26.5,158.2c-11.5-17.1-15.9-35.8-13.1-56.3C25.1,119,29.4,137.7,26.5,158.2z"/>
            <path className={ styling }
                  d="M59.7,17.5c1.3,20.6-4.4,39-17.2,55.2c-0.7-9.9,0.3-19.7,3.3-29.2C48.7,33.9,53.4,25.3,59.7,17.5z"/>
            <path className={ styling }
                  d="M371.7,52.3c7.1,19.4,6.7,38.7-1,57.8c-3.5-9.3-5.3-19-5.1-28.9C365.7,71.2,367.9,61.6,371.7,52.3z"/>
            <path className={ styling }
                  d="M90.2,273.8c-20.5,2.3-39.1-2.6-55.9-14.6c9.9-1.2,19.7-0.6,29.3,1.9C73.3,263.5,82.1,267.9,90.2,273.8z"/>
            <path className={ styling }
                  d="M338.6,88c16.2,12.7,26.1,29.2,29.6,49.6c-7.9-6.1-14.5-13.4-19.6-21.9C343.4,107.1,340.2,97.8,338.6,88z"/>
            <path className={ styling } d="M310.4,273.8c16.7-12,35.4-16.9,55.9-14.6C349.6,271.2,331,276,310.4,273.8z"/>
            <path className={ styling } d="M110,284.6c-7.8-8.3-13.7-17.7-17.4-28.4c-2.8-8.2-4.2-16.6-4.2-25.2c6.3,6.6,11.3,14,15,22.3
	C107.8,263.3,109.9,273.8,110,284.6z"/>
            <path className={ styling }
                  d="M148,301.1c-9-5.9-16.6-13.3-22.6-22.2c-5.7-8.5-10.1-20.4-10.8-25C131.8,265.5,142.9,281.2,148,301.1z"/>
            <path className={ styling } d="M392.4,161.2c-3.7,20.3-13.7,36.8-30,49.4C366.1,190.3,376.1,173.8,392.4,161.2z"/>
            <path className={ styling } d="M345.4,163.9c9,18.6,10.7,37.7,5,57.6C341.4,203,339.7,183.8,345.4,163.9z"/>
            <path className={ styling } d="M382.3,213.6c-10.3,17.9-25.3,30-44.9,36.4C347.7,232.1,362.6,220,382.3,213.6z"/>
            <path className={ styling } d="M290.6,284.6c0-8.7,1.5-17.2,4.3-25.4c3.5-10.1,10.9-22.2,17.5-28.4c-0.2,10.2-2,19.8-5.7,29.1
	C302.9,269.1,297.5,277.3,290.6,284.6z"/>
            <path className={ styling }
                  d="M38.2,210.5c-16.3-12.6-26.4-29-30.1-49.4c8,6,14.6,13.2,19.8,21.7C33.1,191.4,36.5,200.7,38.2,210.5z"/>
            <path className={ styling }
                  d="M252.5,301.5c2.3-15.9,19-39.6,33.6-47.5c-2.5,9.9-6.5,18.8-12.3,27C268.1,289,260.9,295.7,252.5,301.5z"/>
            <path className={ styling } d="M63.5,250.2c-8.7-2.8-16.5-6.8-23.6-12c-9-6.6-16-14.9-21.6-24.6c9.5,3,18.2,7.5,25.9,13.8C52,233.7,58.3,241.3,63.5,250.2z
	"/>
            <path className={ styling } d="M358.1,72.6c-12.8-16.1-18.5-34.5-17.2-55C353.7,33.7,359.5,52.1,358.1,72.6z"/>
            <path className={ styling }
                  d="M297.7,8c18.3,9.8,31,24.3,38.2,43.7c-5-1.9-16.4-9.9-23.4-18.1C306.3,26.3,299.3,14.3,297.7,8z"/>
            <path className={ styling } d="M55.9,124.8c0.4,20.6-6.2,38.6-19.8,54.2C35.7,158.4,42.4,140.3,55.9,124.8z"/>
            <path className={ styling } d="M80,261.9c-5-6.5-8.8-13.5-11.6-21.2c-4.1-11.1-5.2-22.6-4.1-34.5c6,7.9,10.5,16.6,13.2,26.2C80.3,242.2,80.9,252,80,261.9z
	"/>
            <path className={ styling }
                  d="M102.8,8c-3.3,15-22.9,37.5-38.3,43.9c3.5-9.5,8.4-18.1,15-25.6C86.1,18.8,93.9,12.8,102.8,8z"/>
        </svg>

    )
}

export default RecoveryBadge;