import React from 'react';

export const Calcium = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M24.6,77c0,0.4,0.1,0.7,0.4,1s0.6,0.4,1,0.4l7.3,0v-0.7c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2v0.7H60v-0.7
		c0-1.6,1.3-2.9,2.9-2.9c1.5,0,2.7,1.1,2.9,2.6v1h16.2c0.8,0,1.4-0.6,1.4-1.4v-2.3h-0.8c-1.4,0-2.6-1.2-2.6-2.6
		c0-1.4,1.2-2.6,2.6-2.6h0.8l0-8.3l-0.8,0h0c-1,0-1.7-0.8-1.7-1.8s0.8-1.8,1.8-1.8h0.8v-7.2c0-0.3-0.2-0.6-0.5-0.8l-20-11.2
		l-0.4,0.8c0,0,0,0,0,0.1c-0.2,0.4-0.6,0.7-1,0.8c-0.5,0.1-0.9,0.1-1.4-0.1c-0.4-0.2-0.7-0.6-0.9-1.1c-0.1-0.5-0.1-0.9,0.1-1.4
		l0.4-0.7l-13.1-7.4c-1.7-0.9-3.8-0.8-5.4,0.2l-0.2,0.2c-7.3,4.8-12.6,11-16,19.1l-0.4,1L24.6,77z M80.1,50.1l-25.4-0.5
		c-0.2-1.4-1.6-2.5-3.2-2.5c-1.6,0-2.9,1-3.2,2.4l-21.5-0.4c3.3-7.3,8.5-13.4,15.2-17.8l0.2-0.2c1.2-0.8,2.7-0.8,3.9-0.2l11.8,6.7
		c-0.2,0.6-0.2,1.3,0,1.9c0.3,0.8,0.8,1.5,1.6,2c0.5,0.3,1,0.4,1.6,0.4c0.3,0,0.6,0,0.9-0.1c0.6-0.2,1.2-0.5,1.6-1L80.1,50.1z
		 M51.4,48.7c1,0,1.8,0.7,1.8,1.4c0,1-0.8,1.8-1.8,1.8c-1,0-1.8-0.8-1.8-1.8C49.6,49.3,50.4,48.7,51.4,48.7z M26.1,77l0.1-26.8
		L48.3,51c0.4,1.4,1.7,2.4,3.1,2.4c1.4,0,2.7-0.9,3.1-2.2l27.3,0.5v4.9c-1.5,0.3-2.5,1.6-2.5,3.2c0,1.5,1.1,2.8,2.5,3.2l0,5.4
		c-1.9,0.3-3.4,2-3.4,4s1.5,3.7,3.4,4V77H67.2c-0.4-2.1-2.2-3.6-4.3-3.6c-2.1,0-3.9,1.6-4.3,3.6H39.1c-0.3-1.7-1.8-2.9-3.6-2.9
		c-1.8,0-3.3,1.3-3.6,2.9L26.1,77z"/>
                <path d="M64.5,60.8c0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6c0,1.4-1.2,2.6-2.6,2.6S64.5,62.2,64.5,60.8z M66,60.8
		c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1S66,60.2,66,60.8z"/>
                <path d="M51.1,69.3c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6C52.7,73,51.1,71.3,51.1,69.3z M52.6,69.3
		c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1c0-1.2-1-2.1-2.1-2.1C53.5,67.2,52.6,68.1,52.6,69.3z"/>
                <path d="M39.8,59.1c0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6s-1.2,2.6-2.6,2.6S39.8,60.6,39.8,59.1z M41.3,59.1
		c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1S41.3,58.6,41.3,59.1z"/>
                <path d="M38.6,40.8c0-1.7,2.2-3.1,5.1-3.1s5.1,1.3,5.1,3.1c0,1.7-2.2,3.1-5.1,3.1S38.6,42.6,38.6,40.8z M40.1,40.8
		c0,0.6,1.4,1.6,3.6,1.6s3.6-0.9,3.6-1.6s-1.4-1.6-3.6-1.6S40.1,40.2,40.1,40.8z"/>
                <path d="M29.9,65.5c0-2.2,1.8-4.1,4.1-4.1s4.1,1.8,4.1,4.1c0,2.2-1.8,4.1-4.1,4.1S29.9,67.8,29.9,65.5z M31.4,65.5
		c0,1.4,1.1,2.6,2.6,2.6s2.6-1.1,2.6-2.6c0-1.4-1.1-2.6-2.6-2.6S31.4,64.1,31.4,65.5z"/>
            </g>
        </svg>
    );
};