import React from 'react'

function MuscleBadge(props) {

    let styling = props.badge ? 'active-badge' : 'inactive';
    const showSvg = props.badge ? 'initial':'none';

    return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" style={{display: showSvg}}
                 viewBox="0 0 400.6 325.2" xmlSpace="preserve">
                    <path className={styling} d="M166,80.1c1.4,0.6,2.7,1.1,4.2,1.7c3.8-7.5,7.6-15,11.4-22.5c7.2,3.5,14.2,7,21.5,10.6c0.8-1.3,1.5-2.7,2.3-4.1
	c10.5,6.4,20.9,12.8,31.5,19.2c-0.7,1.1-1.4,2.2-2.2,3.3c6.7,5.8,13.4,11.6,20.2,17.5c-0.8,0.9-1.5,1.8-2.3,2.8
	c4.8,5.7,9.6,11.4,14.5,17.2c-1.6,1.5-3.1,2.9-4.7,4.3c-1.3,1.2-2.6,2.3-3.9,3.6c-0.4,0.4-0.7,1-0.7,1.5c0,2.6,0.2,5.3,0.3,7.9
	c0,0.7-0.1,1.4-0.4,2.1c-5.7,12.5-11.4,25-17,37.5c-0.3,0.7-0.5,1.6-0.5,2.4c0.4,12.6,0.8,25.2,1.3,37.9c0.5,14.4,1.1,28.8,1.6,43.2
	c0,0.3,0,0.6,0,1.1c-3.6,0.1-7.1,0.3-10.8,0.4c-0.1-2.4-0.2-4.6-0.3-6.9c-0.5-12.9-0.9-25.7-1.4-38.6c-0.4-12.3-0.8-24.5-1.3-36.8
	c-0.1-2.6,0.4-4.9,1.5-7.2c4.8-10.4,9.5-20.9,14.3-31.4c0.2-0.4,0.3-0.8,0.3-1.3c-3.2,2.9-6.4,5.8-9.6,8.8c-0.5,0.5-0.9,1.1-1.8,0.5
	c-0.2-0.1-0.5,0-0.7,0c-3.8,0-7.6,0-11.4-0.1c-1,0-1.5,0.3-1.9,1.2c-3.4,6.4-6.8,12.9-10.2,19.3c-0.1,0.3-0.3,0.5-0.6,1
	c-0.7-2.3-1.3-4.4-1.8-6.6c-1.7-7.2-4.3-14-8.4-20.3c-5.4-8.2-12.2-14.6-21.7-17.9c-2.5-0.8-5-1.4-7.5-2.1c-0.3-0.1-0.6-0.2-1-0.3
	c2.5-6.2,4.9-12.3,7.4-18.5c1.2,0.3,2.3,0.7,3.5,1c3.9,1.2,7.9,2.1,12,1.6c1.6-0.2,3.4-0.8,4.8-1.6c2.1-1.3,4-2.9,6.2-4.6
	c-10.9-4.5-21.5-8.9-32.2-13.3c-7.5,12.6-15.1,25.2-22.6,37.9c2.9,3.7,5.7,7.4,8.6,11.1c9.6,12.4,19.1,24.7,28.6,37.1
	c0.3,0.4,0.5,1.1,0.5,1.6c-0.9,9.8-1.9,19.7-2.8,29.5c-0.9,9.5-1.8,18.9-2.7,28.4c-0.8,8.6-1.7,17.3-2.5,25.9
	c-0.3,3.1-0.6,6.3-0.9,9.6c-3.6-0.3-7.1-0.7-10.7-1c0.2-2.2,0.4-4.4,0.6-6.5c0.8-7.9,1.5-15.8,2.3-23.7c0.9-8.8,1.7-17.6,2.6-26.4
	c0.8-8.6,1.6-17.1,2.5-25.7c0.2-2.1,0.4-4.3,0.6-6.4c0.1-0.8-0.1-1.4-0.6-2.1c-12.8-16.6-25.6-33.1-38.4-49.7
	c-0.4-0.6-0.6-1-0.2-1.7c10.1-16.9,20.2-33.9,30.4-50.8C165.7,80.5,165.9,80.3,166,80.1z M229.1,96.7c-5.6,8.3-11,16.6-16.6,24.9
	c2.9,2.8,5.8,5.4,8.7,8.1c6.6-7.7,13.1-15.2,19.6-22.9C236.9,103.4,233.1,100.1,229.1,96.7z M245.9,116.3
	c-5.8,6.8-11.6,13.5-17.3,20.2c2.1,1.8,4,3.6,6.1,5.4c6.2-5.6,12.3-11.3,18.6-16.9C250.8,122,248.4,119.2,245.9,116.3z M222.8,88.1
	c-4.6-2.8-9.2-5.6-13.8-8.4c-2.8,5-5.5,9.8-8.2,14.7c4.7,1.9,9.3,3.8,14,5.8C217.5,96.1,220.1,92.1,222.8,88.1z M191.5,90.5
	c2.2-4,4.4-8,6.6-12c-4.1-2-8-4-12.1-6c-2.2,4.4-4.4,8.6-6.6,13C183.5,87.2,187.5,88.9,191.5,90.5z M202.4,135.3
	c3,4.3,6.1,8.8,9.2,13.3c0.6-1.1,1.4-2.4,2-3.8c0.4-0.8,0.9-1,1.7-1c1.8,0.1,3.5,0,5.3,0c0.3,0,0.6-0.1,0.9-0.1
	c0-0.1,0.1-0.2,0.1-0.4c-0.8-0.5-1.6-1.1-2.5-1.5c-2.2-0.9-4.4-1.8-6.7-2.7C209,137.9,205.5,136.6,202.4,135.3z"/>
                    <path className={styling} d="M144.9,46.5c3.1,1.3,6.1,2.4,9.3,3.7c-4.4,11.2-8.9,22.3-13.4,33.6c5.1,2.1,10.2,4.1,15.4,6.2c-4.5,7.3-8.9,14.5-13.3,21.7
	c-3.8-1.5-7.5-3-11.4-4.5c-4.2,10.6-8.4,21.1-12.6,31.7c-3.1-1.2-6.1-2.5-9.3-3.7C121.4,105.6,133.1,76.1,144.9,46.5z"/>
                    <path className={styling} d="M289.9,105.7c3.1,1.2,6.1,2.4,9.3,3.7c-11.8,29.6-23.5,59.1-35.3,88.7c-3.1-1.2-6.2-2.5-9.3-3.7c4.4-11,8.7-22,13.1-33
	c-3.8-1.5-7.6-3-11.5-4.6c2.8-7.9,5.6-15.7,8.4-23.6c4.1,1.6,8.1,3.2,12.3,4.9C281.3,127.3,285.5,116.6,289.9,105.7z"/>
                    <path className={styling} d="M106.1,134c-4.1-1.6-8.2-3.3-12.3-4.9c11.8-29.6,23.5-59,35.3-88.7c4.1,1.6,8.2,3.2,12.4,4.9
	C129.6,74.9,117.9,104.4,106.1,134z"/>
                    <path className={styling} d="M302.6,111.1c4.1,1.7,8.2,3.3,12.3,4.9c-11.8,29.6-23.5,59.1-35.3,88.7c-4.1-1.6-8.2-3.3-12.4-4.9
	C279.1,170.1,290.9,140.7,302.6,111.1z"/>
                    <path className={styling} d="M292.8,209.6c-3.1-1.3-6.2-2.5-9.3-3.7c11.8-29.6,23.5-59.1,35.3-88.7c3.1,1.2,6.1,2.4,9.3,3.7
	C316.3,150.5,304.6,179.9,292.8,209.6z"/>
                    <path className={styling}
                          d="M90,127.3c-3.1-1.3-6.1-2.4-9.3-3.7c11.7-29.5,23.5-59,35.3-88.7c3.1,1.2,6.1,2.4,9.3,3.7C113.5,68.2,101.8,97.7,90,127.3z"
                    />
                    <path className={styling}
                          d="M80,261.7c-12.4-16.5-17.6-35-15.7-55.6C76.6,222.6,81.9,241.1,80,261.7z"/>
                    <path className={styling}
                          d="M358.1,72.4c-12.8-16.2-18.5-34.5-17.2-55.1C353.7,33.5,359.5,51.8,358.1,72.4z"/>
                    <path className={styling}
                          d="M274.1,297.9c17.3-11.3,36.1-15.3,56.5-12.1c-8.3,5.5-17.3,9.4-27,11.5C293.8,299.4,284,299.5,274.1,297.9z"/>
                    <path className={styling}
                          d="M345.4,163.7c9,18.5,10.7,37.7,4.9,57.5C341.3,202.7,339.7,183.5,345.4,163.7z"/>
                    <path className={styling}
                          d="M344.7,124.5c13.6,15.5,20.2,33.6,19.8,54.2C350.9,163.2,344.3,145.1,344.7,124.5z"/>
                    <path className={styling}
                          d="M13.4,101.6c11.6,17.1,15.9,35.8,13.1,56.2C14.9,140.8,10.6,122,13.4,101.6z"/>
                    <path className={styling}
                          d="M371.6,51.7c7.1,19.8,6.7,39.1-1,58.2C363.6,90.5,363.9,71.2,371.6,51.7z"/>
                    <path className={styling}
                          d="M89.5,48.5c-9.7,18.2-24.2,30.9-43.6,37.9C55.6,68.2,70.1,55.6,89.5,48.5z"/>
                    <path className={styling}
                          d="M337.1,250c10.6-18.1,25.6-30.3,45.2-36.6C372,231.2,357,243.4,337.1,250z"/>
                    <path className={styling}
                          d="M126.4,297.8c-20.4,3.2-39.2-0.8-56.4-12C90.3,282.6,109.1,286.6,126.4,297.8z"/>
                    <path className={styling}
                          d="M374.1,157.9c-2.8-20.4,1.5-39.2,13.1-56.3c1.5,9.9,1.2,19.7-1.1,29.4C383.8,140.7,379.8,149.7,374.1,157.9z"/>
                    <path className={styling} d="M297.8,8c18.3,9.6,30.9,24.1,38,43.5C317.6,41.9,304.9,27.4,297.8,8z"/>
                    <path className={styling}
                          d="M114.6,253.8c17.2,11.4,28.3,27.2,33.4,47.2c-8.3-5.5-15.5-12.2-21.3-20.3C121,272.5,117,263.5,114.6,253.8z"/>
                    <path className={styling}
                          d="M312.2,230.8c-0.3,20.6-7.5,38.5-21.6,53.6C290.9,263.7,298.1,245.9,312.2,230.8z"/>
                    <path className={styling}
                          d="M90.1,273.5c-20.5,2.3-39.1-2.6-55.9-14.6C54.8,256.6,73.4,261.5,90.1,273.5z"/>
                    <path className={styling} d="M110,284.3c-7.1-7.6-12.7-16.1-16.4-25.8c-3.4-8.9-5.1-18.2-5.2-27.7c6.9,7.2,12.2,15.4,16,24.6
	C108.1,264.7,109.9,274.3,110,284.3z"/>
                    <path className={styling}
                          d="M366.6,258.8c-16.1,11.8-35.7,17.2-56.5,14.8C327.2,261.5,345.8,256.6,366.6,258.8z"/>
                    <path className={styling} d="M64.8,51.5c7.1-19.4,19.7-33.9,38-43.5C95.7,27.4,83,41.9,64.8,51.5z"/>
                    <path className={styling}
                          d="M36.1,178.7c-0.3-20.6,6.3-38.7,19.8-54.2C56.2,145.1,49.7,163.2,36.1,178.7z"/>
                    <path className={styling}
                          d="M252.6,300.9c5.1-20,16.2-35.7,33.4-47.1C280.9,273.8,269.7,289.5,252.6,300.9z"/>
                    <path className={styling}
                          d="M338.5,87.7c16.2,12.7,26.1,29.3,29.6,49.6C351.9,124.6,342.1,108,338.5,87.7z"/>
                    <path className={styling}
                          d="M236,309.4c19.9-6.2,40.2-4.5,58.2,4.1c-9.8,3-19.5,4.2-29.4,3.5C254.8,316.3,245.3,313.7,236,309.4z"/>
                    <path className={styling}
                          d="M164.3,309.4c-18.7,8.8-37.9,10.1-57.6,4.1C125.3,304.7,144.6,303.3,164.3,309.4z"/>
                    <path className={styling}
                          d="M311.1,48.6c19.4,7,33.9,19.6,43.5,37.8C335.3,79.4,320.8,66.8,311.1,48.6z"/>
                    <path className={styling}
                          d="M32.4,137.5c0.8-15.6,17-42.7,29.8-49.9C58.5,108.1,48.6,124.6,32.4,137.5z"/>
                    <path className={styling}
                          d="M392.4,161c-3.7,20.3-13.7,36.7-30,49.3C366.1,189.9,376.1,173.5,392.4,161z"/>
                    <path className={styling}
                          d="M63.2,249.7c-19.6-6.4-34.6-18.5-45.2-36.6C37.9,219.7,52.9,231.8,63.2,249.7z"/>
                    <path className={styling}
                          d="M59.6,17.3c1.3,20.6-4.4,38.9-17.2,55.1C41.1,51.9,46.8,33.5,59.6,17.3z"/>
                    <path className={styling}
                          d="M8.1,161c16.4,12.6,26.3,29.1,30.2,49.7c-8.1-6.3-14.8-13.5-20-22.1C13.2,180,9.9,170.8,8.1,161z"/>
                    <path className={styling}
                          d="M50.2,221.3c-5.7-19.9-4.1-39.1,4.9-57.6c2.9,9.6,3.9,19.3,3.1,29.3C57.4,202.9,54.6,212.3,50.2,221.3z"/>
                    <path className={styling} d="M28.9,51.7c3.2,7.8,5,15.5,5.8,23.4c1.1,11.9-0.6,23.5-4.8,34.7c-3.9-9.2-5.9-18.8-6.1-28.8C23.6,71.1,25.4,61.4,28.9,51.7z
	"/>
                    <path className={styling}
                          d="M336.3,206.1c1.9,20.5-3.3,39.1-15.7,55.6c-1-9.9-0.2-19.7,2.5-29.3C325.8,222.8,330.3,214,336.3,206.1z"/>
                    <path className={styling} d="M212.6,221.4c-2.3-0.1-4.4-0.2-6.7-0.3c0.1-3.1,0.3-6.2,0.4-9.3c0.3-6.4,0.5-12.8,0.8-19.3c0-0.7-0.2-1-0.9-1.2
	c-2.5-0.7-4.9-1.9-7.1-3.5c1.3-1.8,2.6-3.6,3.9-5.3c4.9,2.9,9.7,4.4,15,1.1c1.3,1.8,2.5,3.6,3.8,5.4c-2.4,1.8-5.1,2.7-8,3.1
	C213.5,201.8,213.1,211.5,212.6,221.4z"/>
            </svg>
        )
}

export default MuscleBadge;