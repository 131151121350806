import React from 'react';

export const Iron = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M54.2,81.4c-11-0.3-19.4-7.4-21.5-18c-1.4-7.5-0.1-15.1,4-23.2c1.9-3.7,4.6-7,8.3-10c2.7-2.2,5.9-3.4,9.2-3.3
		c3.3,0,6.4,1.1,9.1,3.3c3.7,3,6.4,6.3,8.3,10c4.1,8.1,5.4,15.7,4,23.2l0,0C73.6,74,65.2,81,54.2,81.4z M53.9,28.3c-2.9,0-5.6,1-8,3
		c-3.5,2.9-6.1,6-7.9,9.6c-4,7.8-5.2,15.1-3.9,22.2c1.9,9.9,9.7,16.5,20,16.8c10.2-0.3,18-6.9,19.9-16.8l0,0
		c1.4-7.2,0.1-14.5-3.9-22.2c-1.8-3.5-4.4-6.7-7.9-9.6c-2.4-2-5.3-3-8.2-3C54.1,28.3,54,28.3,53.9,28.3z"/>
                <g>
                    <g>
                        <path d="M53.8,70.7c-5.7,0-10.4-4.7-10.4-10.4S48.1,50,53.8,50s10.4,4.7,10.4,10.4S59.5,70.7,53.8,70.7z M53.8,51.5
				c-4.9,0-8.9,4-8.9,8.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9S58.7,51.5,53.8,51.5z"/>
                    </g>
                    <g>
                        <circle cx="49" cy="34.2" r="0.8"/>
                        <path d="M41.7,44.6c-0.1,0-0.2,0-0.3,0c-0.4-0.1-0.6-0.6-0.4-1c1.1-3.1,2.9-5.9,5.2-8.2c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1
				c-2.2,2.2-3.8,4.8-4.9,7.7C42.3,44.5,42.1,44.6,41.7,44.6z"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};