import React, { useState, useEffect } from "react";

const IOSPrompt = ({ title, body, delay }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let visits = localStorage.getItem("visits");
    if (visits === null) {
      visits = 0;
      localStorage.setItem("visits", visits);
    }

    if (parseInt(visits) < 1) {
      localStorage.setItem("visits", parseInt(visits) + 1);
      setTimeout(() => {
        setIsVisible(true);
      }, delay);
    }
  }, [delay]);

  return (
    <>
      <div
        className="modal"
        style={isVisible ? { display: "block" } : { display: "none" }}
        onClick={() => setIsVisible(false)}
      >
        <div className="prompt">
          <div className="p-content">
            <span onClick={() => setIsVisible(false)} className="close">
              &times;
            </span>
            <p>
              <strong>{title}</strong>
            </p>
            <p>{body}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default IOSPrompt;