import React from 'react';

export const Athletic = (props)  => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 107.7 107.7" xmlSpace="preserve">
            <g fill={props.color}>
                <path d="M75.3,32.6h-4.5V30c1.8-0.4,3.1-2,3.1-3.9c0-2.2-1.8-4-4-4H37.7c-2.2,0-4,1.8-4,4c0,1.9,1.3,3.5,3.1,3.9v2.7h-4.4
			c-1.5,0-2.7,1.2-2.7,2.8v5.5c0,7,5.3,12.8,12.1,13.1c0.2,0,0.4,0,0.6,0c0.7,0,1.4-0.1,2-0.2c0.1,0,0.1,0,0.2,0
			c0.9,0.6,1.8,1.1,2.8,1.5c-0.6,0.6-1.1,1.4-1.1,2.4c0,1.7,1.4,3.2,3.2,3.2h1.8c0.2,1.3,0.6,6.3-5.7,10.7c0,0,0,0,0,0
			c-3,1.8-5.3,4.5-6.6,7.8h-1c-1.7,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2h31.5c1.8,0,3.2-1.4,3.2-3.2s-1.4-3.2-3.2-3.2h-1
			c-1.3-3.3-3.6-6-6.5-7.8c0,0,0,0,0,0c0,0,0,0,0,0c-6.3-4.4-5.9-9.3-5.7-10.7H58c1.8,0,3.2-1.4,3.2-3.2c0-0.9-0.4-1.8-1-2.4
			c1-0.4,2-0.9,2.9-1.5c0.1,0,0.2,0,0.3,0c0.7,0.1,1.3,0.2,2,0.2c0.2,0,0.4,0,0.6,0C72.7,53.6,78,47.9,78,40.9v-5.5
			C78,33.9,76.8,32.6,75.3,32.6z M41.9,52.4C36,52.2,31.2,47,31.2,40.9v-5.5c0-0.7,0.6-1.3,1.2-1.3h4.4v5.4c0,0.5,0,1,0.1,1.4
			c0.4,4.6,2.6,8.7,6,11.5C42.5,52.5,42.2,52.5,41.9,52.4z M71.2,82.5c0,0.9-0.8,1.7-1.7,1.7H38.1c-0.9,0-1.7-0.8-1.7-1.7
			s0.7-1.7,1.7-1.7h31.5C70.5,80.8,71.2,81.6,71.2,82.5z M66.9,79.3H40.7c1.2-2.8,3.3-5.1,5.9-6.6c2.2-1.3,4.6-2,7.3-2
			C59.6,70.7,64.7,74.2,66.9,79.3z M57.8,69.7c-1.3-0.3-2.6-0.5-4-0.5c-1.3,0-2.7,0.2-3.9,0.5c3.3-3.8,3.2-7.4,3-9h1.9
			C54.5,62.3,54.5,66,57.8,69.7z M58,59.3h-8.4c-0.9,0-1.7-0.8-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7c1.3,0.4,2.8,0.5,4.2,0.5
			c1.5,0,2.9-0.2,4.3-0.5c0.9,0,1.6,0.8,1.6,1.7C59.7,58.5,58.9,59.3,58,59.3z M53.8,55c-8,0-14.7-6.2-15.4-14.1
			c0-0.4-0.1-0.9-0.1-1.3v-9.5h12.9c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8H37.7c-1.4,0-2.5-1.1-2.5-2.5
			c0-1.4,1.1-2.5,2.5-2.5h32.2c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5H56.3c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8
			h12.9v9.5C69.3,48,62.3,55,53.8,55z M76.5,40.9c0,6.1-4.8,11.3-10.7,11.6c-0.4,0-0.7,0-1.1,0c3.7-3.1,6-7.8,6-12.9v-5.4h4.5
			c0.7,0,1.3,0.6,1.3,1.3V40.9z"/>
                <path d="M50.4,47c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.3-0.5-0.6-0.4-1.1l0.4-2.6c0.1-0.4-0.1-0.9-0.4-1.2l-1.9-1.8
			c-0.3-0.3-0.4-0.7-0.3-1.1c0.1-0.4,0.5-0.7,0.9-0.7l2.6-0.4c0.5-0.1,0.8-0.3,1-0.8l1.1-2.3c0.4-0.8,1.6-0.8,2,0l1.1,2.3
			c0.2,0.4,0.6,0.7,1,0.8l2.6,0.4c0.4,0.1,0.8,0.3,0.9,0.7c0.1,0.4,0,0.8-0.3,1.1l-1.9,1.8c-0.3,0.3-0.5,0.8-0.4,1.2l0.4,2.6
			c0.1,0.4-0.1,0.8-0.4,1.1c-0.3,0.3-0.8,0.3-1.2,0.1l-2.3-1.2c-0.4-0.2-0.9-0.2-1.3,0l-2.3,1.2C50.7,46.9,50.5,47,50.4,47z
			 M50.2,45.5C50.2,45.5,50.2,45.5,50.2,45.5L50.2,45.5z M57.5,45.5L57.5,45.5C57.5,45.5,57.5,45.5,57.5,45.5z M49.1,39.6l1.3,1.2
			c0.7,0.7,1,1.6,0.8,2.6l-0.3,1.7l1.6-0.8c0.8-0.4,1.9-0.4,2.7,0l1.6,0.8l-0.3-1.7c-0.2-0.9,0.1-1.9,0.8-2.6l1.3-1.2l-1.8-0.3
			c-0.9-0.1-1.8-0.7-2.2-1.6l-0.8-1.6L53,37.7c-0.4,0.9-1.2,1.4-2.2,1.6L49.1,39.6z"/>
            </g>
        </svg>
    );
};