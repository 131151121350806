import React from 'react';

class HorizontalBar extends React.Component{

	render(){
		return(
			<svg viewBox="0 0 100 2"
				 preserveAspectRatio="none"
				 version="1.1"
				 id="Layer_1"
				 xmlns="http://www.w3.org/2000/svg"
				 xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 xmlSpace="preserve">
    			<rect x="0" y="0" width="100" height="2" fill={this.props.inactiveColor} stroke={this.props.stroke} strokeWidth={this.props.strokeWidth} rx="1" ry="77"/>
				<g>
					<rect x="0" y="0" height="2" fill={this.props.activeColor} stroke={this.props.stroke} strokeWidth={this.props.strokeWidth} width="30.556" rx="1" ry="77" >
						<animate attributeName="width" from="0"
								 to={this.props.value * 100} dur="1.3s" fill="freeze"
								 calcMode="spline"
								 keySplines="0,0,.58,1"
						/>
					</rect>
{/*					<text x={this.props.value * 100 - 4.5} y="1.6" fontSize="2" fill="#f5f5f5" >
						{`${Math.round(this.props.value * 100)}%`}
					</text>*/}
				</g>
			</svg>
		);
	}

}

export default HorizontalBar;