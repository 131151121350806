import React, { useEffect, useState } from "react";
import "./styles/App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import FullCategory from "./components/FullCategory/FullCategory";
import config from "./config/config";
import Cookies from "./components/Cookies/Cookies";
import Terms from "./components/Terms/Terms";
import Help from "./components/Help/Help";
import { StateProvider } from "./contexts/StateContext";
import NavBar from "./components/NavBar/NavBar";
import {
  CookieConsentsProvider,
  useCookieConsents,
} from "@enzsft/react-cookie-consents";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import { Athletic } from "./container/Athletic/Athletic";
import { Preventive } from "./container/Preventive/Preventive";
import { Nutrition } from "./container/Nutrition/Nutrition";
import { Refs } from "./components/Refs/Refs";
import { useMatomo } from "@datapunt/matomo-tracker-react";

function App(props) {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    localStorage.setItem("isFirstTime", "false");
  }, []);

  /*
   * Language state is kept in localStorage.
   * When not specified, it defaults to the first language in the config object.
   */
  let language = localStorage.getItem("lang");
  if (language == null) {
    language = config.languages[0];
    localStorage.setItem("lang", language);
  }

  let id = localStorage.getItem("id");
  if (id == null) {
    id = config.companies[0].title;
    localStorage.setItem("id", id);
  }

  const searchParams = new URLSearchParams(props.location.search);
  searchParams.forEach(function (value, key) {
    if (
      key === "id" &&
      config.companies.findIndex((obj) => obj.value === value) !== -1
    ) {
      const index = config.companies.findIndex((obj) => obj.value === value);
      id = config.companies[index].title;
      localStorage.setItem("id", id);
    }

    if (key === "lang" && config["languages"].includes(value)) {
      language = value;
      localStorage.setItem("lang", language);
    }
  });

  const initialState = {
    theme: { primary: "green" },
    company: id,
    lang: language,
    hash: props.match.params.hash,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "changeTheme":
        return {
          ...state,
          theme: action.newTheme,
        };
      case "changeLanguage":
        return {
          ...state,
          lang: action.newLanguage,
        };
      default:
        return state;
    }
  };

  const cookieConsents = useCookieConsents();
  const consent = cookieConsents.get().length > 0;

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod" && consent) {
      trackPageView();
    }
  }, [trackPageView, consent]);

  useEffect(() => {
    let manifestFile = document.getElementById("manifest")
    let favicon = document.getElementById("favicon")

    if (id === "invenimus") {
      document.title = "Invenimus";
      manifestFile.href = "/manifest_invenimus.json"
      favicon.href = "/img/icons/invenimus_icons/favicon-32x32-seochecker-manifest-7510.png"
    } else {
      manifestFile.href = "/manifest.json"
      favicon.href = "/img/icons/favicon.ico"
    }
  }, [id]);

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <div className={id}>
        <NavBar />
        <section className="container">
          <Switch>
            <Route
              exact
              path="/webapp/:hash/plan/athletic"
              component={Athletic}
            />
            <Route
              exact
              path="/webapp/:hash/plan/preventive"
              component={Preventive}
            />
            <Route
              exact
              path="/webapp/:hash/plan/nutrition"
              component={Nutrition}
            />
            <Route exact path="/webapp/:hash/refs" component={Refs} />
            <Route exact path="/webapp/:hash/terms" component={Terms} />
            <Route exact path="/webapp/:hash/help" component={Help} />
            <Route exact path="/webapp/:hash/cookies" component={Cookies} />
            <Route
              exact
              path="/webapp/:hash/area/:area"
              component={FullCategory}
            />
            <Redirect to={`/webapp/${initialState.hash}/plan/athletic`} />
          </Switch>
        </section>
      </div>
      <CookieBanner />
    </StateProvider>
  );
}

export default App;
