import React from 'react';
import VoBadge from "../../assets/badges/Vo";
import RefuelBadge from "../../assets/badges/Refuel";
import RecoveryBadge from "../../assets/badges/Recovery";
import PowerBadge from "../../assets/badges/Power";
import EnduranceBadge from "../../assets/badges/Endurance";
import PowerenduranceBadge from "../../assets/badges/Powerendurance";
import MuscleBadge from "../../assets/badges/Muscle";
import InjuryBadge from "../../assets/badges/Injury";


function BadgeIcon(props) {
    switch (props.name) {
        case "power":
            return <PowerBadge badge={props.badge}/>;
        case "endurance":
            return <EnduranceBadge badge={props.badge}/>;
        case "powerendurance":
            return <PowerenduranceBadge badge={props.badge}/>;
        case "vo":
            return <VoBadge badge={props.badge}/>;
        case "muscle":
            return <MuscleBadge badge={props.badge}/>;
        case "injury":
            return <InjuryBadge badge={props.badge}/>;
        case "needs":
            return <RecoveryBadge badge={props.badge}/>;
        default: return;
    }
}

export default BadgeIcon;