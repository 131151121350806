import React from 'react'

function MuscleIcon(props) {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 99.21 99.21" xmlSpace="preserve">
            <g>
                <defs>
                    <rect id="SVGID_1_" y="0" width="99.21" height="99.21"/>
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"/>
                </clipPath>
                <path  fill={props.color} d="M54.32,82.46c-11.6,0-24.97-3.14-35.93-14.29c-1.96-2-0.7-5.61,0.64-9.44c0.37-1.06,0.75-2.15,1.04-3.17
                    c1.24-4.33,8.49-24.12,8.79-24.96c0.04-0.14,0.41-1.22,2.14-2.38c1.97-1.33,14.06-10.78,14.18-10.88c0.19-0.12,2.24-1.26,4.04-0.03
                    c1.42,0.97,7.09,6.18,7.33,6.4c0.09,0.08,1.02,0.92,1.08,2.22c0.04,0.87-0.31,1.7-1.04,2.46c-1.82,1.9-4.22,4-4.32,4.08
                    c-0.87,0.69-2.93,1.56-4.73,0.1c-2.25-1.84-0.45-5.32-0.37-5.47c0.22-0.41,0.73-0.57,1.14-0.35c0.41,0.22,0.57,0.73,0.36,1.14
                    c-0.01,0.02-1.22,2.43-0.06,3.38c1.16,0.95,2.44,0.02,2.58-0.09c0,0,2.41-2.11,4.18-3.95c0.4-0.42,0.59-0.82,0.57-1.21
                    c-0.03-0.59-0.51-1.04-0.52-1.04c-1.63-1.5-6.06-5.52-7.16-6.27c-0.92-0.63-2.14,0.03-2.15,0.04c-0.39,0.32-12.16,9.51-14.17,10.88
                    c-1.23,0.83-1.49,1.53-1.49,1.53c-0.09,0.24-7.55,20.61-8.77,24.88c-0.3,1.06-0.69,2.18-1.07,3.26c-1.06,3.03-2.26,6.45-1.03,7.7
                    c24.18,24.58,60.68,8.83,61.04,8.68c0.42-0.19,0.92,0,1.11,0.43c0.19,0.42-0.01,0.92-0.43,1.11C81.11,77.3,69.18,82.46,54.32,82.46
                    "/>
                <path  fill={props.color} d="M50.06,39.07c-2,0-4.95-0.7-6.39-1.08c-0.45-0.12-0.72-0.58-0.6-1.03c0.12-0.45,0.58-0.72,1.03-0.6
                    c3.07,0.81,6.16,1.24,6.74,0.96c1.35-0.77,5.72-4.77,6.75-6.03c-0.38-0.42-1.26-1.27-2.03-1.95c-0.35-0.31-0.38-0.84-0.07-1.19
                    c0.31-0.35,0.84-0.38,1.19-0.07c2.47,2.19,2.64,2.75,2.7,2.93c0.47,1.53-7.32,7.56-7.7,7.78C51.31,38.99,50.74,39.07,50.06,39.07
                     M57.76,31.5h0.02H57.76z"/>
                <path  fill={props.color} d="M41.33,56.3c-0.24,0-0.48-0.1-0.65-0.3c-0.3-0.36-0.25-0.89,0.11-1.19c1.95-1.62,1.64-3.01,0.15-8.03
                    l-0.14-0.46c-1.65-5.59,2.32-9.58,2.49-9.74c0.33-0.33,0.87-0.32,1.19,0.01c0.33,0.33,0.33,0.86,0,1.19
                    c-0.14,0.14-3.42,3.47-2.06,8.06l0.14,0.46c1.46,4.93,2.2,7.41-0.69,9.81C41.71,56.24,41.52,56.3,41.33,56.3"/>
                <path  fill={props.color} d="M47.76,57.36c-0.21,0-0.42-0.08-0.59-0.24c-1.3-1.25-4.56-1.03-5.7-0.83c-0.45,0.07-0.89-0.23-0.97-0.69
                    c-0.08-0.46,0.23-0.89,0.69-0.97c0.51-0.08,5.02-0.78,7.16,1.28c0.34,0.32,0.35,0.86,0.02,1.19C48.2,57.27,47.98,57.36,47.76,57.36
                    "/>
                <path  fill={props.color} d="M47.76,57.36c-0.04,0-0.07,0-0.11-0.01c-0.46-0.06-0.79-0.48-0.73-0.94c0.01-0.09,1.2-8.64,10.08-9.43
                    c10.9-0.96,12.5,7,12.52,7.08c0.09,0.46-0.22,0.9-0.68,0.98c-0.45,0.08-0.9-0.22-0.98-0.68c-0.05-0.26-1.36-6.54-10.71-5.7
                    c-7.51,0.67-8.52,7.67-8.56,7.97C48.54,57.05,48.18,57.36,47.76,57.36"/>
                <path  fill={props.color} d="M68.69,55.02c-0.15,0-0.3-0.04-0.43-0.12c-0.4-0.24-0.53-0.76-0.29-1.16c1.1-1.84,6.09-5.52,10.79-2.92
                    c0.41,0.22,0.56,0.74,0.33,1.15c-0.23,0.41-0.74,0.55-1.14,0.33c-3.69-2.04-7.79,1.09-8.53,2.32
                    C69.26,54.87,68.98,55.02,68.69,55.02"/>
            </g>
        </svg>
    )
}

export default MuscleIcon;